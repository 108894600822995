import {Component, Inject, inject, input, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainChartComponent} from '../../components/main-chart/main-chart.component';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {BehaviorSubject, combineLatestWith, filter, firstValueFrom, map} from 'rxjs';
import {
  ConnectionResourceLocation,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import {HttpErrorResponse} from '@angular/common/http';
import {showRequestErrors, RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {UmsatzstatistikQuery} from '../../types/umsatzstatistik.query';
import moment from 'moment';
import {I18nModule, UiCoreModule} from '@softline/ui-core';
import {UmsatzstatistikService} from '../../services/umsatzstatistik-service';
import {Umsatzstatistik} from '../../types/umsatzstatistik';
import {SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG} from '../../umsatzstatistik.api';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {extractUmsatzstatistikRemoteConfig} from '../../utils/extract-remote-config';
import {toChartData} from '../../utils/to-chart-data';
import {SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_AUFGLIEDERUNG} from '../../umsatzstatistik.shared';
import {WwsUmsatzstatistikAufgliederungStore} from '../../store/aufgliederung.store';

@Component({
  selector: 'soft-umsatzstatistik-widget',
  standalone: true,
  imports: [CommonModule, MainChartComponent, I18nModule, UiCoreModule],
  templateUrl: './umsatzstatistik-widget.component.html',
  styleUrl: './umsatzstatistik-widget.component.scss',
})
export class UmsatzstatistikWidgetComponent implements OnInit {

  private readonly uuid = inject(SOFTLINE_SERVICE_UUID);

  kundennummer = input<string | null | undefined>(null);
  idktostamm = input<number | null>(null);

  readonly vondatum = signal(moment(new Date().toISOString()).startOf('year').toISOString());
  readonly bisdatum = signal(moment(new Date().toISOString()).endOf('month').toISOString());

  readonly data$ = new BehaviorSubject<Umsatzstatistik | null>(null);

  readonly loading = signal(false);

  readonly noData$ = toObservable(this.loading).pipe(
    combineLatestWith(this.data$),
    map(([loading, data]) => !loading && (data === null || this.chartData()?.length < 1))
  );

  readonly chartData = toSignal(
    this.data$.pipe(
      filter((o): o is Umsatzstatistik => !!o),
      map(o => o.ebeneresult),
      combineLatestWith(
        this.store.observe(SOFTLINE_FEATURE_REMOTE_CONFIG, RemoteConfigStore.getters.data).pipe(
          map(o => extractUmsatzstatistikRemoteConfig(o))
        )
      ),
      map(([umsatzstatistik, remoteConfig]) =>
        toChartData(umsatzstatistik, remoteConfig)
      )
    ), { initialValue: [] }
  );

  constructor(
    private readonly store: Store,
    private readonly service: UmsatzstatistikService,
    @Inject(SOFTLINE_SERVICE_HTTP)
    private readonly httpService: ResourceService<ConnectionResourceLocation>,
  ) {}

  async ngOnInit(): Promise<void> {
    try {

      this.loading.set(true);
      const remoteConfig = await this.store.dispatch(
        SOFTLINE_FEATURE_REMOTE_CONFIG,
        RemoteConfigStore.actions.load
      );

      const umsatzstatistikRemoteConfig = extractUmsatzstatistikRemoteConfig(remoteConfig);

      const aufgliederungen = await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_AUFGLIEDERUNG,
        WwsUmsatzstatistikAufgliederungStore.actions.loadItems
      );

      const aufgliederung = aufgliederungen.find(o => o.umsatzaufgliederung === umsatzstatistikRemoteConfig?.defaultAufgliederung);

      const query = {
        ebene: { umsatzebene: umsatzstatistikRemoteConfig?.defaultEbene ?? 'Vkforg', key: 'VKFORG' },
        umsatzart: { umsatzart: umsatzstatistikRemoteConfig?.defaultUmsatzart ?? 'Gesamt' },
        aufgliederung: aufgliederung ?? null,
        vondatum: this.vondatum(),
        bisdatum: this.bisdatum(),
        filiale: null,
        menge: 0,
        vergleich: false
      }

      const result = await this.loadUmsatzstatistik(query);
      this.data$.next(result);
      this.loading.set(false);
    } catch (e) {
      this.loading.set(false);

      if (!(e instanceof HttpErrorResponse))
        return;

      showRequestErrors(this.store, e);
    }
  }

  private async loadUmsatzstatistik(query: Partial<UmsatzstatistikQuery>): Promise<Umsatzstatistik | null> {
    const accountId = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    )?.['accountid'];

    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG,
      queryParams: (this.idktostamm() ?? accountId)
        ? { idktostamm: (this.idktostamm() ?? accountId) }
        : undefined
    }

    const filialen = await firstValueFrom(this.httpService.get<any[]>(location));

    const requestBody = {
      ebene: query?.ebene?.umsatzebene,
      umsatzart: query?.umsatzart?.umsatzart,
      aufgliederung: query?.aufgliederung?.name,
      filialen: query?.filiale !== null ? [query.filiale] : filialen,
      vondat: query?.vondatum,
      bisdat: query?.bisdatum,
      menge: 0,
      vergleich: false
    }

    if (this.kundennummer()) {
      requestBody['kunde'] = this.kundennummer()
    }

    return await firstValueFrom(this.service.loadUmsatzstatistik(requestBody));
  }
}
