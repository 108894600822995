import { Observable, Subscription } from 'rxjs';
import {
  createMutation,
  createAction,
  mutate,
  on,
  createGetter,
  select,
} from '../../factories';
import { StoreFeature } from '../../store';
import { Dictionary } from '../../../types/dictionary';
import { isDefined } from '../../../functions/is-defined.function';
import { StoreCommit } from '../../abstraction';
import { CancelledError } from '../../../types/errors';

export interface State {
  subscriptions: Dictionary<Subscription>;
}

export const mutations = {
  add: createMutation<State, { key: string; subscription: Subscription }>(
    'add subscription'
  ),
  remove: createMutation<State, string>('remove subscription'),
};

export const actions = {
  cancel: createAction<State, string>('cancel'),
  cancelAll: createAction<State>('cancel all'),
};

export const getters = {
  active: createGetter<State, boolean, string>('active'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    subscriptions: {},
  },
  mutations: [
    mutate(mutations.add, ({ state, params, featureName }) => {
      if (state.subscriptions[params.key])
        throw new Error(
          `[SubscriptionStore] ${featureName} - add subscription: There is already a subscription with id ${params.key}`
        );
      const subscriptions = {
        ...state.subscriptions,
        [params.key]: params.subscription,
      };
      return { ...state, subscriptions };
    }),
    mutate(mutations.remove, ({ state, params, featureName }) => {
      if (!state.subscriptions[params])
        throw new Error(
          `[SubscriptionStore] ${featureName} - remove subscription: Subscription with id ${params} not found`
        );
      const subscriptions = { ...state.subscriptions };
      delete subscriptions[params];
      return { ...state, subscriptions };
    }),
  ],
  actions: [
    on(actions.cancel, ({ state, commit, params, featureName }) => {
      const subscription = state.subscriptions[params];
      if (!isDefined(subscription))
        throw new Error(
          `[SubscriptionStore] ${featureName} - cancel subscription: Subscription with id ${params} not found`
        );
      if (subscription.closed)
        throw new Error(
          `[SubscriptionStore] ${featureName} - cancel subscription: Subscription with id ${params} is already closed`
        );
      commit(featureName, mutations.remove, params);
      subscription.unsubscribe();
    }),
    on(actions.cancelAll, ({ state, commit, params, featureName }) => {
      for (const [key, subscription] of Object.entries(state.subscriptions)) {
        if (!isDefined(subscription) || subscription.closed) continue;

        commit(featureName, mutations.remove, key);
        subscription.unsubscribe();
      }
    }),
  ],
  getters: [
    select(
      getters.active,
      ({ state, params }) => state.subscriptions[params]?.closed === true
    ),
  ],
};

export function handleSubscriptionState<T>(
  observable: Observable<T>,
  featureName: string,
  commit: StoreCommit,
  token: string
): Observable<T> {
  return new Observable<T>((observer) => {
    const subscription = observable.subscribe(
      (o) => {
        observer.next(o);
      },
      (e) => {
        commit(featureName, mutations.remove, token);
        observer.error(e);
      },
      () => {
        commit(featureName, mutations.remove, token);
        observer.complete();
      }
    );
    commit(featureName, mutations.add, { key: token, subscription });
    subscription.add(() => {
      observer.error(new CancelledError(`RemoteObjectStore - load: cancelled (${token})`));
    });
  });
}
