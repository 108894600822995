<ng-container *ngIf="item">
    <div class="c row space-between no-wrap center-v pt-3 pb-3">
        <div class="c column no-wrap pl-3 pr-3 border-box">
          <div class="g custom-grid">
            <div>
              <span class="text-sm color-light">{{  item.itemScanBean?.artikel?.nummer }}</span>
              <i class="fa-regular fa-images info-icon" *ngIf="(item?.files || []).length > 0"></i>
            </div>
            <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
              <i class="fa-regular fa-note-sticky info-icon" *ngIf="(item?.vermerk || '').length > 0"></i>
              <div class="text-ellipsis overflow-hidden opacity-70">{{ item?.vermerk }}</div>
            </div>
          </div>

          <div class="mt-1 mb-1">
            <p class="text-base font-semibold" *ngIf="item.itemScanBean.artikel.artbez1?.length">{{ item.itemScanBean.artikel.artbez1 }}</p>
            <p class="text-base text-light" *ngIf="item.itemScanBean.artikel.artbez2?.length">{{ item.itemScanBean.artikel.artbez2 }}</p>
            <p class="text-base text-light" *ngIf="item.itemScanBean.artikel.artbez3?.length">{{ item.itemScanBean.artikel.artbez3 }}</p>
          </div>
        </div>
        <div class="c row end no-wrap center-v pl-3 border-box">
            <div class="flex relative">
                <ng-container>
                    <soft-number-input placeholder="Menge"
                                       [value]="item.itemScanBean?.menge"
                                       [class.error]="hasError"
                                       changeTrigger="input"
                                       style="max-width: 80px"
                                       (valueChange)="onChange(item, menge.value, item.itemScanBean.einheit)"
                                       #menge>
                    </soft-number-input>

                    <div style="width: 100px;" class="ml-2">
                        <span class="soft-title ml-3 color-light"
                              style="width: 100px">{{ item.itemScanBean.einheit.arteh || ''}}</span>
                    </div>
                </ng-container>
            </div>

            <div class="detail-area ml-4" (click)="onEditBeweClick(item)">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </div>
            <span class="detail-area">
                <i class="fa-regular fa-delete-left pointer p-1"
                   [ngStyle]="{ 'color': (!!(item.itemScanBean?.menge) || !!menge?.value) ? 'var(--colorError)' : 'rgba(0, 0, 0, 0.33)'}"
                   (click)="onDeleteBeweClick(item); menge.value = null"></i>
            </span>
        </div>
    </div>
</ng-container>
