import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  ArchiveStore,
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors,
  SOFTLINE_FEATURE_ARCHIVE,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import {base64Decode, isDefined, Mediator, Store} from "@softline/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, firstValueFrom, map, Subscription, switchMap} from "rxjs";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from "@softline/ui-core";
import {PdfViewerComponent} from "@softapps/allgemein/pdf-viewer";
import {
  SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
  SOFTLINE_DEFINITION_FREIGABE_DETAIL,
  SOFTLINE_FEATURE_FREIGABE,
  SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT,
  SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT
} from "../../freigabe.shared";
import {FreigabeStore} from "../../store";
import {WorkflowData} from "../../types/workflow-data";
import {DefinitionStore, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS} from "@softline/dynamic";
import {PdfEditorStore, SOFTLINE_FEATURE_PDF_EDITOR} from "@softapps/allgemein/pdf-editor";

@Component({
  selector: 'lib-freigabe',
  standalone: true,
  imports: [
    CommonModule,
    UiCoreModule,
    PdfViewerComponent,
    DynamicModule,
  ],
  templateUrl: './freigabe.component.html',
  styleUrls: ['./freigabe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreigabeComponent implements OnInit, OnDestroy, BackNavigable {
  private routeSubscription?: Subscription;
  private mediatorSubscription?: Subscription;
  document?: Blob;

  id$ = this.route.paramMap.pipe(
    map(o => o.get('id'))
  );
  detailDefinition$: any = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_FREIGABE_DETAIL
  );
  workflow$ = this.route.paramMap.pipe(
    switchMap((o) =>
      this.store.observe(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.getters.entityWithDetails,
        o.get('id')
      )
    )
  );
  detailLoading = false;
  documentLoading = false;
  documentEdited = false;
  saving$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService,
    private mediator: Mediator,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#FREIGABE.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: FreigabeComponent,
        commands: this.createCommands(),
      }
    );
    await this.loadDefinition();

    this.routeSubscription = this.route.paramMap.subscribe(
      async (o) => await this.loadData(o.get('id'))
    );

    this.mediatorSubscription = this.mediator.subscribe(async (message) => {
      switch (message.name) {
        case SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT:
          await this.onNext();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription && !this.routeSubscription.closed)
      this.routeSubscription.unsubscribe();
    this.routeSubscription = undefined;

    if (this.mediatorSubscription && !this.mediatorSubscription.closed)
      this.mediatorSubscription.unsubscribe();
    this.mediatorSubscription = undefined;

    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      FreigabeComponent
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['freigabe']);
  }

  async loadDefinition(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        { name: SOFTLINE_DEFINITION_FREIGABE_DETAIL }
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async loadData(id: string | null): Promise<void> {
    if(!isDefined(id))
      return;
    try {
      this.detailLoading = true;
      this.documentLoading = true;

      const workflow = await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.loadOnce,
        { id }
      );

      let detail = this.store.get(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.getters.details,
        {id}
      )?.detail

      if (!detail) {
       detail = (await this.store.dispatch(
          SOFTLINE_FEATURE_FREIGABE,
          FreigabeStore.actions.loadDetail,
          { id, name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL }
        )) as WorkflowData;
      }

      console.log('loaded detail: ', detail);

      this.detailLoading = false;
      this.cdRef.detectChanges();

      const editedDocument = this.store.get(
        SOFTLINE_FEATURE_PDF_EDITOR,
        PdfEditorStore.getters.document,
        detail.fileKey
      ) as any;
      if (editedDocument?.edited) {
        this.document = editedDocument.data;
        this.documentEdited = true;
      }
      else if (detail.fileKey && detail.archiveKey)
        this.document = (
          await this.store.dispatch(
            SOFTLINE_FEATURE_ARCHIVE,
            ArchiveStore.actions.download,
            {
              file: {
                archiveKey: detail.archiveKey,
                fileKey: detail.fileKey,
                metaData: JSON.parse(base64Decode(detail.fileKey)).metaData,
              },
            }
          )
        ).content;
    } catch (e) {
      console.log(e);
      showRequestErrors(this.store, e);
    } finally {
      this.detailLoading = false;
      this.documentLoading = false;
      this.cdRef.detectChanges();
    }
  }

  createCommands(): Command[] {
    return [
      {
        name: '#FREIGABE.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-list',
        name: '#FREIGABE.MENU.LIST',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/freigabe'],
      },
      {
        icon: 'fa-regular fa-file-export',
        name: '#FREIGABE.MENU.FREIGABE',
        class: 'menu action-menu action-menu-top',
        canExecute: this.workflow$.pipe(
          map((o) => o.availableTasks?.length > 0)
        ),
        execute: () => this.onNext(),
      },
    ];
  }

  async onEditPdf(): Promise<void> {
    const workflow = await firstValueFrom(this.workflow$);
    if(!workflow.detail.fileKey)
      throw new Error('[freigabe.component] onEditPdf: no filekey to open')
    if(!this.document)
      throw new Error('[freigabe.component] onEditPdf: no document to open')
    await this.store.dispatch(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.actions.open,
      {
        id: workflow.detail.fileKey,
        data: this.document,
        name: 'Test',
        fromRoute: {
          url: ['/freigabe', this.route.snapshot.paramMap.get('id')],
        },
      }
    );
  }

  async onResetDocument(): Promise<void> {
    if (!this.document)
      throw new Error('[FreigabeComponent] onResetDocument: no document');

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        question: 'Möchten sie die das Dokument wirklich zurücksetzen?',
        dismiss: true
      });
    if(result !== 'YES')
      return;
    const workflow = await firstValueFrom(this.workflow$);

    if(!workflow.detail.archiveKey || !workflow.detail.fileKey)
      return;

    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.mutations.remove,
      workflow.detail.fileKey
    );
    this.documentLoading = true;
    this.document = undefined;
    this.cdRef.detectChanges();

    try {
      this.document = (
        await this.store.dispatch(
          SOFTLINE_FEATURE_ARCHIVE,
          ArchiveStore.actions.download,
          {
            file: {
              archiveKey: workflow.detail.archiveKey,
              fileKey: workflow.detail.fileKey,
              metaData: JSON.parse(base64Decode(workflow.detail.fileKey)).metaData,
            },
          }
        )
      ).content;
      this.documentLoading = false;
      this.documentEdited = false;
      this.cdRef.detectChanges();
    }
    catch (e) {
      this.documentLoading = false;
      showRequestErrors(this.store, e);
      this.cdRef.detectChanges();
    }
  }

  async onNext(): Promise<void> {
    const workflow = await firstValueFrom(this.workflow$);
    const task = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: 'Freigabe',
        subtitle: `${workflow.detail.belegartKopferw} ${workflow.detail.belegnummer} freigeben`,
        options: workflow.availableTasks.map((o) => ({
          value: o.id,
          label: o.displayName,
          icon: 'fa-regular fa-plus',
        })),
        dismiss: true,
      }
    );
    if (task === 'DISMISSED')
      return;

    if(!workflow.detail.wfprocessid)
      throw new Error('[freigabe.component] next:  No WfProcessId')
    try {
      this.saving$.next(true);
      await this.mediator.next({ name: SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT })
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.next,
        { id: workflow.id, task }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#FREIGABE.MESSAGES.SUCCESS.NEXT'
      );
      if (!result.task) {
        this.store.commit(
          SOFTLINE_FEATURE_FREIGABE,
          FreigabeStore.mutations.remove,
          result
        );
        await this.navigateBack();
      }
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.saving$.next(false);
    }
  }

}
