import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import { Arteh } from '../../types/arteh';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, startWith, tap } from 'rxjs';
import { Dictionary } from '@softline/core';
import { combineLatestWith, filter, map } from 'rxjs/operators';
import { StornoBewe } from '../../types/return-note';
import { BeweLf } from '../../types/bewe-lf';
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from '@angular/forms';
import {CommonModule, DecimalPipe} from '@angular/common';
import {CalculationStrategy} from './strategies/calculation.strategy';
import {KzAusgabe2CalculationStrategy} from './strategies/kz-ausgabe-2/kz-ausgabe-2-calculation.strategy';
import {KzAusgabe1CalculationStrategy} from './strategies/kz-ausgabe-1/kz-ausgabe-1-calculation.strategy';
import {KzAusgabeNCalculationStrategy} from './strategies/kz-ausgabe-n/kz-ausgabe-n-calculation.strategy';
import {KzAusgabeAllCalculationStrategy} from './strategies/kz-ausgabe-all/kz-ausgabe-all-calculation.strategy';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-extended-unit-dialog-component',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './extended-unit-dialog.component.html',
  styleUrls: ['./extended-unit-dialog.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendedUnitDialogComponent implements OnInit, Modal<{ amount: number, arteh: Arteh } | null> {

  private close!: (result: { amount: number, arteh: Arteh } | null) => void;
  private quantities$ = new BehaviorSubject<Dictionary<number | null>>({});
  smallestUnit!: Arteh;

  anyInputFocused = false;

  // tslint:disable-next-line:variable-name
  private _beweLf!: BeweLf;

  private artehBeans$ = new BehaviorSubject<Arteh[]>([]);

  readonly formGroup = this.fb.group({},  { updateOn: 'blur' });

  // The primary stream which calculates the sum
  // and also syncs the form fields to the outcome
  readonly sum$: Observable<number> = this.quantities$.pipe(
      filter(o => !!o && Object.values(o)?.length >= 1),
      debounceTime(200),
      map(Object.entries),
      map(kv => {
        const sum = kv.reduce((prev, [id, curr]) => prev + (this.convertInput(+id, curr || 0) ?? 0), 0);

        // Clear all form fields
        // They will get populated in the for loop below
        // this.formGroup.reset({ emitEvent: false, onlySelf: true });

        for (const [id, value] of kv) {
          this.formGroup.patchValue(
            { [`${id}`]: value === 0 ? null : this.roundStr(value) },
            { emitEvent: false, onlySelf: true }
          );
        }

        return sum;
      }),
      // map(sum => sum > this.smallestUnit?.maxRetourmenge ? this.smallestUnit.maxRetourmenge : sum),
      distinctUntilChanged(),
      startWith(0),
  );

  readonly artehs$: Observable<Arteh[]> = this.quantities$.pipe(
      combineLatestWith(this.artehBeans$),
      filter(([, beans]) => !!beans && beans?.length > 0),
      debounceTime(200),
      map(([dict, artehBeans]) => {
        return artehBeans?.map(arteh => ({
          ...arteh,
          maxRetourmenge: arteh?.maxRetourmenge - (dict[arteh.id] || 0),
        }));
      }),
  );

  get ausgabeEH(): string {
    return this.artehBeans$.value.find(o => o.kzausgabe === '1')?.arteh ?? '';
  }

  // Will be set by caller via `data` property on dialog definition
  get beweLf(): BeweLf { return this._beweLf; }
  set beweLf(value: BeweLf) {
    this._beweLf = value;
    this.artehBeans$.next(value.artehBeans);

    value.artehBeans.forEach(bean => {
      this.formGroup.addControl(
        bean.id + '',
        this.fb.control(null, [Validators.min(0), /* Validators.max(bean.maxRetourmenge ?? 0) */])
      );
    });

    this.smallestUnit = value.artehBeans.reduce((a, b) => a.maxRetourmenge > b.maxRetourmenge ? a : b);
  }

  readonly unitById = (index: number, item: Arteh) => item?.id ?? index;

  constructor(private fb: UntypedFormBuilder, private numberPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(value => {
      console.log('subscribe...');
      this.formChanged(value);
    });
  }

  registerCloseHandler(handler: (result: { amount: number, arteh: Arteh } | null) => void) {
    this.close = handler;
  }

  closeDialog(sum: number): void {
    this.close({ amount: sum, arteh: this.artehBeans$.value.find(o => o.arteh === this.ausgabeEH) ?? this.smallestUnit });
  }

  cancel(): void {
    this.close(null);
  }

  onUserInputChanged(dict: Dictionary<number | null>, arteh: Arteh, inputValue: number | null): Dictionary<number | null> {
    const baseArteh = this.artehBeans$.value.find(o => o.arteh === this.beweLf.einheit)

    if (!baseArteh || !inputValue)
      return dict

    const quantityDictionary: Dictionary<number | null> = dict;
    const strategy = this.getStrategy(this.artehBeans$.value)

    for (const key of Object.keys(dict)) {
      quantityDictionary[key] = null
    }

    return strategy.calculate({
      inputValue,
      inputArteh: arteh,
      dictionary: quantityDictionary,
      artehs: this.artehBeans$.value,
      baseArteh
    })
  }

  private getStrategy(artehs: Arteh[]): CalculationStrategy {
    const hasKzausgabe1Arteh = !!artehs.find(o => o.kzausgabe === '1')
    const hasKzausgabe2Arteh = !!artehs.find(o => o.kzausgabe === '2')

    if (hasKzausgabe2Arteh && hasKzausgabe1Arteh) {
      return new KzAusgabeAllCalculationStrategy()
    } else if (hasKzausgabe2Arteh && !hasKzausgabe1Arteh) {
      return new KzAusgabe2CalculationStrategy()
    } else if (hasKzausgabe1Arteh && !hasKzausgabe2Arteh) {
      return new KzAusgabe1CalculationStrategy()
    } else {
      return new KzAusgabeNCalculationStrategy()
    }
  }

  /**
   * The logic and calculation for a specific unit and the users or calculated/corrected input value
   * Based on various conditions the logic is different, therefore this method is unfortunately complex
   * @param dict the dictionary which will be used for storing the calculation results and then returned
   * @param arteh the associated unit to the form input
   * @param value the value which was entered in the form (UI)
   * @returns dict The mutated dictionary from the argument
   */
  quantityChanged(dict: Dictionary<number | null>, arteh: Arteh, value: string | number | unknown): Dictionary<number | null> {
    if (typeof value !== 'string' && typeof value !== 'number')
      return dict;

    const numeric = +(typeof value === 'string' ? value?.replace(',', '.') : value);
    const quantityDictionary: Dictionary<number | null> = dict;

    if (numeric === 0) {
      quantityDictionary[arteh.id + ''] = null;
    }

    if (arteh?.kzausgabe === '1') {
      const ausgabeEh2 = this.artehBeans$.value.find(o => o.kzausgabe === '2');

      const result = (numeric || 0) * arteh.faktor;

      if (!ausgabeEh2) {
        quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + numeric;
      } else {
        if (ausgabeEh2.faktor > arteh.faktor && result >= ausgabeEh2.faktor) {
          const rest = result % ausgabeEh2.faktor;
          quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh2.id + ''] ?? 0) + Math.ceil(result / ausgabeEh2.faktor);
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.floor(rest);
        } else if (ausgabeEh2.faktor > arteh.faktor && result < ausgabeEh2.faktor) {
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.ceil(result * arteh.faktor);
        } else if (arteh.faktor > ausgabeEh2.faktor && result >= arteh.faktor) {
          const rest = result % arteh.faktor;
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.floor(result / arteh.faktor);
          quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh2.id + ''] ?? 0) + Math.ceil(rest);
        } else {
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.floor(result / arteh.faktor);
        }
      }
    }

    if (arteh?.kzausgabe === '2') {
      const ausgabeEh1 = this.artehBeans$.value.find(o => o.kzausgabe === '1');

      const result = (numeric || 0) * arteh.faktor;

      if (!ausgabeEh1) {
        quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.ceil(result / arteh.faktor);
      } else {
        if (ausgabeEh1.faktor > arteh.faktor) {
          const rest = result % ausgabeEh1.faktor;
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.ceil(result / arteh.faktor);
          quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.floor(rest);
        } else {
          const rest = result % arteh.faktor;
          quantityDictionary[arteh.id + ''] = (quantityDictionary[arteh.id + ''] ?? 0) + Math.floor(result / arteh.faktor);
          quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.ceil(rest);
        }
      }
    }

    if (arteh?.kzausgabe === 'N') {
      const ausgabeEh1 = this.artehBeans$.value.find(o => o.kzausgabe === '1');
      const ausgabeEh2 = this.artehBeans$.value.find(o => o.kzausgabe === '2');

      const result = (numeric || 0) * arteh.faktor;

      if (ausgabeEh1 && ausgabeEh2) {
        if (ausgabeEh2.faktor > ausgabeEh1.faktor) {
          const rest = result % ausgabeEh2.faktor;

          if (rest > ausgabeEh1.faktor && result > ausgabeEh2.faktor && ausgabeEh2.faktor <= 1) {
            quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh2.id + ''] ?? 0) + Math.ceil(result / ausgabeEh2.faktor);
            quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.floor(rest);
          } else if (rest > ausgabeEh1.faktor && result < ausgabeEh2.faktor) {
            quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.ceil(result);
          } else {
            quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh2.id + ''] ?? 0) + Math.floor(result / ausgabeEh2.faktor);
            quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.ceil(rest);
          }
        } else {
          const rest = result % ausgabeEh1.faktor;
          quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.floor(result / ausgabeEh1.faktor);
          quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.ceil(rest);
        }
      } else if (ausgabeEh1 && !ausgabeEh2) {
        quantityDictionary[ausgabeEh1.id + ''] = (quantityDictionary[ausgabeEh1.id + ''] ?? 0) + Math.ceil(result / ausgabeEh1.faktor);
      } else if (ausgabeEh2 && !ausgabeEh1) {
        quantityDictionary[ausgabeEh2.id + ''] = (quantityDictionary[ausgabeEh2.id + ''] ?? 0) + Math.ceil(result / ausgabeEh2.faktor);
      }
    }

    return quantityDictionary;
  }

  private formChanged(values: Dictionary<string | number>): void {
    let changeData: { value: number | null; arteh: Arteh }[] = [];
    const newFormValues: Dictionary<number | null> = {};

    const artehDict: Dictionary<Arteh> = {};
    this.artehBeans$.value.forEach(o => artehDict[o.id + ''] = o);

    for (const [key, value] of Object.entries(values).reverse()) {
      const numericValue = +((typeof value === 'string' ? value?.replace(',', '.') : value) || 0);
      newFormValues[key] = numericValue;

      if (!artehDict[key])
        continue;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      changeData.push({value: numericValue === 0 ? null : numericValue, arteh: artehDict[key]!})
    }

    // When there is a value in the form that is not a "Ausgabe Einheit" then we
    // recalculate the complete form and only use the non-ausgabe einheit value (others will be ignored)
    if (changeData.find(o =>o.arteh.kzausgabe === 'N' && o?.value !== null)) {
      const onlyNonAusgabe = changeData.filter(o => o.arteh.kzausgabe === 'N');
      const onlyAusgabe = changeData.filter(o => o.arteh.kzausgabe !== 'N').map(o => ({ ...o, value: null }));
      changeData = [...onlyAusgabe, ...onlyNonAusgabe];
    }


    let updatedFormValues: Dictionary<number | null> | null = null
    console.log(changeData)

    for (const { arteh, value } of changeData) {
      const result = this.onUserInputChanged(newFormValues, arteh, value)

      /*
      if (updatedFormValues) {
        for (const [key, value] of Object.entries(result)) {
          if (updatedFormValues[key] && value === 0) {
            updatedFormValues[key] = updatedFormValues[key] ?? 0
            console.log('set vale of key ' + key, updatedFormValues[key] ?? 0)
          } else if (value && value !== 0) {
            updatedFormValues[key] = value
            console.log('else if ----- set vale of key ' + key, value)
          }
        }
        console.log('after merge update: ', updatedFormValues)
      } else {
        updatedFormValues = result
      }*/

      updatedFormValues = result
      console.log(`result for input of ${value} ${arteh.arteh}: `, result)
      console.log(`values after update of ${value} ${arteh.arteh}: `, updatedFormValues)
    }

    console.log(updatedFormValues)

    // update the stream which recalculates the total and updates the form fields
    // emits the FormControlName (which is the id of the unit as string) and the new value (dictionary)
    this.quantities$.next(updatedFormValues ?? {});
  }

  /**
   * Recalculates the quantities (values) for the input fields of the form
   * Also takes care of validating the form (maxRetourmenge)
   * The values which are displayed after calculation are generated/calculated/edited here
   * This method gets called whenever the formGroups `valueChanges` emits
   * @param values The current form state (this.formGroup.value)
   * @private
   */
  private quantitiesChanged(values: Dictionary<string | number>): void {
    let currentValues: Dictionary<number | null> = {};
    let artehValuesToSet: { value: string | number | null; arteh: Arteh }[] = [];

    const onlyAusgabeEH = this.artehBeans$.value.filter(o => o.kzausgabe !== 'N');

    // Create a dictionary from the array to efficiently access the unit per id in the for-loops
    const ehDict: Dictionary<Arteh> = {};
    this.artehBeans$.value.forEach(o => ehDict[o.id + ''] = o);

    // Find the largest and smallest ausgabe-einheit
    // This is later needed in the second for loop in order to check the max-value
    // and limit/adjust the values for the inputs accordingly
    const largestAusgabeEH = onlyAusgabeEH.reduce((a, b) => a?.faktor > b.faktor ? a : b);
    const smallestAusgabeEH = onlyAusgabeEH.reduce((a, b) => a?.faktor < b.faktor ? a : b);

    // Create an array out of the form controls and their respective unit
    for (const [id, value] of Object.entries(values)) {
      const arteh = ehDict[id];

      if (!arteh)
        continue;

      const entry = { value: value as string | number | null, arteh };
      artehValuesToSet.push(entry);
    }

    // When the array contains at least one entry which is not an ausgabe-einheit and this entry is not empty (null)
    // then we reset all other (ausgabe-einheiten) form fields as we want to recalculate the entire dialog
    // We do this, because we want to achieve the same behaviour as the softline dialog in verkauf (Softtop)
    // All other entries in form-fields for ausgabe-einheiten we just add the amount (no reset)
    if (
        artehValuesToSet.find(o =>
          o.arteh.kzausgabe === 'N' &&
          !isNaN(+(typeof o?.value === 'string' ? o?.value?.replace(',', '.') : (o?.value || 0)))
          && o?.value !== null
        )
    ) {
      const onlyNonAusgabe = artehValuesToSet.filter(o => o.arteh.kzausgabe === 'N');
      const onlyAusgabe = artehValuesToSet.filter(o => o.arteh.kzausgabe !== 'N').map(o => ({ ...o, value: null }));
      artehValuesToSet = [...onlyAusgabe, ...onlyNonAusgabe];
    }

    // accumulate the values to get values for the form inputs and the sum calculation
    // If any non ausgabe-einheit is included in the array we reset the form otherwise we add the values
    artehValuesToSet.forEach(({ value, arteh }) =>
        currentValues = this.quantityChanged(currentValues, arteh, value)
    );

    // Sort the calculated key-value-pairs so that the *largest* factor is first
    // We need the sorted values in order to ensure that we always have already filled the largest ausgabe-einheit
    // Only when the largest is filled we can calculate the difference (rest which may be needed) in the for-loop below
    const sortedCurrentValues = Object.entries(currentValues).sort(([keyA], [keyB]) => {
      const a = ehDict[keyA];
      const b = ehDict[keyB];

      if (a && b)
        return b.faktor - a.faktor;

      return 0;
    });

    let rest = 0;
    let sum = 0;
    let nonLimitedSum = 0;

    for (const [id, value] of sortedCurrentValues) {
      const arteh = ehDict[id];

      if (!arteh)
        continue;

      if (largestAusgabeEH.id === arteh.id && arteh.kzausgabe !== 'N') {
        if (+(value || 0) < largestAusgabeEH.maxRetourmenge) {
          sum += +(value || 0) * largestAusgabeEH.faktor;
          nonLimitedSum = sum;
          rest = (largestAusgabeEH.maxRetourmenge - +(value || 0)) * largestAusgabeEH.faktor;
          continue;
        } else {
          const maxPossibleAmount = Math.floor(arteh.maxRetourmenge);
          currentValues[id] = maxPossibleAmount;
          sum += maxPossibleAmount * largestAusgabeEH.faktor;
          nonLimitedSum = +(value || 0) * largestAusgabeEH.faktor;
          rest = (+(value || 0) - arteh.maxRetourmenge) * arteh.faktor;
        }
      }

      // When we have the smallest ausgabe-einheit we calculate the missing amount
      // The amount should only be overwritten if the difference is smaller than the calculated value
      // This way we ensure that the calculated value stays, but if the calculated value is too large we set the max value
      if (smallestAusgabeEH.id === arteh.id && arteh.kzausgabe !== 'N') {
        const difference = smallestAusgabeEH.maxRetourmenge - sum;

        if (difference < +(value || 0) || nonLimitedSum > smallestAusgabeEH.maxRetourmenge) {
          currentValues[id] = Math.ceil(difference);
        }
      }
    }

    // update the stream which recalculates the total and updates the form fields
    // emits the FormControlName (which is the id of the unit as string) and the new value (dictionary)
    this.quantities$.next(currentValues);
  }

  private convertInput(id: number, inputValue: number): number | undefined {
    const artehBean = this.artehBeans$.value.find(o => o.id === id);

    if (!artehBean)
      return undefined;

    return this.round((inputValue || 0) * artehBean.faktor);
  }

  private round(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  private roundStr(num: number): string | null {
    return this.numberPipe.transform(num, '1.0-3') ?? null
  }
}
