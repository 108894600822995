<ng-container *ngIf="bewelf">
    <div class="c row space-between no-wrap center-v pt-3 pb-3">
        <div class="c column no-wrap pl-3 pr-3 border-box">
            <div class="g custom-grid">
              <div>
                <span class="text-sm color-light">{{  bewelf?.artikelnummer  }}</span>
                <i class="fa-regular fa-images info-icon" *ngIf="(value?.files || []).length > 0"></i>
              </div>
              <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
                <i class="fa-regular fa-note-sticky info-icon" *ngIf="(value?.vermerk || '').length > 0"></i>
                <div class="text-ellipsis overflow-hidden opacity-70">{{ value?.vermerk }}</div>
              </div>
            </div>

            <div class="mt-1 mb-1">
              <p class="text-base font-semibold" *ngIf="bewelf?.bezeichnung1?.length">{{ bewelf?.bezeichnung1 }}</p>
              <p class="text-base text-light" *ngIf="bewelf?.bezeichnung2?.length">{{ bewelf?.bezeichnung2 }}</p>
              <p class="text-base text-light" *ngIf="bewelf?.bezeichnung3?.length">{{ bewelf?.bezeichnung3 }}</p>
            </div>
            <p class="text-base font-semibold mt-1 mb-1" *ngIf="!bewelf?.bezeichnung1 && bewelf?.zeilentext?.length">{{ bewelf?.zeilentext }}</p>
            <span class="color-tertiary" *ngIf="artehBean">
              {{ (artehBean?.maxRetourmenge || 0) | number:'1.0-3' }} {{ artehBean.arteh  }} {{ '#DELIVERY_NOTE.RETOUR.LIST_ITEM_QTY_SUFFIX' | translate }}
            </span>

            <span class="text-base mt-1 italic" *ngIf="value && isSearchItem(value)">Retour zu Lieferschein {{ value?.deliveryNote }} vom {{value?.date | date}}</span>
        </div>
        <div class="c row end no-wrap center-v pl-3 border-box ">
            <div class="flex relative">
                <small class="absolute left-0 bottom-9 text-error-700 text-xs font-medium" *ngIf="(!addedFromSearch && hasError) || (hasError && (addedFromSearch && !hasSplit))">
                  @if (value?.artehBean?.maxRetourmenge) {
                    max. {{ value?.artehBean?.maxRetourmenge | number: '1.2-3' }} {{ value?.artehBean?.arteh }}
                  } @else if (value?.menge === undefined || value?.menge === null || (value?.menge ?? 0) < 0 ) {
                    Keine Menge
                  } @else {
                    Retourmenge überschritten
                  }
                </small>

                <small class="absolute left-0 bottom-9 text-warning-700 text-xs font-medium" *ngIf="addedFromSearch && hasSplit">
                  Wird aufgeteilt
                  <!-- max. {{ value?.artehBean?.maxRetourmenge | number: '1.2-3' }} {{ value?.artehBean?.arteh }} -->
                </small>

                <ng-container>
                    <soft-number-input placeholder="Menge"
                                       [value]="value?.menge"
                                       [class.error]="hasError"
                                       [changeTrigger]="deliveryNoteConfig?.openDialogMaxMenge ? 'blur' : 'input'"
                                       style="max-width: 80px"
                                       (valueChange)="onChange(value, menge.value, arteh.value, menge)"
                                       #menge>
                    </soft-number-input>

                    <div style="width: 100px;" class="ml-2">
                        <soft-select placeholder="EH"
                                     [value]="value?.artehBean ?? artehBean"
                                     [class.hidden]="bewelf.artehBeans?.length === 1"
                                     (valueChange)="onChange(value, menge.value, arteh.value, menge)"
                                     #arteh>
                            <soft-select-option [value]="option"
                                                *ngFor="let option of bewelf.artehBeans">
                              {{option?.arteh}}
                            </soft-select-option>
                        </soft-select>
                      <span class="soft-title ml-3 color-light"
                            style="width: 100px"
                            [class.hidden]="bewelf.artehBeans?.length !== 1">{{ bewelf?.einheit || ''}}</span>
                    </div>
                    <small *ngIf="artehBean" class="absolute left-0 top-9 text-gray-600 text-xs">
                      max. {{ artehBean?.maxRetourmenge | number: '1.2-3' }} {{ artehBean?.arteh }}
                    </small>
                </ng-container>
            </div>

            <span class="detail-area ml-4" (click)="onEditBeweClick(value)">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </span>
            <span class="detail-area">
                <i class="fa-regular fa-delete-left pointer p-1"
                   [ngStyle]="{ 'color': (!!(value?.menge) || !!menge?.value) ? 'var(--colorError)' : 'rgba(0, 0, 0, 0.33)'}"
                   (click)="onDeleteBeweClick(value); menge.value = null"></i>
            </span>
        </div>
    </div>
</ng-container>
