import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {SOFTLINE_SERVICE_UUID, Store} from '@softline/core';
import {SOFTLINE_FEATURE_DELIVERY_NOTE} from '../../delivery-note.shared';
import {DeliveryNotesStore} from '../../stores';
import {BehaviorSubject, combineLatest, Observable, of, shareReplay} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {DeliveryNote} from '../../types/delivery-note';
import {animate, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {
  BackNavigable, BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors, Refreshable, RefreshService,
  ScannerStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import moment from 'moment';
import {CommonModule} from '@angular/common';
import {SegmentedControlComponent} from '../../components/segemented-control/segemented-control.component';
import {DeliveryNoteCardComponent} from '../../components/delivery-note-card/delivery-note-card.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-overview',
  standalone: true,
  imports: [CommonModule, UiCoreModule, SegmentedControlComponent, DeliveryNoteCardComponent],
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-out', style({transform: '*'}))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-out', style({transform: '*'}))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class OverviewComponent implements OnInit, OnDestroy, BackNavigable, Refreshable {

  private readonly uuidLoad: string = this.uuid();
  private readonly searchInput$ = new BehaviorSubject<string>('');
  private readonly filterType$ = new BehaviorSubject<DeliveryNote['type']['type']>('AUSLIEFERUNG');

  readonly viewedDate$ = this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.viewedDate);

  // disables animations (angular) for this component and all children
  // needed for fixing the glitch on initial load (see also: `loadingChanged$`)
  @HostBinding('@.disabled')
  public animationsDisabled = true;

  readonly activeSegment$ = this.filterType$.asObservable();

  readonly loading$ = this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.loading);

  // enables the animations shortly after loading finished (delaying)
  // this fixes weird slide in when the loading state changes (initial load)
  readonly loadingChanged$ = this.loading$.pipe(
      delay(200),
      map((isLoading) => this.animationsDisabled = isLoading)
  );

  readonly deliveryNotes$ = this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.all);

  readonly filteredNotes$: Observable<DeliveryNote[]> = combineLatest([
      this.deliveryNotes$,
      this.searchInput$,
      this.filterType$,
      this.viewedDate$
  ]).pipe(
    map(([items, input, activeFilter, date]) => ({
      items: this.filterItemsByType(items, activeFilter),
      input: input?.toLowerCase(),
      date: moment(date).format('YYYY-MM-DD')
    })),
    map(({items, input, date}) => {
      if (input?.length < 1)
        return items?.filter(note => note.lieferdatum === date);
      else
        return items
            .filter(note => {
              return note?.adresseBean?.kurzbez?.toLowerCase().includes(input)
                || note?.adresseBean?.vorname?.toLowerCase().includes(input)
                || note?.adresseBean?.nachname?.toLowerCase().includes(input)
                || note?.adresseBean?.strasse?.toLowerCase().includes(input)
                || note?.adresseBean?.ort?.toLowerCase().includes(input)
                || note?.belegnummer.toString().toLowerCase().includes(input);
            })
            .filter(note => note.lieferdatum === date);
    }),
    shareReplay(1)
  );

  readonly noteTypeSegments$ = combineLatest([
      this.deliveryNotes$,
      this.viewedDate$
  ]).pipe(
      map(([notes, date]) => {
        const notesForDate = notes.filter(note => note.lieferdatum === moment(date).format('YYYY-MM-DD'));

        const segments: any[] = [];

        if (notesForDate?.filter(o => o.type.type === 'AUSLIEFERUNG')?.length > 0) {
          segments.push({
            value: 'AUSLIEFERUNG',
            label: '#DELIVERY_NOTE.OVERVIEW.SEGMENTS.LIEFERSCHEINE',
            hasEntries: notesForDate?.filter(o =>
              o.type.type === 'AUSLIEFERUNG'
              || o.type.type === 'BARVERKAUF'
              || o.type.type === 'KREDITRECHNUNG'
            )?.length > 0
          });
        }

        if (notesForDate?.filter(o => o.type.type === 'MATERIAL')?.length > 0) {
          segments.push({
            value: 'MATERIAL',
            label: '#DELIVERY_NOTE.OVERVIEW.SEGMENTS.MATERIALSCHEINE',
            hasEntries: notesForDate?.filter(o => o.type.type === 'MATERIAL')?.length > 0
          });
        }

        if (segments?.length === 1) {
          this.filterType$.next(segments[0].value);
        }

        if (segments?.length === 2) {
          this.filterType$.next('AUSLIEFERUNG');
        }

        return segments;
      }),
  );

  constructor(
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    private cdRef: ChangeDetectorRef,
    private store: Store,
    protected router: Router,
    private refreshService: RefreshService,
    private backNavigationService: BackNavigationService) { }

  async ngOnInit(): Promise<void> {
    this.refreshService.add(this)
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, 'Auslieferungen');
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: OverviewComponent,
      commands: this.createCommands()
    });
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.collection.loadMany, {
        clear: true,
        token: this.uuidLoad
      });
    }
    catch (e: unknown) {
      showRequestErrors(this.store, e);
    }
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.refreshService.remove(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, OverviewComponent);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async refresh(): Promise<void> {
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.collection.loadMany, {
        clear: true
      });
    }
    catch (e: unknown) {
      showRequestErrors(this.store, e);
    }
  }

  protected createCommands(): Command[] {
    return [
      {
        name: 'Scan',
        class: 'menu top-menu top-menu-right flex self-center justify-center items-center',
        icon: 'fa-regular fa-barcode-scan',
        execute: async () => {
          try {
            const scan = await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, { labelType: ['code39'] });

            if (!scan || !scan?.data)
              return;

            const lieferschein = await this.store.dispatch(
                SOFTLINE_FEATURE_DELIVERY_NOTE,
                DeliveryNotesStore.actions.getLieferscheinForScan,
                { labelType: scan?.labelType, code: scan.data },
            );

            if (!lieferschein)
              return;

            await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.success, {
              title: '#DELIVERY_NOTE.MESSAGES.OVERVIEW_SCAN_SUCCESS.TITLE',
              message: '#DELIVERY_NOTE.MESSAGES.OVERVIEW_SCAN_SUCCESS.MESSAGE'
            });
          } catch (e) {
            console.log('Error: ', e);

            if (e && e instanceof Error && e?.message === 'InputScanner: canceled scan') {
              return;
            }

            if (e && (e as any)?.status === 420) {
              await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
                title: 'Der Lieferschein wurde bereits ausgeliefert!'
              });
              return;
            }

            showRequestErrors(this.store, e as Error);
          }
        }
      }
    ];
  }

  onSearchInputChange(newValue: string): void {
    this.searchInput$.next(newValue);
  }

  onChangeListType({value}: { value: DeliveryNote['type']['type'], label: string }): void {
    this.filterType$.next(value);
  }

  dateChanged(newDate: string): void {
    // Disable animations to fix a slide in of the view when transitioning from date with no data to date with data
    this.animationsDisabled = true;
    this.store.commit(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.mutations.setViewedDate, newDate);
    // Enable animations after we changed the date
    setTimeout(() => this.animationsDisabled = false, 10);
  }

  async showDetails(id: number) {
    let lockedByAnwender: string | undefined;

    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_DELIVERY_NOTE,
        DeliveryNotesStore.actions.exzess,
        { idlf: id, clear: false }
      );

      if (result && result?.success === false && (result?.anwender?.anwendername || result?.anwender?.anwender)) {
        lockedByAnwender = result?.anwender?.anwendername ?? result?.anwender?.anwender;
      }
    } catch (e) {
      console.log('ERROR: ', e);
    } finally {
      if (lockedByAnwender) {
        await this.router.navigate(['lieferscheine', id], { queryParams: { lockedByAnwender }});
      } else {
        await this.router.navigate(['lieferscheine', id]);
      }
    }
  }

  private filterItemsByType(items: readonly DeliveryNote[], type: DeliveryNote['type']['type']): DeliveryNote[] {
    if (type === 'MATERIAL')
      return items.filter(o => o.type.type === type);

    return items.filter(o =>
      o.type.type === 'AUSLIEFERUNG'
      || o.type.type === 'BARVERKAUF'
      || o?.type?.type === 'KREDITRECHNUNG'
    );
  }
}
