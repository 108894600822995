import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Skeleton } from './skeleton-types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-loading-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSkeletonComponent implements OnInit {
  @Input() skeleton: Skeleton | any = { type: 'text' };

  constructor() {}

  ngOnInit(): void {}
}
