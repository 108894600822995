import { ImportedNgModuleProviders, importProvidersFrom, StaticProvider } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_TEMPLATE_SERVICE,
  SOFTLINE_STORE_VALIDATION_SERVICE,
  SoftlineEntityService,
  SoftlineTemplateService,
  SoftlineValidationService,
  StoreModule
} from '@softline/core';
import { registerApplication, SOFTLINE_CONFIG_DASHBOARD_COMPONENT } from '@softline/application';
import { default as de } from '../i18n/de.json';
import { SOFTLINE_PERMISSION_TIMESTAMP } from './stempelerfassung.permissions';
import { StempelerfassungWidget } from './widgets/stempelerfassung/stempelerfassung.widget';
import {
  SOFTLINE_FEATURE_TIMESTAMP,
  SOFTLINE_FEATURE_TIMESTAMP_CODE,
  SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
  TimestampCodeStore,
  TimestampEmployeeStore,
  TimestampStore
} from './stempelerfassung.shared';
import {
  SOFTLINE_API_EMPLOYEES,
  SOFTLINE_API_TIMESTAMP,
  SOFTLINE_API_TIMESTAMP_CODE,
  SOFTLINE_API_TIMESTAMP_TEMPLATE,
  SOFTLINE_API_TIMESTAMP_VALIDATION
} from './stempelerfassung.api';
import { TimestampCurrentEmployeeStore } from './store/current-employee.store';

export const stempelerfassungWidgetProviders = [
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: StempelerfassungWidget,
      permission: SOFTLINE_PERMISSION_TIMESTAMP
    },
  }
]

export const stempelerfassungProviders: (StaticProvider | ImportedNgModuleProviders)[] = [
  registerApplication({
    name: 'stempelerfassung',
    title: '#TIMESTAMP.APP',
    icon: 'fa-regular fa-stopwatch',
    route: '/stempelerfassung',
    permission: SOFTLINE_PERMISSION_TIMESTAMP,
    translations: { de }
  }),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
      feature: TimestampEmployeeStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_EMPLOYEES),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TIMESTAMP,
      feature: TimestampStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_TIMESTAMP),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
        {
          provide: SOFTLINE_STORE_VALIDATION_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineValidationService(
              service,
              SOFTLINE_API_TIMESTAMP_VALIDATION
            ),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
        {
          provide: SOFTLINE_STORE_TEMPLATE_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineTemplateService(
              service,
              SOFTLINE_API_TIMESTAMP_TEMPLATE
            ),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TIMESTAMP_CODE,
      feature: TimestampCodeStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_TIMESTAMP_CODE),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
      feature: TimestampCurrentEmployeeStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineEntityService(service, SOFTLINE_API_EMPLOYEES),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    })
  ),

];
