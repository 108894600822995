<h2 class="text-xl pr-3 pl-3 pt-2 mb-1 mt-2">{{ '#DELIVERY_NOTE.DIALOGS.UNSAVED_CHANGES.TITLE' | translate }}</h2>

<div class="c row pr-3 pl-3 pb-3 border-box">
    <span>{{ '#DELIVERY_NOTE.DIALOGS.UNSAVED_CHANGES.TEXT' | translate }}</span>
</div>

<div class="c row border-box no-wrap mt-2 size-aware-padding">
    <button class="soft-button secondary custom-size" (click)="close('discard')">
        {{ '#DELIVERY_NOTE.DIALOGS.UNSAVED_CHANGES.CANCEL' | translate }}
    </button>
    <button class="soft-button primary custom-size" (click)="close('save')">
        {{ '#DELIVERY_NOTE.DIALOGS.UNSAVED_CHANGES.SAVE' | translate }}
    </button>
</div>
