import {Component, OnDestroy, OnInit, viewChildren} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  iif,
  Observable,
  of, share,
  startWith,
  Subscription
} from 'rxjs';
import {ReturnNote, SearchStornoBewe} from '../../types/return-note';
import {combineLatestWith, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE} from '../../delivery-note.shared';
import {ReturnNoteStore} from '../../stores/return-note.store';
import {DeliveryNote} from '../../types/delivery-note';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {Store} from '@softline/core';
import {DeliveryNotesStore} from '../../stores';
import {DeliveryNoteService} from '../../services/delivery-note.service';
import {BeweLf} from '../../types/bewe-lf';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {
  ReturnNoteSearchItemComponent
} from '../../components/return-note-search-item/return-note-search-item.component';
import {ArtikelStorno} from '../../types/artikel-storno';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-return-note-search-items',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReturnNoteSearchItemComponent],
  templateUrl: './return-note-search-items.component.html',
  styleUrls: ['./return-note-search-items.component.scss']
})
export class ReturnNoteSearchItemsComponent implements OnInit, OnDestroy, BackNavigable {

  private subscription?: Subscription;

  private id$ = this.route.paramMap.pipe(map(o => o.get('id')));
  private changes = new Map<number | string, SearchStornoBewe>();

  readonly searchInput$ = new BehaviorSubject<string | null>(null);
  readonly lockResults$ = new BehaviorSubject<boolean>(false);

  private readonly searchListItemComponents = viewChildren<ReturnNoteSearchItemComponent>('searchListItemComponent');

  readonly returnNote$: Observable<ReturnNote> = this.id$.pipe(
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_RETURN_NOTE,
        ReturnNoteStore.getters.preparedEntity,
        id
      )
    )
  );

  readonly deliveryNote$: Observable<DeliveryNote> = this.returnNote$.pipe(
    switchMap(returnNote =>
      this.store.observe(
        SOFTLINE_FEATURE_DELIVERY_NOTE,
        DeliveryNotesStore.getters.collection.preparedEntity,
        returnNote.idlf
      )
    )
  );

  readonly searchResults$: Observable<{ results: ArtikelStorno[]; loading: boolean }> = this.searchInput$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    withLatestFrom(this.returnNote$),
    switchMap(([query, returnNote]) => this.getSearchResult$(returnNote.idlf, query)),
    catchError(() => of({ results: [], loading: false })),
    startWith({ results: [], loading: true }),
    share(),
  );

  private readonly getSearchResult$ = (id: number, query: string | null) => iif(
    () => query === null || query?.length < 3,
    of({ loading: false, results: [] }),
    this.service.getOffeneArtikel(+id, query ?? '').pipe(
      map(results => ({ results, loading: false })),
      startWith({ loading: true, results: [] }),
      catchError(() => {
        return of({ loading: false, results: [] });
      })
    )
  )

  readonly trackByFn = (index: number, deliveryNote: DeliveryNote) => deliveryNote.id ?? index;
  readonly trackBeweLf = (index: number, bewe: BeweLf) => bewe;

  constructor(
    private store: Store,
    private service: DeliveryNoteService,
    private route: ActivatedRoute,
    private backNavigationService: BackNavigationService,
    protected router: Router,
  ) { }

  ngOnInit(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, 'Angelieferte Artikel suchen');
    this.backNavigationService.set(this);
    this.subscription = this.searchResults$.pipe(
      map(o => o.results),
      // TODO: Nachfragen bei Jan warum das so mit locked und input gelöst wurde
      combineLatestWith(this.lockResults$.pipe(startWith(false)), this.searchInput$)
    ).subscribe(([results, locked, input]) => {
        this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
          name: ReturnNoteSearchItemsComponent,
          commands: this.createCommands(results, locked, input)
        });
      });
  }

  ngOnDestroy(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, ReturnNoteSearchItemsComponent);
    this.backNavigationService.set(undefined);
  }

  async navigateBack() {
    const deliveryNoteId = this.route.snapshot.paramMap.get('id');
    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.entity, deliveryNoteId);
    await this.router.navigate(['lieferscheine', 'return-note', returnNote?.id]);
  }

  protected createCommands(artikelStornos: ArtikelStorno[], locked: boolean, input: string | null): Command[] {
    return [
      {
        icon: locked ? 'fa-regular fa-lock-open' : 'fa-regular fa-lock',
        name: locked ? 'Eingabe' : 'Sperren',
        class: (!!input && input?.length > 2) ? 'menu top-menu top-menu-right' : '',
        isVisible: of(!!input && input?.length > 2),
        canExecute: of(!!input && input?.length > 2),
        execute: () => {
          this.lockResults$.next(!locked);
        },
      },
      {
        icon: 'fa-regular fa-check',
        name: 'Übernehmen',
        class: 'menu top-menu top-menu-right',
        canExecute: of(!!input && input?.length > 2),
        execute: async () => {
          const id = this.route.snapshot.paramMap.get('id') ?? '';
          await this.onSave(id);
        },
      },
    ];
  }

  onBeweChanged(bewe: ArtikelStorno, stornoBewe: SearchStornoBewe, deliveryNote?: DeliveryNote): void {
    // stornoBewe = { ...stornoBewe, deliveryNote: deliveryNote.belegnummer, date: deliveryNote.lieferdatum };
   console.log('bewe: ', bewe);
   console.log('stornoBewe: ', stornoBewe);
   console.log('deliveryNote: ', deliveryNote);
    this.changes.set(`${bewe.idartstamm}-`, stornoBewe);
  }

  onAufsplitten(params: { menge: number, bewe: SearchStornoBewe }, lieferscheine: ArtikelStorno[]): void {
    console.log('onAufsplitten: ', params);
/*
    const allItems: { item: BeweLf; maxRetourmenge: number, lf: DeliveryNote }[] = [];

    for (const lieferschein of lieferscheine) {
      const items = lieferschein.bewelfs?.filter(o => o.idartstamm === params.bewe.bewelf.idartstamm) ?? [];

      allItems.push(
        ...items.map((item) => {
          const einheit = item.artehBeans.find(o => o.arteh === item.einheit) ?? item.artehBeans[0];

          return {
            item,
            maxRetourmenge: einheit.maxRetourmenge,
            lf: lieferschein
          }
        })
      );
    }

    console.log('all items: ', allItems);

    let fullQuantity = params.menge;
    allItems.reverse();
    // const inputValues: Record<number, { werte: { idbewelf: number, menge: number} }> = {};

    const updatedComponents = [];


    while (fullQuantity > 0) {
      console.log('full quantity: ', fullQuantity);
      const item = allItems.pop();
      console.log('while - item: ', item);

      if (!item) {
       fullQuantity = 0;
       continue;
      }

      const component = this.searchListItemComponents().find(o =>
        o.lieferschein.id === item.lf.id
        && o.bewelf.idartstamm === item.item.idartstamm
        && o.bewelf.id === item.item.id
      );



      console.log('while - component: ', component);

      if (fullQuantity >= item.maxRetourmenge) {
        component?.setMenge(component?.value, item.maxRetourmenge);
        fullQuantity = fullQuantity - item.maxRetourmenge
      } else {
        component?.setMenge(component?.value, fullQuantity);
        fullQuantity = 0;
      }
    }

    if (allItems.length > 0) {
      for (const item of allItems) {
        const component = this.searchListItemComponents().find(o =>
          o.lieferschein.id === item.lf.id
          && o.bewelf.idartstamm === item.item.idartstamm
          && o.bewelf.id === item.item.id
        );

        component?.setMenge(component?.value, 0);
      }
    } */
  }

  filterSingleSearchStornoBewe(stornoBewe: SearchStornoBewe, bewelf: BeweLf): boolean {
    // return stornoBewe?.bewelf?.id === bewelf.id;
    return stornoBewe?.bewelf?.artikelnummer === bewelf.artikelnummer && stornoBewe?.bewelf?.einheit === bewelf.einheit;
  }

  async onSave(returnNoteId: string): Promise<void> {
    for (const [, bewe] of this.changes.entries()) {
      if (!bewe.menge || (bewe?.menge === 0) || isNaN(bewe.menge)) {
        continue;
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_RETURN_NOTE,
        ReturnNoteStore.actions.upsertBewe,
        { id: returnNoteId, bewe }
      );
    }

    await this.navigateBack();
  }
}
