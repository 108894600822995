<div class="soft-card rounded mb-2 w-full">
  <ng-container *ngIf="isStorno(bewe)">
    <div class="c row space-between no-wrap center p-1 border-box gap-6">
      <div class="c column w-full">
        <div class="g custom-grid" [ngClass]="(bewelf?.bezeichnung1?.length || 0) < 1 && (bewelf?.bezeichnung2?.length || 0) < 1 && !bewelf?.menge ? 'mt-1 font-semibold' : 'text-sm color-light mb-1'">
          <div>
            <span class="text-sm color-light">{{ bewelf?.artikelnummer }}</span>
            <i class="fa-regular fa-images info-icon" *ngIf="(bewe?.files || []).length > 0"></i>
          </div>
          <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
            <i class="fa-regular fa-note-sticky info-icon" *ngIf="(bewe?.vermerk || '').length > 0"></i>
            <div class="text-ellipsis overflow-hidden opacity-70">{{ bewe?.vermerk }}</div>
          </div>
        </div>
        <span class="mt-1 font-semibold">{{ bewelf?.bezeichnung1 }}</span>
        <div class="text-sm color-light mt-1" *ngIf="bewelf?.bezeichnung2">{{ bewelf?.bezeichnung2 }}</div>
        <div class="text-sm color-light mt-1" *ngIf="bewelf?.bezeichnung3">{{ bewelf?.bezeichnung3 }}</div>
        <div *ngIf="bewelf?.zeilentext"
             [ngClass]="(bewelf?.bezeichnung1?.length ?? 0) < 1 && (bewelf?.bezeichnung2?.length ?? 0) < 1 && !bewelf?.artikelnummer ? 'mt-1 font-semibold' : 'color-light mt-1'">
          {{ bewelf?.zeilentext }}
        </div>
      </div>
      <div class="flex justify-end items-end min-w-[4rem]">
        <div class="c row end text-base font-semibold">
          {{ bewe.menge ?? 0 }} {{ bewe.artehBean?.arteh }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isEmballage(bewe)">
    <div class="c row space-between no-wrap center p-1 border-box gap-6">
      <div class="c column">
        <div class="flex text-sm color-light mb-1">
          {{ bewe.emballageBean.emballage }}
          <i class="fa-regular fa-images info-icon" *ngIf="(bewe.files?.length ?? 0) > 0"></i>
          <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
            <i class="fa-regular fa-note-sticky info-icon" *ngIf="(bewe?.vermerk || '').length > 0"></i>
            <div class="text-ellipsis overflow-hidden opacity-70">{{ bewe?.vermerk }}</div>
          </div>
        </div>
        <span class="mt-1 font-semibold">{{ bewe.emballageBean.bezeichnung  }}</span>
      </div>
      <div class="flex justify-end items-end min-w-[4rem]">
        <div class="c row end text-base font-semibold">
          {{ bewe.menge ?? 0 }} EH
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isVermerk(bewe)">
    <div class="c row space-between no-wrap center p-1 border-box">
      <div class="c column">
        <span class="mt-1 font-semibold">{{ bewe.vermerk  }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isSearchItem(bewe)">
    <div class="c row space-between no-wrap center p-1 border-box gap-6">
      <div class="c column w-full">
        <div class="g custom-grid" [ngClass]="(bewelf?.bezeichnung1?.length || 0) < 1 && (bewelf?.bezeichnung2?.length || 0) < 1 && !bewelf?.menge ? 'mt-1 font-semibold' : 'text-sm color-light mb-1'">
          <div>
            <span class="text-sm color-light">{{ bewelf?.artikelnummer }}</span>
            <i class="fa-regular fa-images info-icon" *ngIf="(bewe?.files || []).length > 0"></i>
          </div>
          <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
            <i class="fa-regular fa-note-sticky info-icon" *ngIf="(bewe?.vermerk || '').length > 0"></i>
            <div class="text-ellipsis overflow-hidden opacity-70">{{ bewe?.vermerk }}</div>
          </div>
        </div>
        <span class="mt-1 font-semibold">{{ bewelf?.bezeichnung1 }}</span>
        <div class="text-sm color-light mt-1" *ngIf="bewelf?.bezeichnung2">{{ bewelf?.bezeichnung2 }}</div>
        <div *ngIf="bewelf?.zeilentext"
             [ngClass]="(bewelf?.bezeichnung1?.length ?? 0) < 1 && (bewelf?.bezeichnung2?.length ?? 0) < 1 && !bewelf?.artikelnummer ? 'mt-1 font-semibold' : 'color-light mt-1'">
          {{ bewelf?.zeilentext }}
        </div>
      </div>
      <div class="flex justify-end items-end min-w-[4rem]">
        <div class="c row end text-base font-semibold">
          {{ bewe.menge ?? 0 }} {{ bewe.artehBean?.arteh }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isScanItem(bewe)">
    <div class="c row space-between no-wrap center p-1 border-box gap-6">
      <div class="c column w-full">
        <div class="g custom-grid" [ngClass]="(bewelf?.bezeichnung1?.length || 0) < 1 && (bewelf?.bezeichnung2?.length || 0) < 1 && !bewelf?.menge ? 'mt-1 font-semibold' : 'text-sm color-light mb-1'">
          <div>
            <span class="text-sm color-light">{{ bewe.itemScanBean.artikel?.nummer }}</span>
            <i class="fa-regular fa-images info-icon" *ngIf="(bewe?.files || []).length > 0"></i>
          </div>
          <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
            <i class="fa-regular fa-note-sticky info-icon" *ngIf="(bewe?.vermerk || '').length > 0"></i>
            <div class="text-ellipsis overflow-hidden opacity-70">{{ bewe?.vermerk }}</div>
          </div>
        </div>
        <span class="mt-1 font-semibold">{{ bewe.itemScanBean.artikel?.artbez1 }}</span>
        <div class="text-sm color-light mt-1" *ngIf="bewe.itemScanBean.artikel?.artbez2">{{  bewe.itemScanBean.artikel?.artbez2 }}</div>
        <div class="color-light mt-1" *ngIf="bewe.itemScanBean.artikel?.artbez3">
          {{  bewe.itemScanBean.artikel?.artbez3 }}
        </div>
      </div>
      <div class="flex justify-end items-end min-w-[4rem]">
        <div class="c row end text-base font-semibold">
          {{ bewe.itemScanBean?.menge ?? 0 }} {{ bewe.itemScanBean?.einheit?.arteh }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
