<h2 class="text-xl font-semibold mt-3 mb-2 pr-3 pl-3 pt-2 mb-2">
    <ng-container *ngIf="isItem; else noItemTemplate">
        {{ '#DELIVERY_NOTE.DIALOGS.COMMENT.TITLE_ADD_ITEM' | translate }}
    </ng-container>
    <ng-template #noItemTemplate>
        <ng-container *ngIf="(vermerk && (vermerk?.length ?? 0) > 0); else defaultTitle">{{ '#DELIVERY_NOTE.DIALOGS.COMMENT.TITLE_EDIT' | translate }}</ng-container>
        <ng-template #defaultTitle>{{ '#DELIVERY_NOTE.DIALOGS.COMMENT.TITLE_ADD' | translate }}</ng-template>
    </ng-template>

</h2>

<div class="c row center pr-3 pl-3 pb-2 border-box">
    <soft-multiline-input #multilineInput [lines]="9" (valueChange)="inputChanged($event ?? '')" [value]="vermerk"></soft-multiline-input>
</div>

<div class="c row border-box no-wrap mt-2 size-aware-padding">
    <button class="soft-button secondary custom-size" (click)="close(null)">
        {{ '#DELIVERY_NOTE.DIALOGS.COMMENT.CANCEL' | translate }}
    </button>
    <button class="soft-button primary custom-size"
            (click)="closeDialog()">
        {{ '#DELIVERY_NOTE.DIALOGS.COMMENT.SAVE' | translate }}
    </button>
</div>
