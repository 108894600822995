<div class="segmented-control mt-3" *ngIf="segments && selected">
    <div class="segmented-control-active-indicator" [style]="activeIndicatorStyle">&nbsp;</div>
    <div class="segmented-control-option" *ngFor="let segement of segments"
         [class.segment-active]="segement?.value === selected"
         (click)="onChangeSegment(segement)">
        <div class="text-wrapper">
            <span>{{ segement?.label | translate }}</span>
            <ng-container *ngIf="segement?.hasEntries">
                <!-- <span class="text-danger-700">*</span> -->
                <!-- <div class="dot-container">
                    <i class="fa-regular fa-caret-down text-2xl text-tertiary-600"></i>
                </div> -->
             </ng-container>
        </div>
    </div>
</div>
