<label [for]="labelName" class="soft-radio">
  <ng-container *ngIf="labelPosition() === 'before'"
    ><ng-content></ng-content></ng-container>
  <span
    class="soft-radio-box"
    [class.checked]="checked()"
    [class.disabled]="input.disabled"
    [class.focus]="hasFocus.hasFocus"
  ></span>
  @if (checked()) {
    <span class="soft-radio-bullet"></span>
  }
  <input
    [id]="labelName"
    type="radio"
    class="soft-radio-input"
    [checked]="checked()"
    (change)="setChecked()"
    [disabled]="radioGroup?.readonly() || disabled()"
    softHasFocus
    #hasFocus="softHasFocus"
    #input
  />
  <ng-container *ngIf="labelPosition() === 'after'"><ng-content></ng-content
  ></ng-container>
</label>
