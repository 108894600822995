import { ChangeDetectionStrategy, Component, effect, inject, input, OnInit, output, signal, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TabComponent,
  TabGroupComponent,
  UiCoreDirectivesModule,
  UiCoreModule,
  WithBreakpoints
} from '@softline/ui-core';
import { MenuItem, Scan, ScanButtonComponent, WithActivatedRoute, WithMenuItems } from '@softline/application';
import { SearchStrategy } from './search.strategy';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { SearchResultComponent, SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW } from './abstraction/search-result.component';
import { Router } from '@angular/router';
import { PageComponent } from '../page/page.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';
import { isDefined } from '@softline/core';
import { PageTabComponent } from '../page/page-tab/page-tab.component';

@Component({
  selector: 'soft-search-page',
  standalone: true,
  imports: [CommonModule, UiCoreDirectivesModule, ScanButtonComponent, TabComponent, TabGroupComponent, UiCoreModule, PageComponent, PageHeaderComponent, RemoteDataContainerComponent, RemoteDataContainerComponent, PageTabComponent],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPageComponent<T> extends
  WithBreakpoints(
    WithActivatedRoute()
) implements OnInit {

  private readonly router = inject(Router);

  readonly searchInput$ = new BehaviorSubject<string | null>(null);

  readonly debouncedSearchInput = toSignal(
    this.searchInput$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ), { initialValue: null }
  );

  canScan = input(true);

  readonly select = output<T>();
  readonly favoriteChange = output<T>();
  readonly scan = output<Scan>();

  readonly strategy = inject(SearchStrategy);
  readonly overrideResultComponent = inject(SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW, { optional: true });

  get resultComponent(): Type<SearchResultComponent<T>> {
    return this.overrideResultComponent ?? this.strategy.resultViewType;
  }

  private readonly _onSearchInputChangeEffect = effect(async () => {
    const searchInput = this.debouncedSearchInput();
    this.strategy.searchInputChange(searchInput ?? null);
    await this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: !searchInput ? null : {
        searchTerm: searchInput
      },
      preserveFragment: true
    });
  }, { allowSignalWrites: true });

  constructor() {
    super();
  }

  onFavoriteChange(value: T): void {
    this.strategy.favoriteChange(value);
    this.favoriteChange.emit(value);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    const queryParamsSearchTerm = this.routeQueryParams()['searchTerm'];
    if (queryParamsSearchTerm) {
      this.searchInput$.next(queryParamsSearchTerm);
    }
    const fragment = this.routeFragment();
    if(!isDefined(fragment))
      await this.router.navigate([], {fragment: 'search'});
    try {
      await this.strategy.loadFavorites();
      const lu = await this.strategy.loadLastUsed();
    }
    catch (e) {
      console.log(e);
    }
  }

  onSelect(value: T): void {
    this.strategy.addToLastUsed(value);
    this.select.emit(value);
  }
}
