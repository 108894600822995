// Store Features
import {InjectionToken} from '@angular/core';

export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK = 'wws/umsatzstatistik';
export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_EBENE = 'wws/umsatzstatistik/ebenen';
export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_BELEGART = 'wws/umsatzstatistik/belegart';
export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_AUFGLIEDERUNG = 'wws/umsatzstatistik/aufgliederung';
export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG = 'wws/umsatzstatistik/vkforg';
export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_UMSATZART = 'wws/umsatzstatistik/umsatzart';

export const SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK = 'wws/umsatzstatistik/fok';

// Permission
export const SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK = 'UMSATZSTATISTIK';
export const SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET = 'UMSATZSTATISTIK.WIDGET';
export const SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK_WIDGET_FULL_WIDTH = 'UMSATZSTATISTIK.WIDGET_FULL_WIDTH';

export const SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK_LIST_SERVICE_URL = new InjectionToken<string>('SOFTLINE_CONFIG_WWS_UMSATZSTATISTIK_LIST_SERVICE_URL')
