<div class="flex justify-between items-center mb-2 mt-1 w-full">
  <span class="soft-overline mt-1" style="margin-bottom: 0 !important;">{{  (headerText || "#DELIVERY_NOTE.HEADERS.CLIENT") | translate }}</span>
  <a class="soft-link text-sm"  target="_blank" [attr.href]="generateMapsLink(address)" *ngIf="address">Navigation starten</a>
</div>
<div class="p-4 flex flex-1 flex-col w-full border border-solid border-gray-300 rounded h-full">
  <div>
    <strong class="text-base font-semibold">{{ address?.vorname }} {{ address?.nachname }} </strong>
  </div>
  <div class="c column space-y-1 mt-1">
    <small *ngIf="address?.nummer">
      {{ address?.nummer }} <ng-container *ngIf="address?.subnummer"> / {{ address?.subnummer }}</ng-container>
    </small>
    <small *ngIf="address?.kurzbez && ( address?.kurzbez?.length ?? 0 ) > 0">
      {{ address?.kurzbez }}
    </small>
    <small *ngIf="address?.strasse && ( address?.strasse?.length ?? 0 ) > 0">
      {{ address?.strasse }}
    </small>
    <small *ngIf="(address?.plz && ( address?.plz?.length ?? 0 ) > 0) || (address?.ort && ( address?.ort?.length ?? 0 ) > 0) ">
      {{ address?.plz }} {{ address?.ort }}
    </small>
    <small *ngIf="address?.telefon">
      <a class="soft-link" href="tel:{{ address?.telefon }}">{{ address?.telefon }}</a>
    </small>
  </div>
</div>

<!-- <div class="text-small color-light mb-5 mt-1 pt-1">
  Länge: ca. 61 km, Fahrtzeit: ca. 50 min
</div> -->
