import { MenuItem } from '@softline/application';
import { ESL_FLASH_COMMAND_NAME } from '../commands/esl-flash.command';

export function eslFlashMenuItem(config: {
  outlet: string;
  title?: string;
  class?: string;
}): MenuItem {
  return {
    type: 'command',
    icon: 'fa-regular fa-lightbulb-exclamation-on',
    name: ESL_FLASH_COMMAND_NAME,
    title: config?.title ?? 'ESL Blinken',
    class: config?.class,
    outlet: config.outlet,
  };
}
