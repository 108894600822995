import { Inject, Injectable } from '@angular/core';
import {
  Change,
  ConnectionHttpService,
  ConnectionResourceLocation,
  isNullOrEmpty,
  PatchChange,
  SOFTLINE_SERVICE_HTTP,
} from '@softline/core';
import { lastValueFrom, Observable } from 'rxjs';
import { DeliveryNote } from '../types/delivery-note';
import {
  SOFTLINE_API_DELIVERY_NOTE_BY_ID,
  SOFTLINE_API_DELIVERY_NOTE_CUSTOMER_NOTES, SOFTLINE_API_DELIVERY_NOTE_EXZESS,
  SOFTLINE_API_DELIVERY_NOTE_FINISH, SOFTLINE_API_DELIVERY_NOTE_GEOLOCATION, SOFTLINE_API_DELIVERY_NOTE_GET_FOR_SCAN,
  SOFTLINE_API_DELIVERY_NOTE_SCAN, SOFTLINE_API_DELIVERY_NOTE_SCAN_ALL,
  SOFTLINE_API_DELIVERY_NOTE_SIGN,
  SOFTLINE_API_DELIVERY_NOTE_STORNO, SOFTLINE_API_DELIVERY_NOTE_STORNO_ARTIKEL,
  SOFTLINE_API_DELIVERY_NOTES,
} from '../delivery-note.api';
import { ArchiveService, LabelType } from '@softline/application';
import { map } from 'rxjs/operators';
import { EmballageBewe, ItemScanBewe, ReturnNote, ReturnNoteBewe, SearchStornoBewe, StornoBewe, VermerkBewe } from '../types/return-note';
import { ExzessResult } from '../types/exzess-result';
import {ArtikelStorno} from '../types/artikel-storno';

@Injectable({ providedIn: 'root' })
export class DeliveryNoteService {

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService,
    private archiveService: ArchiveService
  ) {}

  async save(deliveryNote: DeliveryNote, change: Change<DeliveryNote>): Promise<DeliveryNote> {
    if (change.action !== 'patch')
      throw new Error('[DeliveryNoteService] Can not save delivery note');

    if (change.changes.signatureBlob) {
      await lastValueFrom(this.saveSignature(deliveryNote.id, [change.changes.signatureBlob], change.changes.signatureTime ?? new Date().toISOString()));
    }
    if (change.changes.vermerk || change.changes.bewelfs) {
      deliveryNote = await lastValueFrom(
        this.service.patch({path: `${SOFTLINE_API_DELIVERY_NOTES}/${deliveryNote.id}`}, {
          vermerk: change.changes.vermerk ?? '',
          bewelfs: change.changes.bewelfs?.map(o => ({id: o.id, menge: o.menge, vermerk: o.vermerk}))
        })
      );
    }
    if (deliveryNote.archiveKey && change.changes.files && change.changes.files.length > 0) {
      for (const file of change.changes.files)
        await lastValueFrom(this.archiveService.upload({archiveKey: deliveryNote.archiveKey, files: [file]}));
    }

    if (change.changes.signatureBlob) {
      deliveryNote.signatureBlob = change.changes.signatureBlob;
      deliveryNote.signatureTime = change.changes.signatureTime;
    }
    return deliveryNote;
  }

  async complete(
    deliveryNote: DeliveryNote,
    change: PatchChange<DeliveryNote> | undefined,
    returnNote: ReturnNote | undefined,
    data: { kilometerstand: number; mail: string | undefined | null }
  ): Promise<DeliveryNote> {
    let stornoResult;

    if (change && !isNullOrEmpty(change?.changes))
      await this.save(deliveryNote, change);

    if (returnNote &&
      (returnNote?.vermerk
        || (returnNote?.bewes.length > 0 && returnNote?.bewes?.filter(o => this.isNonEmptyBewe(o)))
      )
    )
      stornoResult = await this.saveReturnNote(deliveryNote?.id, returnNote);

    return await lastValueFrom(
      this.service.create(
        {
          path: SOFTLINE_API_DELIVERY_NOTE_FINISH,
          pathParams: { id: deliveryNote?.id }
        },
        stornoResult
          ? { ...data, stornoResult }
          : data
      )
    );
  }

  private isNonEmptyBewe(bewe: ReturnNoteBewe): boolean {
    if (bewe.typ === 'vermerk') {
      return !!bewe?.vermerk && bewe?.vermerk?.trim()?.length > 0;
    } else if (bewe?.typ === 'itemScan') {
      return (bewe.itemScanBean?.menge || 0) > 0;
    } else {
      return (bewe?.menge || 0) > 0;
    }
  }

  exzess(idlf: number): Observable<ExzessResult> {
    const location: ConnectionResourceLocation = { path: SOFTLINE_API_DELIVERY_NOTE_EXZESS };
    return this.service.create(location, { idlf });
  }

  clearExzess(idlf: number): Observable<undefined> {
    const location: ConnectionResourceLocation = { path: SOFTLINE_API_DELIVERY_NOTE_EXZESS + `/${idlf}` };
    return this.service.delete(location);
  }

  getIdForScan(labelType: LabelType | null | undefined, code: string): Observable<any> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_DELIVERY_NOTE_SCAN_ALL,
      pathParams: { type: Array.isArray(labelType) ? labelType[0] : labelType, code }
    };
    return this.service.get(location);
  }

  saveCoordinates(id: number, coordinates: { gpslaenge: number; gpsbreite: number }): Observable<DeliveryNote> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_DELIVERY_NOTE_GEOLOCATION,
      pathParams: { id }
    };
    return this.service.create(location, coordinates);
  }

  // INFO: Es wird automatisch der Fahrer umgesetzt, damit der Lieferschein in der Liste erscheint.
  getLieferschein(belegBean: object): Observable<DeliveryNote> {
    const location: ConnectionResourceLocation = { path: SOFTLINE_API_DELIVERY_NOTE_GET_FOR_SCAN };
    return this.service.create(location, belegBean);
  }

  // Liefert die Lieferscheine für den Kunden des lieferscheins (id)
  // Für die erweiterte Retour-Funktion notwendig
  getCustomerNotes(id: number, query: string): Observable<DeliveryNote[]> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_DELIVERY_NOTE_CUSTOMER_NOTES,
      pathParams: { id },
      queryParams: { suchbegriff: query }
    };

    return this.service.get(location);
  }

  // Liefert die Artikel die bereits einmal zum Kunden ausgeliefert wurden (von alten Lieferscheinen)
  getOffeneArtikel(id: number, query: string): Observable<ArtikelStorno[]> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_DELIVERY_NOTE_STORNO_ARTIKEL,
      pathParams: { id },
      queryParams: { suchbegriff: query }
    };

    return this.service.get(location);
  }

  private saveSignature(id: number, files: File[], time: string): Observable<any> {
    return this.service.upload(
      { path: SOFTLINE_API_DELIVERY_NOTE_SIGN, pathParams: { id } },
      { uhrzeit: time, files, filename: 'signature.png' }
    ).pipe(map(() => ({ result: '' })));
  }

  private async saveReturnNote(idlf: number, returnNote: ReturnNote): Promise<any> {
    if (!idlf)
      return undefined;

    const savableReturnNote = this.convertReturnNote(returnNote);
    const result = await lastValueFrom(this.service.create<any, any>({path: SOFTLINE_API_DELIVERY_NOTE_STORNO, pathParams: {id: idlf}}, savableReturnNote));

    for (const bewe of (returnNote?.bewes ?? [])) {
      let resultBewe: any;
      switch (bewe.typ) {
        case 'storno':
          resultBewe = result?.stornoResultBewes?.find((o: any) => o?.idbewelf === bewe?.idbewelf);
          break;
        case 'search':
          resultBewe = result?.stornoResultBewes?.find((o: any) => o?.idbewelf === bewe?.bewelf?.id);
          break;
        case 'emballage':
          resultBewe = result?.stornoResultEmballagen?.find((o: any) => o?.idemballage === bewe?.emballageBean?.id);
          break;
        case 'vermerk':
          resultBewe = result?.stornoResultZeilentexte?.find((o: any) => o?.lfdnr === (bewe?.lfdnr || 0));
          break;
        case 'itemScan':
          resultBewe = result?.stornoResultArtikel?.find((o: any) => o?.idartstamm === (bewe?.itemScanBean?.artikel?.id || 0));
          break;
        default:
          continue;
      }

      if (!resultBewe)
        continue;

      const archiveKey = resultBewe?.archiveKey;
      const idbewe = resultBewe?.idbewe;
      await this.saveReturnNoteBewe(idbewe, archiveKey, bewe);
    }

    return result;
  }

  private async saveReturnNoteBewe(idbewe: number, archiveKey: string, bewe: ReturnNoteBewe): Promise<void> {
    if (!idbewe || !archiveKey)
      return;
    for (const file of (bewe?.files ?? []))
      await lastValueFrom(this.archiveService.upload({archiveKey, files: [file], fields: { idbewe }}));
  }

  private convertReturnNote(returnNote: ReturnNote): any {
    const stornoBewes = returnNote.bewes
      .filter(o => o?.typ === 'storno')
      .map(o => o as StornoBewe)
      .map(o => ({
        idbewelf: o?.idbewelf,
        menge: o?.menge,
        artehBean: o?.artehBean,
        vermerk: o?.vermerk
      }));
    const stornoArtikel = returnNote.bewes
      .filter(o => o?.typ === 'search')
      .map(o => o as SearchStornoBewe)
      .map(o => ({
        idartstamm: o?.bewelf?.idartstamm,
        menge: o?.menge,
        artehBean: o?.artehBean,
        vermerk: o?.vermerk
      }))

    return {
      stornoBewes,
      emballagen: returnNote.bewes
        .filter(o => o?.typ === 'emballage')
        .map(o => o as EmballageBewe)
        .map(o => ({
          emballageBean: o?.emballageBean,
          vermerk: o?.vermerk,
          menge: o?.menge
        })),
      zeilentexte: returnNote.bewes
        .filter(o => o?.typ === 'vermerk')
        .map(o => o as VermerkBewe)
        .map(o => ({
          lfdnr: o?.lfdnr,
          text: o?.vermerk
        })),
      stornoArtikel: stornoArtikel, /*returnNote.bewes
        .filter(o => o?.typ === 'itemScan')
        .map(o => ({
          vermerk: o?.vermerk,
          itemScanBean: (o as ItemScanBewe)?.itemScanBean
        })),*/
      email: returnNote?.email ?? false,
      vermerk: returnNote?.vermerk ?? null
    };
  }
}
