import { inject, Injectable, signal } from '@angular/core';
import { FrontendUmsatzstatistikQuery } from '../types/frontend-umsatzstatistik.query';
import { Dictionary, isDefinedNotEmpty, Store } from '@softline/core';
import { ZusatzfilterControlDefinition } from '../types/zusatzfilter-control-definition';
import { SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK } from '@softapps/wws/umsatzstatistik';
import { UmsatzstatistikFieldOkStore } from '../store/fok.store';

@Injectable()
export class QueryService {
  readonly #store = inject(Store);

  readonly #query = signal<FrontendUmsatzstatistikQuery | null>(null);

  readonly query = this.#query.asReadonly();

  async set(query: FrontendUmsatzstatistikQuery): Promise<void> {
    this.#query.set(query);
  }

  clear() {
    this.#query.set(null);
  }

  async createZusatzfilter(
    definitions: ZusatzfilterControlDefinition[]
  ): Promise<Dictionary<ZusatzfilterControlDefinition | null>> {
    const dict = {};

    const query = this.#query();

    for (const definition of definitions) {
      const value = query?.[definition.formControlName];

      if (!isDefinedNotEmpty(value)) continue;

      dict[definition.formControlName] = await this.createControlDefinition(
        definition,
        value
      );
    }

    return dict;
  }

  private async createControlDefinition<T>(
    definition: ZusatzfilterControlDefinition,
    queryValue: T
  ): Promise<ZusatzfilterControlDefinition> {
    try {
      let value = {}
      if(definition.formControlName === "unteruntergruppe" || definition.formControlName === "untergruppe"){
        const id = definition.options?.convert?.(queryValue) ?? 0;

        value = await this.#store.dispatch(
          SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK,
          UmsatzstatistikFieldOkStore.actions.loadResult,
          {
            id: + +id ? +id : 0,
            name: definition.options?.name ?? '',
            componentId: QueryService.name,
            params: {
              filter: definition.options?.convert?.(queryValue) ?? queryValue,
              multiValued: false,
              parameters: {},
            },
          }
        );

      }else{
        value = await this.#store.dispatch(
          SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_FOK,
          UmsatzstatistikFieldOkStore.actions.validate,
          {
            name: definition.options?.name ?? '',
            componentId: QueryService.name,
            params: {
              filter: definition.options?.convert?.(queryValue) ?? queryValue,
              multiValued: false,
              parameters: {},
            },
          }
        );
      }


      if (!value || (Array.isArray(value) && value?.length > 1))
        return definition;

      return {
        ...definition,
        initialValue: Array.isArray(value) ? value[0] : (value as any),
      };
    } catch (e) {
      console.error(e);
      return definition;
    }
  }
}
