import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  BackNavigable, BackNavigationService,
  Command, CommandStore,
  showRequestErrors,
  ItemScan,
  ItemScanStore,
  ScannerStore, SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_ITEM_SCAN,
  SOFTLINE_FEATURE_ROUTE,
  SOFTLINE_FEATURE_SCANNER, SOFTLINE_FEATURE_SHELL, SOFTLINE_FEATURE_TITLE, TitleStore,
} from '@softline/application';
import {ActivatedRoute, Router} from '@angular/router';
import { ItemScanBewe, ReturnNote } from '../../types/return-note';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';
import { SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE } from '../../delivery-note.shared';
import { ReturnNoteStore } from '../../stores/return-note.store';
import { DeliveryNote } from '../../types/delivery-note';
import { Dictionary, RequestError, SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import { DeliveryNotesStore } from '../../stores';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {CommonModule} from '@angular/common';
import {ScanItemComponent} from '../../components/scan-item/scan-item.component';
import { ShellStore } from '@softapps/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-return-note-scan-items',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ScanItemComponent],
  templateUrl: './return-note-scan-items.component.html',
  styleUrls: ['./return-note-scan-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnNoteScanItemsComponent implements OnInit, OnDestroy, BackNavigable {

  private id$ = this.route.paramMap.pipe(map(o => o.get('id')));
  private scannedItems$ = new BehaviorSubject<Dictionary<ItemScanBewe>>({});

  readonly items$: Observable<ItemScanBewe[]> = this.scannedItems$.pipe(
    map(o => Object.values(o).filter(o => !!o).map(o => o as ItemScanBewe))
  );

  readonly scanInProgress$ = new BehaviorSubject(false);

  readonly leftOffsetInPx$ = this.store.observe(SOFTLINE_FEATURE_SHELL, ShellStore.getters.sideMenuMinified).pipe(
    combineLatestWith(this.store.observe(SOFTLINE_FEATURE_SHELL, ShellStore.getters.sideMenuOpen)),
    map(([minified, open]) => {
      if (open && minified)
        return '70px';
      else if (open && !minified)
        return '250px';
      else
        return '0px';
    })
  );

  readonly rightOffsetInPx$ = this.store.observe(SOFTLINE_FEATURE_SHELL, ShellStore.getters.actionMenuMinified).pipe(
    combineLatestWith(this.store.observe(SOFTLINE_FEATURE_SHELL, ShellStore.getters.actionMenuOpen)),
    map(([minified, open]) => {
      if (open && minified)
        return '70px';
      else if (open && !minified)
        return '250px';
      else
        return '0px';
    })
  );

  readonly returnNote$: Observable<ReturnNote> = this.id$.pipe(
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_RETURN_NOTE,
        ReturnNoteStore.getters.preparedEntity,
        id
      )
    )
  );

  readonly deliveryNote$: Observable<DeliveryNote> = this.returnNote$.pipe(
    switchMap(returnNote =>
      this.store.observe(
        SOFTLINE_FEATURE_DELIVERY_NOTE,
        DeliveryNotesStore.getters.collection.preparedEntity,
        returnNote.idlf
      )
    )
  );

  readonly trackById = (index: number, item: ItemScanBewe) => item.id;

  constructor(
    protected router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private backNavigationService: BackNavigationService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) { }

  ngOnInit(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, 'Retour-Artikel scannen');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: ReturnNoteScanItemsComponent,
      commands: this.createCommands()
    });
    this.backNavigationService.set(this);
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, ReturnNoteScanItemsComponent);
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    const deliveryNoteId = this.route.snapshot.paramMap.get('id');
    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.entity, deliveryNoteId);
    await this.router.navigate(['lieferscheine', 'return-note', returnNote?.id]);
  }

  protected createCommands(): Command[] {
    return [
      {
        name: 'Scannen',
        icon: 'fa-regular fa-barcode-scan',
        class: 'menu action-menu action-menu-top',
        execute: async () => this.scan()
      },
    ];
  }

  itemRemoved(item: ItemScanBewe): void {
    const items = this.scannedItems$.getValue();
    items[item.id] = undefined;
    this.scannedItems$.next(items);
  }

  itemChanged(item: ItemScanBewe): void {
    const items = this.scannedItems$.getValue();
    items[item.id] = item;
    this.scannedItems$.next(items);
  }

  async scan(): Promise<void> {
    try {
      const scan = await this.store.dispatch(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.actions.scan,
        { labelType: ['ean128', 'ean13', 'code128', 'code128'] }
      );

      this.scanInProgress$.next(true);

      const item = await this.store.dispatch(
        SOFTLINE_FEATURE_ITEM_SCAN,
        ItemScanStore.actions.load,
        { ...scan, timestamp: new Date().toISOString() }
      );

      if (!item) {
        await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
          title: 'Es wurde kein Artikel zu dem Barcode gefunden!'
        });
        this.scanInProgress$.next(false);
        return;
      }


      this.addItemIfNotExists(item);
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.scanInProgress$.next(false);
    }
  }

  private addItemIfNotExists(item: ItemScan): void {
    const items = Object.values(this.scannedItems$.getValue());

    if (items.find(o => o?.itemScanBean?.artikel?.id === item.artikel.id))
      return;

    const itemScanBewe: ItemScanBewe = {
      id: this.uuid(),
      typ: 'itemScan',
      itemScanBean: item,
      files: [],
      vermerk: ''
    };

    const current = this.scannedItems$.getValue();
    current[itemScanBewe.id] = itemScanBewe;
    this.scannedItems$.next(current);
  }

  async onSave(returnNoteId: string): Promise<void> {
    for (const bewe of Object.values(this.scannedItems$.getValue())) {
      if (bewe) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_RETURN_NOTE,
          ReturnNoteStore.actions.upsertBewe,
          { id: returnNoteId, bewe }
        );
      }
    }

    await this.navigateBack();
  }
}
