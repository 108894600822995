import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Modal, UiCoreModule } from '@softline/ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-confirm-menge-above-max-menge-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './confirm-menge-above-max-menge-dialog.component.html',
  styleUrls: ['./confirm-menge-above-max-menge-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMengeAboveMaxMengeDialogComponent implements Modal<boolean> {

  close!: (result: boolean) => void;

  registerCloseHandler(handler: (result: boolean) => void) {
    this.close = handler;
  }
}
