import { EnvironmentProviders, Provider } from '@angular/core';
import { ResolveData, Route } from '@angular/router';

export interface SoftlineModuleRoutes {
  internal: Route[];
  internalResolve?: ResolveData;
  public: Route[];
}

export type SoftlineModuleConfig = [
  (Provider | EnvironmentProviders)[],
  SoftlineModuleRoutes
];
