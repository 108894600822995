import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'soft-chip-group',
  standalone: true,
  templateUrl: './chip-group.component.html',
  styleUrls: ['./chip-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
