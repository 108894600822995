<h2 class="text-xl font-semibold mt-3 mb-3 ml-3 mr-3 border-box">
    {{ (images?.length ?? 0) > 1 ? ('#DELIVERY_NOTE.DIALOGS.EDIT_IMAGE.TITLE_MULTIPLE' | translate) : ('#DELIVERY_NOTE.DIALOGS.EDIT_IMAGE.TITLE_SINGLE' | translate) }}
</h2>

<div class="c column max-height scrollable">
    <ng-container *ngIf="(images?.length ?? 0) > 0; else placeholderTemplate">
        <div class="c row space-between center-v no-wrap border-box pt-2 pb-3 separator-bottom" [class.separator-none]="last" *ngFor="let image of images; let i = index; let last = last">
            <div class="c column ml-3 border-box">
                <strong>{{ image?.name }}</strong>
                <span class="text-sm color-light">{{ image.size | filesize }}</span>
            </div>

            <div class="c row end center-v mr-3 border-box" style="width: auto !important; min-width: 5rem;">
                <i class="fa-regular fa-eye soft-link ml-3 pr-2" (click)="openImage(image, i)"></i>
                <i class="fa-regular fa-trash color-delete ml-2 pl-2 pointer" (click)="removeImage(image)"></i>
            </div>
        </div>
    </ng-container>

    <ng-template #placeholderTemplate>
        <div class="c row center">
            <span class="color-light mt-4 mb-4 pt-2 pb-2 border-box">{{ '#DELIVERY_NOTE.DIALOGS.EDIT_IMAGE.EMPTY_PLACEHOLDER' | translate }}</span>
        </div>
    </ng-template>
</div>
