import {Dictionary} from '@softline/core';
import {KzAusgabeAllInputCalculationData, KzAusgabeAllInputCalculationStrategy} from './input-calculation.strategy';

export class KzAusgabe1InputCalculationStrategy implements KzAusgabeAllInputCalculationStrategy  {
  calculate(data: KzAusgabeAllInputCalculationData): Dictionary<number | null> {
    const ausgabeEh1Quantity = Math.ceil(data.inputValue)
    const faktor = (data.ausgabeEh2.faktor / data.ausgabeEh1.faktor)
    const ausgabeEh2Quantity = Math.floor(ausgabeEh1Quantity / faktor)

    if (ausgabeEh2Quantity > 0) {
      const restInAusgabeEh1 = ausgabeEh2Quantity * data.ausgabeEh2.faktor
      data.dict[data.ausgabeEh2.id] = ausgabeEh2Quantity
      data.dict[data.ausgabeEh1.id] = ausgabeEh1Quantity - restInAusgabeEh1
    } else {
      data.dict[data.ausgabeEh1.id] = ausgabeEh1Quantity
    }
    return data.dict
  }
}
