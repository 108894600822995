import { requestErrorMessageFactory } from '@softline/application';
import { Injector } from '@angular/core';
import { MessageBarService, TranslationStore2 } from '@softline/ui-core';
import { interpolate, UnauthorizedError } from '@softline/core';
import { LogoutService } from '@softline/auth';

export function handleRequestError(error: unknown, injector: Injector, options?: {show?: boolean}): void {
  const message = requestErrorMessageFactory(error);

  const translator = injector.get(TranslationStore2);
  const subject = message.subject ? interpolate(translator.translate(message.subject)(), message.data as any) : null;
  const text = interpolate(translator.translate(message.message)(), message.data as any);

  switch (message.type) {
    case 'critical':
      console.error('Softapps Critical error:', subject ?? 'Critical error', '\n', text, '\n', error);
      throw error;
    case 'error':
      console.error('Softapps Error:', subject ?? 'Error', '\n', text, '\n\n', error);
      break;
    case 'warning':
      console.warn('Softapps Warning:', subject ?? 'Warning', '\n', text, '\n\n', error);
      break;
    case 'info':
      console.info('Softapps Info:', subject ?? 'Info', '\n', text, '\n\n', error);
      break;
    case 'success':
      console.debug('Softapps Success:', subject ?? 'Success', '\n', text, '\n\n', error);
      break;
    default:
      console.error('Softapps', subject ?? 'Unknown Error', message, '\n', text, '\n\n', error);
      throw error;
  }
  if(options?.show) {
    const messageBarService = injector.get(MessageBarService);
    switch (message.type as any) {
      case 'critical':
      case 'error':
        messageBarService.error(text, subject);
        break;
      case 'warning':
        messageBarService.warning(text, subject);
        break;
      case 'info':
        messageBarService.info(text, subject);
        break;
      case 'success':
        messageBarService.success(text, subject);
        break;
      default:
        messageBarService.error(text, subject);
        break;
    }
  }

  if (error instanceof UnauthorizedError) {
    const logoutService = injector.get(LogoutService);
    logoutService.logout();
  }
}
