import {KzAusgabe1CalculationData, KzAusgabe1Strategy} from './kz-ausgabe-1.strategy';
import {Dictionary} from '@softline/core';

export class KzAusgabeNInputCalculationStrategy implements KzAusgabe1Strategy {
  calculate(data: KzAusgabe1CalculationData): Dictionary<number | null> {
    const faktor = (data.ausgabeEh1.faktor / data.inputArteh.faktor)
    data.dict[data.ausgabeEh1.id] = Math.ceil(data.inputValue / faktor)
    return data.dict
  }
}
