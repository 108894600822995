import { wwsCoreProviders } from '@softapps/wws/core';
import {
  fibuCoreProviders,
  kundeninfoLeisteProviders,
} from '@softapps/fibu/core';
import {
  BelegDownloadStrategy,
  FremdbelegDownloadStrategy,
  gesamtabfrageProviders,
  gesamtabfrageRoutes,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
} from '@softapps/wws/gesamtabfrage';
import {
  dashboardProviders,
  dashboardRoutes,
  provideKundeninfoWidget,
} from '@softapps/allgemein/dashboard';
import { kreditlimitProviders } from '@softapps/wws/kreditlimit';
import { offenePostenProviders } from '@softapps/fibu/offene-posten';
import {
  artikelInfoProviders,
  artikelInfoRoutes,
} from '@softapps/wws/artikel-info';
import {
  artikelvorratProviders,
  artikelvorratRoutes,
} from '@softapps/mde/artikelvorrat';
import {
  warenuebernahmeProviders,
  warenuebernahmeRoutes,
} from '@softapps/wws/warenuebernahme';
import {
  bestellvorschlagProviders,
  bestellvorschlagRoutes,
} from '@softapps/mde/bestellvorschlag';
import {
  bestellerfassungProviders,
  bestellerfassungRoutes,
} from '@softapps/mde/bestellerfassung';
import {
  etikettendruckProviders,
  etikettendruckRoutes,
} from '@softapps/mde/etikettendruck';
import { inventurProviders, lagerInventurRoutes } from '@softapps/mde/inventur';
import { retourenProviders, retourenRoutes } from '@softapps/mde/retouren';
import {
  warenverprobungProviders,
  warenverprobungRoutes,
} from '@softapps/mde/warenverprobung';
import {
  statusabfrageProviders,
  statusabfrageRoutes,
} from '@softapps/pms/statusabfrage';
import {
  stempelerfassungProviders,
  stempelerfassungRoutes,
} from '@softapps/pms/stempelerfassung';
import {
  lieferscheinProviders,
  lieferscheinRoutes,
} from '@softapps/wws/lieferschein';
import {
  checklisteProviders,
  checklisteRoutes,
} from '@softapps/allgemein/checkliste';
import { freigabeProviders } from '@softapps/fibu/freigabe';
import {
  umsatzstatistikProviders,
  umsatzstatistikRoutes,
} from '@softapps/wws/umsatzstatistik';
import { haftruecklassProviders } from '@softapps/fibu/haftruecklass';
import {
  kundenabfrageProviders,
  kundenabfrageRoutes,
} from '@softapps/wws/kundenabfrage';
import { SoftlineModuleConfig } from '@configs/common';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HagebauPartnerSoftlineConfig {}

export function provideHagebauPartnerSoftlineModules(config?: HagebauPartnerSoftlineConfig): SoftlineModuleConfig{
  const providers = [
    //Wws
    ...wwsCoreProviders,
    ...fibuCoreProviders,
    ...kundeninfoLeisteProviders,
    ...provideKundeninfoWidget(),
    ...dashboardProviders,

    // Gesamtabfrage (Belege)
    ...gesamtabfrageProviders,

    // Artikel-Info
    ...artikelInfoProviders,

    // MDE Module
    ...artikelvorratProviders,
    ...warenuebernahmeProviders,
    ...bestellvorschlagProviders,
    ...bestellerfassungProviders,
    ...etikettendruckProviders,
    ...inventurProviders,
    ...retourenProviders,
    ...warenverprobungProviders,

    // PMS
    ...statusabfrageProviders,
    ...stempelerfassungProviders,

    ...lieferscheinProviders,
    ...checklisteProviders,

    ...freigabeProviders,
    ...kreditlimitProviders,
    ...umsatzstatistikProviders,
    ...haftruecklassProviders,
    ...offenePostenProviders,
    ...kundenabfrageProviders,

    {
      provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
      useValue: {
        geschaeftsfall: true,
        download: [
          {
            belegart: 'Rechnung',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Lieferschein',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Auftrag',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Offert',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Kommissionierschein',
            strategies: [BelegDownloadStrategy],
          },
        ],
      },
    },
  ];

  const routes = {
    public: [],
    internal: [
      ...gesamtabfrageRoutes,
      ...artikelInfoRoutes,
      ...bestellvorschlagRoutes,
      ...bestellerfassungRoutes,
      ...etikettendruckRoutes,
      ...lagerInventurRoutes,
      ...retourenRoutes,
      ...warenverprobungRoutes,

      ...artikelvorratRoutes,
      ...warenuebernahmeRoutes,

      ...stempelerfassungRoutes,
      ...statusabfrageRoutes,
      ...lieferscheinRoutes,
      ...umsatzstatistikRoutes,
      ...checklisteRoutes,

      ...dashboardRoutes,
      ...kundenabfrageRoutes,
    ],
  };

  return [providers, routes];
}
