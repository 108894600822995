<ng-container *ngIf="returnNote$ | async as returnNote">
    <ng-container *ngIf="items$ | async as items">
        <section class="sticky top-[-0.5rem] z-50 fill-content px-4" style="background-color: var(--shellContentBackground)">
            <h2 class="text-xl font-semibold mt-3">Artikel für Returnschein scannen</h2>
            <div class="mt-4 border-gray-200 border-solid border-b fill-content pb-1" *ngIf="items.length > 0">
                <span class="text-sm uppercase tracking-wide text-gray-400 font-semibold">Gescannte Artikel ({{ items?.length ?? 0 }})</span>
            </div>
        </section>

        <section class="z-0 pb-16">
            <div class="flex items-center justify-center min-h-[50vh]" *ngIf="(scanInProgress$ | async); else results">
                <soft-loading-spinner></soft-loading-spinner>
            </div>
            <ng-template #results>
                <ng-container *ngIf="(items?.length ?? 0) > 0">
                    <div class="" *ngFor="let item of items; trackBy: $any(trackById)">
                        <lib-scan-item
                            *ngIf="item"
                            [item]="item"
                            (itemRemoved)="itemRemoved($event)"
                            (itemChanged)="itemChanged($event)">
                        </lib-scan-item>
                    </div>
                </ng-container>
                <div *ngIf="(items?.length ?? 0) < 1" class="flex flex-col items-center justify-center min-h-[50vh] w-full text-center">
                    <span class="soft-text color-light">Noch keine Artikel gescannt</span>
                    <button class="soft-button primary mt-3" (click)="scan()">Artikel scannen</button>
                </div>
            </ng-template>
        </section>

        <section [ngStyle]="{ left: (leftOffsetInPx$ | async), right: (rightOffsetInPx$ | async) }"
                 class="fixed bottom-0 py-4 text-white font-semibold text-base bg-primary-500 disabled:bg-300 flex items-center text-center justify-center"
                 (click)="onSave(returnNote.id)">
            Übernehmen
        </section>
    </ng-container>
</ng-container>
