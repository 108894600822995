import { Component } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import { BeweLf } from '../../types/bewe-lf';
import {CommonModule} from '@angular/common';

export interface BottomSheetOption {
  id: string;
  label: string;
  icon?: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-bottom-sheet-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './bewe-option-dialog.component.html',
  styleUrls: ['./bewe-option-dialog.component.scss']
})
export class BeweOptionDialogComponent implements Modal<{ selected?: BottomSheetOption }> {

  options: BottomSheetOption[] = [];
  itemName?: string;
  customText?: string;

  close!: (result: { selected?: BottomSheetOption }) => void;

  constructor() { }

  registerCloseHandler(handler: (result: { selected?: BottomSheetOption }) => void) {
    this.close = handler;
  }
}
