import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ValidationMessage } from '@softline/core';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'soft-validation-container',
  standalone: true,
  templateUrl: './validation-container.component.html',
  styleUrls: ['./validation-container.component.scss'],
  imports: [ValidationMessageComponent, NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationContainerComponent implements OnInit {
  @Input() validation?: { messages?: ValidationMessage[] } | null;

  constructor() {}

  ngOnInit(): void {}
}
