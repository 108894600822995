import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../factories';
import { StoreFeature } from '../../store';
import { isDefined } from '../../../functions/is-defined.function';
import { SOFTLINE_SERVICE_UUID } from '../../../core.shared';

export interface State {
  keys: string[];
}

export const mutations = {
  add: createMutation<State, string>('add key'),
  clear: createMutation<State>('clear keys'),
};

export const getters = {
  keys: createGetter<State, string[]>('keys'),
};

export const actions = {
  addOrCreate: createAction<State, string, string>('create key'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    keys: [],
  },
  mutations: [
    mutate(mutations.add, ({ state, params, featureName }) => {
      if (state.keys.indexOf(params) > -1)
        throw new Error(
          `[KeyStore] ${featureName} - add action: There is already an key ${params}`
        );
      return { ...state, keys: [...state.keys, params] };
    }),
    mutate(mutations.clear, ({ state, params }) => {
      return { ...state, keys: [] };
    }),
  ],
  getters: [select(getters.keys, ({ state, params }) => state.keys)],
  actions: [
    on(actions.addOrCreate, ({ featureName, commit, injector, params }) => {
      const uuid = isDefined(params)
        ? params
        : injector.get(SOFTLINE_SERVICE_UUID)();
      commit(featureName, mutations.add, uuid);
      return uuid;
    }),
  ],
};
