import {CalculationData, CalculationStrategy} from '../calculation.strategy';
import {Dictionary} from '@softline/core';
import {KzAusgabe2Strategy} from './input-strategies/kz-ausgabe-2.strategy';
import {KzAusgabe2InputCalculationStrategy} from './input-strategies/kz-ausgabe-2-input.strategy';
import {KzAusgabeNInputCalculationStrategy} from './input-strategies/kz-ausgabe-n-input.strategy';

export class KzAusgabe2CalculationStrategy implements CalculationStrategy {

  private readonly strategies: Record<'2' | 'N', KzAusgabe2Strategy> = {
    '2': new KzAusgabe2InputCalculationStrategy(),
    'N': new KzAusgabeNInputCalculationStrategy()
  };

  calculate(data: CalculationData): Dictionary<number | null> {
    const dict = data.dictionary
    const ausgabeEh2 = data.artehs.find(o => o.kzausgabe === '2')

    if (!ausgabeEh2 || !data.inputArteh?.kzausgabe || !this.strategies[data.inputArteh.kzausgabe]) {
      return dict
    }

    return this.strategies[data.inputArteh.kzausgabe].calculate({
      ausgabeEh2,
      inputArteh: data.inputArteh,
      inputValue: data.inputValue,
      baseArteh: data.baseArteh,
      dict
    })
  }
}
