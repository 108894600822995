<h2 class="text-xl font-semibold ml-3 mr-3 mt-2">{{ '#DELIVERY_NOTE.DIALOGS.RETOUR_IMAGE.TITLE' | translate }}</h2>

<div class="c row mt-2 center">
    <soft-file-input [sources]="'photo'" #input></soft-file-input>
</div>

<div class="button-container border-box mt-4">
    <button class="soft-button secondary custom-size" (click)="close(null)">
        {{ '#DELIVERY_NOTE.DIALOGS.RETOUR_IMAGE.ABORT' | translate }}
    </button>
    <button class="soft-button primary custom-size" [disabled]="(input?.value?.length ?? 0) < 1" (click)="close(input?.value || [])">
        {{ '#DELIVERY_NOTE.DIALOGS.RETOUR_IMAGE.SAVE' | translate }}
    </button>
</div>
