import { CdkPortal, PortalModule } from "@angular/cdk/portal";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild, EventEmitter,
  forwardRef,
  Input,
  OnInit, Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Tab } from './tab';
import { TabTitleDirective } from './tab-title/tab-title.directive';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'soft-tab',
  standalone: true,
  imports: [CommonModule, PortalModule],
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  providers: [
    { provide: Tab, useExisting: forwardRef(() => TabComponent), multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent extends Tab implements OnInit {
  @Input() title?: string | null | undefined;
  @ContentChild(TabTitleDirective) templateTitle?: TabTitleDirective;
  @ViewChild(CdkPortal, { static: true }) content?: CdkPortal;

  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
