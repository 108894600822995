import { Component, computed, forwardRef, inject, input } from '@angular/core';
import { RadioGroup, RadioOption } from '../radio-option';
import { CommonModule } from '@angular/common';
import { HasFocusDirective } from '../../../../directives/has-focus.directive';

let uniqueIndex = 0;

@Component({
  selector: 'soft-radio-option',
  standalone: true,
  imports: [CommonModule, HasFocusDirective],
  templateUrl: './radio-option.component.html',
  styleUrls: ['./radio-option.component.scss'],
  providers: [
    {
      provide: RadioOption,
      useExisting: forwardRef(() => RadioOptionComponent),
      multi: true,
    },
  ]
})
export class RadioOptionComponent<T> extends RadioOption<T> {
  labelName = `soft-radio-option-${++uniqueIndex}`;

  radioGroup = inject<RadioGroup<T> | null>(RadioGroup, {optional: true});

  value = input<any>(null);
  labelPosition = input<'before' | 'after'>('after')
  disabled = input<boolean>(false);

  checked = computed(() => {
    if(!this.radioGroup)
      return false;
    return this.radioGroup.value()() === this.value();
  })

  constructor() {
    super();
  }

  setChecked(): void {
    if (!this.radioGroup || this.disabled() || this.radioGroup?.readonly())
      return;
    this.radioGroup.setValue(this.value());
  }
}
