import { Component } from '@angular/core';
import {Modal, ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from '@softline/ui-core';
import { Store } from '@softline/core';
import { ImageCarouselDialogComponent } from '../image-carousel-dialog/image-carousel-dialog.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-edit-image-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './edit-image-dialog.component.html',
  styleUrls: ['./edit-image-dialog.component.scss']
})
export class EditImageDialogComponent implements Modal<File[] | null> {

  images?: File[];
  callback?: (images: File[]) => void;

  close!: (result: File[] | null) => void;

  constructor(private store: Store) { }

  registerCloseHandler(handler: (result: (File[] | null)) => void) {
    this.close = handler;
  }

  async openImage(file: File, index: number): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      id: 'DELIVERY_NOTE_CAROUSEL',
      component: ImageCarouselDialogComponent,
      data: { images: this.images ?? [file], selectedIndex: index },
      priority: 1,
      dismiss: {
        backdrop: true,
        escape: true,
        button: true
      }
    });
  }

  async removeImage(file: File): Promise<void> {
    const answer = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, { question: 'Soll das Bild wirklich gelöscht werden?' });

    if (answer === 'YES') {
      this.images = this.images?.filter(o => o !== file);
      this.callback?.(this.images ?? []); // inform the caller by passing the current (new/updated) files back
    }
  }
}
