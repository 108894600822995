import { Component } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-image-carousel-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './image-carousel-dialog.component.html',
  styleUrls: ['./image-carousel-dialog.component.scss']
})
export class ImageCarouselDialogComponent implements Modal<any> {

  images: File[] = [];
  selectedIndex?: number;

  get hasNext(): boolean {
    return !!this.selectedIndex && ((this.images.length - 1) < this.selectedIndex ?? 0);
  }

  get hasPrevious(): boolean {
    return !!this.selectedIndex && this.selectedIndex > 0;
  }

  get selectedImageURL(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.images[this.selectedIndex ?? 0]));
  }

  close!: (result: any) => void;

  constructor(private sanitizer: DomSanitizer) { }

  registerCloseHandler(handler: (result: any) => void) {
    this.close = handler;
  }

  showNext(): void {

  }

  showPrevious(): void {

  }
}
