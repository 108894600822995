<div class="border border-solid w-full rounded-sm min-h-[1.25rem] h-auto"
     [ngClass]="[customClass ?? '', themeBorderClasses()]">
  <div [ngClass]="[themeClasses()]" class="relative min-h-[1.25rem] h-full">
    <div class="mix-blend-screen min-h-[1.25rem] h-full">
      <div class="bg-white rounded-sm min-h-[1.25rem] h-full" [ngClass]="[backgroundClass() ?? '']">
        <div class="bg-black min-h-[1.25rem] h-full transition-all duration-300" [style.width]="(_progress*100 | number:'1.0-0') + '%'"></div>
      </div>
      <div class="absolute top-0 left-0 w-full flex items-center justify-center tracking-wider text-white font-semibold mix-blend-difference h-full ">
          <span id="percent">
              @if (allowValuesOver100Percent()) {
                {{(displayValue()*100 | number:'1.0-0') + '%'}}
              } @else {
                {{(_progress*100 | number:'1.0-0') + '%'}}
              }
          </span>
      </div>
    </div>
  </div>
</div>
