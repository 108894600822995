import {
  Component,
  computed,
  effect,
  inject,
  Inject,
  signal,
  ViewChild,
} from '@angular/core';
import {
  MenuItem,
  ResponsiveMenuComponent,
  showRequestErrors,
  Template,
  WithCommands2,
  WithLoadTemplate,
  WithMenuItems,
  WithQueryHistory,
  WithSaveTemplate,
  WithStaticBackNavigation,
  WithTitle,
} from '@softline/application';
import { base64Encode, isDefinedNotEmpty } from '@softline/core';
import { SOFTLINE_DEFINITION_VOUCHER_QUERY } from '../../gesamtabfrage.api';
import {
  Definition,
  DefinitionStore,
  DynamicFormComponent,
  DynamicModule,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
} from '../../gesamtabfrage.shared';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { BelegStore } from '../../store/beleg.store';
import { ReactiveFormsModule } from '@angular/forms';
import {
  LoadTemplateDialogComponent, PageComponent, PageHeaderComponent,
  SaveTemplateDialogComponent
} from '@softapps/core';
import { WithBelegart } from '../../mixins/belegart.mixin';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  // tslint:disable-next-line:component-selector
  selector: 'soft-gesamtabfrage-query',
  templateUrl: './gesamtabfrage-query.component.html',
  styleUrls: ['./gesamtabfrage-query.component.scss'],
  imports: [
    CommonModule,
    UiCoreModule,
    DynamicModule,
    ReactiveFormsModule,
    ResponsiveMenuComponent,
    PageComponent,
    PageHeaderComponent
  ],
})
export class GesamtabfrageQueryComponent extends WithBelegart(
  WithLoadTemplate(
    {
      featureName: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      dialog: LoadTemplateDialogComponent,
    },
    WithSaveTemplate(
      {
        featureName: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
        dialog: SaveTemplateDialogComponent,
      },
      WithQueryHistory(
        { featureName: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG },
        WithStaticBackNavigation(
          '/gesamtabfrage',
          WithCommands2(WithMenuItems(WithTitle()))
        )
      )
    )
  )
) {
  router = inject(Router);

  value = this.store.signal(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.query.query
  );
  formValue = signal<Partial<object> | null>(null);

  definition = signal(undefined as Definition | undefined);

  hasFormValue = computed(() => isDefinedNotEmpty(this.formValue()));
  override title = signal('#GESAMTABFRAGE.TITLE');

  override commands = computed(() => [
    {
      name: 'gesamtabfrageQuery',
      execute: async () => this.form?.submit(),
    },
  ]);

  override menuItems = computed(
    () =>
      [
        {
          name: 'find',
          type: 'route',
          outlet: 'nav',
          title: '#GESAMTABFRAGE.MENU.FIND',
          icon: 'fa-regular fa-magnifying-glass',
          routerLink: ['/gesamtabfrage/', this.belegart()?.id, 'find'],
        },
        {
          name: 'gesamtabfrageQuery',
          type: 'command',
          outlet: 'responsive',
          class: 'soft-button accent',
          icon: 'fa-regular fa-magnifying-glass',
          title: '#GESAMTABFRAGE.PAGES.QUERY.SUBMIT',
        },
      ] as MenuItem[]
  );

  @ViewChild('form') form?: DynamicFormComponent<object>;

  belegartEffect = effect(
    async () => {
      const belegart = this.belegart();
      if (!belegart) return;
      try {
        let definition = await this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${belegart.id}` }
        );
        if (!definition) {
          definition = await this.store.dispatch(
            SOFTLINE_FEATURE_DEFINITIONS,
            DefinitionStore.actions.loadOnce,
            { name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}` }
          );
        }
        this.definition.set(definition);
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    },
    { allowSignalWrites: true }
  );

  constructor(
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {
    super();
  }

  async onSubmit(value: any): Promise<void> {
    if (this.form?.form().invalid) return;

    this.saveToQueryHistory(value);

    const belegart = this.belegart();
    if (!belegart) return;

    value = { ...value, belegart };

    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.clear
    );

    const query = base64Encode(JSON.stringify(value));

    await this.router.navigate(['/gesamtabfrage', belegart.id, 'list'], {
      queryParams: { query },
    });
  }

  async onSaveTemplate(): Promise<void> {
    await this.saveTemplate();
  }

  async onSaveTemplateAndExecute(): Promise<void> {
    await this.saveTemplate();
    await this.onSubmit(this.form?.form().value);
  }

  async onChangeBelegart(belegart: string): Promise<void> {
    await this.router.navigate(['/gesamtabfrage', belegart, 'find']);
  }

  clearStore(): void {
    this.patchFormViaStoreCommit({});
  }

  patchFormViaStoreCommit(newValue: object): void {
    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.query.setQuery,
      newValue
    );
  }

  override createSaveTemplateModalParameters(): object {
    return {
      group: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG + '/' + this.belegart()?.id,
      featureName: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      templateValue: this.form?.value,
      definition: this.definition(),
    };
  }

  override createLoadTemplateModalParameters(): object {
    return {
      group: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG + '/' + this.belegart()?.id,
      featureName: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      templateValue: this.form?.value,
      definition: this.definition(),
    };
  }

  override getQueryHistoryGroup(): string {
    return SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG + '/' + this.belegart()?.id;
  }

  override onLoadTemplate(template: Template<any>): void {
    this.form?.form().patchValue(template.value);
  }

  /*private createCommands(type: Belegart | undefined): Command2[] {
    if(!type)
      return [];

    return [
      {
        name: type.kundenName,
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-magnifying-glass',
        name: '#GESAMTABFRAGE.MENU.FIND',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage/', type.id, 'find'],
      },
    ];
  }*/
}
