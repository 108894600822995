import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment from 'moment';
import {DateParser} from '../../../services/date.parser';
import {UserAgentService} from '../../../services/user-agent.service';
import {SOFTLINE_FEATURE_MODAL} from '../../../modal/modal.shared';
import * as ModalStore from "../../../modal/modal.store";
import {Store} from '@softline/core';
import { I18nModule } from '../../../i18n/i18n.module';
import { L10nModule } from '../../../l10n/l10n.module';
import { NgIf } from '@angular/common';

@Component({
  selector: 'soft-date-input',
  standalone: true,
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18nModule, L10nModule, NgIf],
})
export class DateInputComponent implements ControlValueAccessor {
  onChange: Function = () => {};
  onTouch: Function = () => {};

  input: string | null = null;
  showPicker = false;
  hasDateSelected = false;

  @Input() format?: string;
  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly = false;

  @Input() changeTrigger: 'blur' | 'input' = 'blur';

  private _value: string | null | undefined;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (value === this._value) {
      // Fix for bug causing shown value = 'h' if entered twice
      this._value = '';
      this.cdRef.detectChanges();
    }
    this._value = value;
  }
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  constructor(
    private dateParser: DateParser<moment.Moment>,
    readonly userAgentService: UserAgentService,
    private cdRef: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  @HostListener('keydown.f4', ['$event']) async onF4(
    event: KeyboardEvent
  ): Promise<void> {
    await this.openDatePickerModal();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = obj;
      this.input = obj;
    } else {
      const value = this.dateParser.parse(obj);
      if (value) {
        this.value = value;
        if (value !== obj) {
          this.onChange(this._value);
          this.valueChange.emit(this._value);
          this.cdRef.markForCheck();
        }
      }
    }
  }

  onInput(value: string | null): void {
    switch (this.changeTrigger) {
      case 'input':
        this.input = value;
        this.setValue(value, true);
        break;
      default:
        this.input = value;
        break;
    }
  }

  onBlur(value: string | null): void {
    if (this.changeTrigger === 'blur') this.setValue(value, true);
    if (this.value) this.input = null;
  }

  setValue(value: string | null, parse: boolean): void {
    const prevValue = this.value;
    if (!value) this.value = null;
    else if (parse) this.value = this.dateParser.parse(value);
    else this.value = value;

    if (value === prevValue) return;
    this.onChange(this._value);
    this.valueChange.emit(this._value);
  }

  async openDatePickerModal(): Promise<void> {
    this.onTouch();

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.date,
      { value: this.value, dismiss: true }
    );

    if (!result || result === 'DISMISSED') return;

    this.setValue(result, false);
    this.cdRef.markForCheck();
  }
}
