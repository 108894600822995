import {Umsatzstatistik} from '../types/umsatzstatistik';
import { ChartSeriesEntry, GroupedBarChartEntry } from '../types/grouped-bar-chart-data';
import {UmsatzstatistikRemoteConfig} from '../types/remote-config';
import {sortByRemoteConfig} from './sort-by-remote-config';

export type ChartColumn = 'umsatz' | 'db' | 'lagerwerte'

export function toChartData(
  umsatzstatistik: Umsatzstatistik['ebeneresult'],
  remoteConfig: UmsatzstatistikRemoteConfig,
  columns: ChartColumn[] = ['umsatz', 'db']
): GroupedBarChartEntry[] {
  const data: GroupedBarChartEntry[] = []

  if (!umsatzstatistik)
    return []

  for (const [name, values] of Object.entries(umsatzstatistik)) {
    if (name === 'Alle')
      continue

    const series: ChartSeriesEntry[] = [];


    if (columns.includes('umsatz')) {
      series.push({
        name: 'Umsatz',
        value: values.arten?.['Summe']?.values?.['netto'] <= 0 ? 1 : values.arten?.['Summe']?.values?.['netto'] ?? 0,
        extra: {
          key: name,
          dbproz: values.arten?.['Summe']?.values?.['dbproz'] ?? 0
        }
      })
    }

    if (columns.includes('db')) {
      series.push({
        name: 'Rohertrag',
        value: values.arten?.['Summe']?.values?.['db'] <= 0 ? 1 : values.arten?.['Summe']?.values?.['db'] ?? 0,
        extra: {
          key: name,
          dbproz: values.arten?.['Summe']?.values?.['dbproz'] ?? 0
        }
      })
    }

    if (columns.includes('lagerwerte')) {
      series.push({
        name: 'Lagerwert',
        value: (values.arten?.['Summe']?.values?.['lagerwert'] ?? 0) <= 0 ? 1 : (values.arten?.['Summe']?.values?.['lagerwert'] ?? 0),
        extra: {
          key: name,
          dbproz: values.arten?.['Summe']?.values?.['dbproz'] ?? 0
        }
      })
    }

    data.push({
      name: values?.bezeichnung ?? name,
      series
    });
  }

  return sortByRemoteConfig(data, remoteConfig.sort);
}
