import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fabric } from 'fabric';
import { blobToDataURL, CameraService } from '@softline/core';
import { CommonModule } from '@angular/common';
import { ColorInputComponent } from '../form/color-input/color-input.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'soft-draw-pad',
  standalone: true,
  templateUrl: './draw-pad.component.html',
  styleUrls: ['./draw-pad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ColorInputComponent, ClickOutsideDirective],
  providers: [],
})
export class DrawPadComponent implements OnInit, AfterViewInit {
  canvas!: fabric.Canvas;

  activeObjects?: fabric.Object[];
  color = '#000000';
  tool: 'select' | 'pen' = 'pen';
  stroke: 'light' | 'medium' | 'bold' | 'extrabold' = 'medium';

  get strokeWidth(): number {
    switch (this.stroke) {
      case 'light':
        return 25;
      case 'medium':
        return 50;
      case 'bold':
        return 75;
      case 'extrabold':
        return 100;
    }
  }

  @Input() drawing?: string;
  @Input() options?: { canvasHeight: number; canvasWidth: number };

  @ViewChild('content', { static: true }) content!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private cameraService: CameraService
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    const canvas = this.renderer.createElement('canvas') as HTMLCanvasElement;

    canvas.id = `draw-pad`;
    canvas.width =
      this.options?.canvasWidth ?? this.elementRef.nativeElement.offsetWidth;
    canvas.height = this.options?.canvasHeight ?? window.innerHeight * 0.8;

    this.renderer.appendChild(this.content.nativeElement, canvas);

    this.canvas = new fabric.Canvas(canvas, {
      isDrawingMode: true,
      backgroundColor: '#FFFFFF',
    });
    this.canvas.freeDrawingBrush.width = this.stokeToPixel('medium');
    if (this.drawing) {
      fabric.Image.fromURL(this.drawing, (img: any) => {
        // add background image
        this.canvas.setBackgroundImage(
          img,
          this.canvas.renderAll.bind(this.canvas),
          {
            scaleX: this.canvas.getWidth() / (img?.width ?? 1),
            scaleY: this.canvas.getHeight() / (img?.height ?? 1),
          }
        );
      });
    }

    this.canvas.on('mouse:up', () => {
      this.activeObjects = this.canvas.getActiveObjects();
      this.cdRef.detectChanges();
    });
    this.canvas.on('mouse:wheel', (opt: any) => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }

  toDataURL(): string {
    return this.canvas.toDataURL();
  }

  isEmpty(): boolean {
    return this.canvas.isEmpty();
  }

  onColorChange(color: string): void {
    this.color = color;
    this.canvas.freeDrawingBrush.color = color;
    this.onToolChange('pen');

    this.cdRef.detectChanges();
  }

  onToolChange(tool: 'select' | 'pen'): void {
    this.tool = tool;
    switch (tool) {
      case 'select':
        this.canvas.isDrawingMode = false;
        break;
      case 'pen':
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        this.canvas.freeDrawingBrush.width = this.stokeToPixel(this.stroke);
        this.canvas.freeDrawingBrush.color = this.color;
        this.canvas.isDrawingMode = true;
        break;
    }
    this.cdRef.detectChanges();
  }

  onStrokeChange(stroke: 'light' | 'medium' | 'bold' | 'extrabold'): void {
    this.stroke = stroke;
    this.canvas.freeDrawingBrush.width = this.stokeToPixel(stroke);
    this.onToolChange('pen');
    this.cdRef.detectChanges();
  }

  onDeleteSelected(): void {
    this.canvas.remove(...this.canvas.getActiveObjects());
  }

  async onTakePicture(): Promise<void> {
    const picture = await this.cameraService.snap();
    const datauri = await blobToDataURL(picture.file);

    fabric.Image.fromURL(datauri, (img: any) => {
      console.log(
        this.canvas.getWidth(),
        this.canvas.getHeight(),
        img.width,
        img.height
      );
      const img1 = img.set({
        left: 0,
        top: 0,
        scaleX: this.canvas.getWidth() / (img.width ?? this.canvas.getWidth()),
        scaleY:
          this.canvas.getHeight() / (img.height ?? this.canvas.getHeight()),
      });
      this.canvas.add(img1);
    });
    this.onToolChange('select');
  }

  private stokeToPixel(
    stroke: 'light' | 'medium' | 'bold' | 'extrabold'
  ): number {
    switch (stroke) {
      case 'light':
        return 1;
      case 'bold':
        return 7;
      case 'extrabold':
        return 15;
      default:
        return 3;
    }
  }
}
