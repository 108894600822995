<div>
  <div class="c row space-between no-wrap center-v mt-3 pb-2">
    <h2 class="pl-3 pr-3 border-box  text-xl font-semibold">{{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.TITLE' | translate }}</h2>
    <button class="soft-button soft-button-link"
            *ngIf="!hasGeodaten"
            (click)="saveLocation()">
      Standort speichern
    </button>
  </div>

  <div class="separator-top separator-bottom pt-3 pb-3 border-box c row no-wrap space-between mt-2 mb-4">
    <div class="c column pl-3 pr-3 border-box">
      <p class="text-base font-semibold">{{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.TIMESTAMP_LABEL' | translate }}</p>
      <span class="text-sm color-light">{{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.TIMESTAMP_TEXT' | translate }}</span>
    </div>
    <div class="c row no-wrap center-v pl-3 pr-3 border-box">
      <soft-time-input #timeInput [value]="currentTime"></soft-time-input>
      <span class="text-base font-semibold ml-3" style="min-width: 2.5rem">{{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.CLOCK_UNIT' | translate }}</span>
    </div>
  </div>

  <div class="c column pl-3 pr-3 border-box mb-4">
    <p class="text-base font-semibold">{{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.SIGN_LABEL' | translate }}</p>
    <div class="mt-1 w-full" #container>
      <soft-signature-pad #signaturepad [options]="{ minWidth: 2, canvasWidth: container.offsetWidth - 6, canvasHeight: container.offsetHeight + 2 }"></soft-signature-pad>
    </div>
  </div>
</div>

<div class="button-container border-box mt-4">
  <button class="soft-button secondary custom-size" (click)="close(null)">
    {{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.ABORT' | translate }}
  </button>
  <button class="soft-button primary custom-size" (click)="closeDialog(timeInput?.value, signaturepad?.toDataURL(), signaturepad?.isEmpty())">
      {{ '#DELIVERY_NOTE.DIALOGS.SIGNATURE.SAVE' | translate }}
  </button>
</div>
