import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'soft-color-input',
  standalone: true,
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorInputComponent implements OnInit {
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value: string | null | undefined;
  @Output() valueChange: EventEmitter<string | null | undefined> =
    new EventEmitter<string | null | undefined>();

  @Input() readonly = false;

  @Output() blur = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string | null): void {
    this.value = value;

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
