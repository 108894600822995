import {KzAusgabe2CalculationData, KzAusgabe2Strategy} from './kz-ausgabe-2.strategy';
import {Dictionary} from '@softline/core';

export class KzAusgabe2InputCalculationStrategy implements KzAusgabe2Strategy {
  calculate(data: KzAusgabe2CalculationData): Dictionary<number | null> {
    console.log('calculation strategy 2 in use....: ', data.dict);
    data.dict[data.inputArteh.id] = null
    data.dict[data.ausgabeEh2.id] = Math.floor(data.inputValue)
    return data.dict
  }
}
