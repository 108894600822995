import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {
  ApplicationStore, CommandStore,
  SOFTLINE_CONFIG_OFFLINE_FEATURE,
  SOFTLINE_FEATURE_APPLICATIONS,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_INVENTUR,
  SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_INVENTUR_BEWE, SOFTLINE_PERMISSION_MDE_INVENTUR
} from "./inventur.shared";
import {InventurStore} from './store/inventur.store';
import {SOFTLINE_API_MDE_INVENTUR_ARTIKEL, SOFTLINE_API_MDE_INVENTUR_BEWEGUNGEN} from './inventur.api';
import {InventurBeweStore} from './store/inventur-bewe.store';
import {InventurService} from './services/inventur.service';
import {InventurArtikelDetailStore} from './store/inventur-artikel-detail.store';
import {InventurResolver} from './resolver/inventur.resolver';

export const inventurProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeInventur',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Inventur',
          icon: 'fa-regular fa-warehouse-full',
          route: '/mde-inventur',
          permission: SOFTLINE_PERMISSION_MDE_INVENTUR,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_INVENTUR_BEWE,
        InventurBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_INVENTUR,
      feature: InventurStore.feature,
      providers: [{
        provide: InventurService,
        useClass: InventurService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_INVENTUR_BEWE,
      feature: InventurBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_INVENTUR_BEWEGUNGEN
        )
      }]
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
      feature: InventurArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_INVENTUR_ARTIKEL,
              'detail'
            );
          }
        }
      ]
    })
  ),
  InventurResolver,
]
