import {KzAusgabe2CalculationData, KzAusgabe2Strategy} from './kz-ausgabe-2.strategy';
import {Dictionary} from '@softline/core';

export class KzAusgabeNInputCalculationStrategy implements KzAusgabe2Strategy {
  calculate(data: KzAusgabe2CalculationData): Dictionary<number | null> {
    const erfMenge = data.inputValue / (data.baseArteh.faktor / data.inputArteh.faktor)
    const faktor = (data.ausgabeEh2.faktor / data.baseArteh.faktor)
    const ausgabeEh2Menge = Math.floor(erfMenge / faktor)
    data.dict[data.ausgabeEh2.id] = ausgabeEh2Menge
    data.dict[data.inputArteh.id] = (erfMenge - (ausgabeEh2Menge * data.ausgabeEh2.faktor)) / data.inputArteh.faktor
    return data.dict
  }
}
