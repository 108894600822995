<ng-container *ngIf="filteredItems$ | async as abteilungen">
  <div class="flex flex-row w-full mt-2">
    <soft-filter-and-sort
      class="w-full mt-2"
      [filter]="filter$ | async"
      [sort]="sort$ | async"
      [values]="(abteilungen$ | async) || []"
      [filterConfig]="filterConfig"
      [sortConfig]="sortConfig"
      (filterChange)="onFilterChange($event)"
      (sortChange)="onSortChange($event)">
    </soft-filter-and-sort>
  </div>

  @if ((abteilungen.length > 0) || (loading$ | async) === false) {
    <div class="flex flex-col mt-5 pb-5 gap-2 select-none">
      @for (abteilung of abteilungen; track trackByAbteilungId) {
        <soft-abteilung-card
          [abteilung]="abteilung"
          [routerLink]="['./abteilung', abteilung?.abteilung?.id]">
        </soft-abteilung-card>
      } @empty {
        <div class="text-sm italic text-center w-full flex items-center justify-center p-6">
          Keine Abteilungen gefunden
        </div>
      }
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="skeleton"></ng-container>
  }

  <ng-template #skeleton>
    <div class="flex flex-col mt-5 pb-5 gap-2">
      <div class="flex soft-card rounded w-full h-20" *softRepeat="5">
        <div class="flex flex-col gap-2.5 items-start pb-1.5 pt-2">
          <div class="h-4 w-20 skeleton rounded"></div>
          <div class="h-4 w-36 skeleton rounded"></div>
        </div>
      </div>
  </div>
  </ng-template>
</ng-container>
