import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  viewChild
} from '@angular/core';
import {Arteh} from '../../types/arteh';
import {SearchStornoBewe, StornoBewe} from '../../types/return-note';
import {SOFTLINE_CONFIG_LIEFERSCHEIN} from '../../delivery-note.shared';
import {SOFTLINE_SERVICE_UUID, Store} from '@softline/core';
import {ReturnNoteBeweModalService} from '../../services/return-note-bewe-modal.service';
import {CommonModule} from '@angular/common';
import {ModalStore, NumberInputComponent, SOFTLINE_FEATURE_MODAL, UiCoreModule} from '@softline/ui-core';
import {
  ConfirmMengeAboveMaxMengeDialogComponent
} from '../../dialogs/confirm-menge-above-max-menge/confirm-menge-above-max-menge-dialog';
import {DeliveryNoteConfig} from '../../types/delivery-note-config';
import {ArtikelStorno} from '../../types/artikel-storno';

@Component({
  selector: 'lib-return-note-search-item',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './return-note-search-item.component.html',
  styleUrls: ['./return-note-search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReturnNoteSearchItemComponent {

  hasError = false;
  hasSplit = false;

  // tslint:disable-next-line:variable-name
  private _changes = new Map<number, StornoBewe>();

  // tslint:disable-next-line:variable-name
  private _arteh: Arteh | undefined = undefined;

  @Input() bewelf!: ArtikelStorno;
  @Input() returnNoteId!: string;
  @Input() value?: SearchStornoBewe;

  @Output() beweChanged = new EventEmitter<SearchStornoBewe>();
  @Output() mengeAufsplitten = new EventEmitter<{ menge: number; bewe: SearchStornoBewe }>();

  readonly numberInput = viewChild<NumberInputComponent>('menge')

  get artehBean(): Arteh | undefined {
    return this._arteh ?? this.bewelf.artehBeans[0];
  }

  constructor(
    private store: Store,
    private modalService: ReturnNoteBeweModalService,
    private cdRef: ChangeDetectorRef,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    @Optional() @Inject(SOFTLINE_CONFIG_LIEFERSCHEIN) readonly deliveryNoteConfig: DeliveryNoteConfig
  ) { }

  async onChange(bewe: SearchStornoBewe | undefined, menge: number | null | undefined, arteh: Arteh | undefined, numberInput: NumberInputComponent): Promise<void> {
    const maxRetourmenge = arteh?.maxRetourmenge ?? this.artehBean?.maxRetourmenge ?? Number.MAX_VALUE;
    const oldValue = bewe?.menge ?? 0;

    let aufsplitten = false;
    let reset = false;

    if (this.deliveryNoteConfig?.openDialogMaxMenge && ((menge ?? 0) > maxRetourmenge)) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open(),
        {
          id: 'CONFIRM_MENGE_ABOVE_MAX_MENGE_DIALOG',
          component: ConfirmMengeAboveMaxMengeDialogComponent,
          dismiss: true,
        }
      );

      if (!result || result === 'DISMISSED') {
        menge = oldValue;
        reset = true;
      } else {
        aufsplitten = true
      }
    }

    this._arteh = arteh;
    bewe = !bewe ? this.createEmptyBewe() : { ...bewe };

    if (reset) {
      bewe.menge = menge;
      bewe.artehBean = arteh ?? this.artehBean;
      bewe.bewelf = {
        ...bewe.bewelf,
        einheit: (arteh ?? this.artehBean)?.arteh,
        menge: menge ?? undefined
      }

      const input = this.numberInput();

      if (input) {
        numberInput.value = oldValue;
        input.value = oldValue;
      }

      this.beweChanged.emit(bewe);
      this.cdRef.detectChanges();
      return;
    }

    if (aufsplitten) {
      this.mengeAufsplitten.emit({ menge: menge ?? 0, bewe });
      this.hasSplit = true;
    } else {
      this.hasSplit = false;
    }

    bewe.menge = menge;
    bewe.artehBean = arteh ?? this.artehBean;

    bewe.bewelf = {
      ...bewe.bewelf,
      einheit: (arteh ?? this.artehBean)?.arteh,
      menge: menge ?? undefined
    }

    this.beweChanged.emit(bewe);
  }

  setMenge(bewe: SearchStornoBewe | undefined, value: number): void {
    if (!bewe)
      bewe = this.createEmptyBewe();

    const input = this.numberInput();

    if (input) {
      input.value = value === 0 ? null : value;
    }

    this.beweChanged.emit({
      ...bewe,
      menge: value,
      bewelf: {
        ...bewe.bewelf,
        menge: value,
      }
    });

    this.cdRef.detectChanges();
  }

  async onShowExtendedUnitDialog(bewe: SearchStornoBewe | undefined) {
    // if (!bewe)
     // bewe = this.createEmptyBewe();

    // await this.modalService.showExtendedUnitDialog(this.returnNoteId, bewe, this.bewelf);
  }

  private createEmptyBewe(): SearchStornoBewe {
    return {
      id: this.uuid(),
      menge: 0,
      files: [],
      typ: 'search',
      artehBean: this.artehBean,
      bewelf: {
        id: this.bewelf?.id ?? this.uuid(),
        artehBeans: this.bewelf?.artehBeans && Array.isArray(this.bewelf?.artehBeans)
          ? this.bewelf.artehBeans
          : (this.bewelf?.artehBean ? [this.bewelf?.artehBean] : []),
        menge: this.bewelf.menge,
        vermerk: this.bewelf.vermerk,
        idartstamm: this.bewelf.idartstamm,
        arteheingaben: [],
        artikelnummer: this.bewelf.artikelnummer,
        beschreibung: this.bewelf.beschreibung,
        bezeichnung1: this.bewelf.bezeichnung1,
        bezeichnung2: this.bewelf.bezeichnung2,
        bezeichnung3: this.bewelf.bezeichnung3,
        bezeichnung4: this.bewelf.bezeichnung4,
        bezeichnung5: this.bewelf.bezeichnung5,
        einheit: this.artehBean?.arteh ?? this.bewelf?.einheit,
        editable: false,
        zeilentext: this.bewelf.zeilentext,
      },
      vermerk: '',
    };
  }
}
