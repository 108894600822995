import {
  Directive,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ViewDirective } from './view-directive';

@Directive({
  selector: '[softDataView]',
  standalone: true,
  exportAs: 'dataView',
  providers: [
    {
      provide: ViewDirective,
      useExisting: forwardRef(() => DataViewDirective),
      multi: true,
    },
  ],
})
export class DataViewDirective<T> extends ViewDirective {
  private _onSelect?: (entity: T) => void;
  private _onSubmit?: (entity: T) => void;

  @Input('softDataView') name = '';
  @Input() title = '';
  @Input() override visible = true;

  constructor(template: TemplateRef<any>, viewContainer: ViewContainerRef) {
    super(template, viewContainer);
  }

  select(entity: T): void {
    if (this._onSelect) this._onSelect(entity);
  }
  submit(entity: T): void {
    if (this._onSubmit) this._onSubmit(entity);
  }

  registerSelect(onSelect: (entity: T) => void): void {
    this._onSelect = onSelect;
  }
  registerSubmit(onSubmit: (entity: T) => void): void {
    this._onSubmit = onSubmit;
  }
  setContext(entities$: Observable<T[]>): void {
    this.portal.context = { entities$ };
  }
}
