<ng-container *ngIf="item">
    <div class="c row space-between no-wrap center-v pt-3 pb-3">
        <div class="c column no-wrap pl-3 pr-3 border-box">
            <div class="c row center-v">
                <span class="text-sm color-light">{{ item.itemScanBean?.artikel?.nummer }}</span>
                <i class="fa-regular fa-images info-icon" *ngIf="item?.files?.length"></i>
                <i class="fa-regular fa-note-sticky info-icon" *ngIf="item?.vermerk?.length"></i>
            </div>

            <p class="text-base font-semibold mt-1 mb-1" *ngIf="item.itemScanBean.artikel.artbez1?.length">{{ item.itemScanBean.artikel.artbez1 }}</p>
            <p class="text-base font-semibold mt-1 mb-1" *ngIf="item.itemScanBean.artikel.artbez3?.length">{{ item.itemScanBean.artikel.artbez2 }}</p>
            <p class="text-base font-semibold mt-1 mb-1" *ngIf="item.itemScanBean.artikel.artbez2?.length">{{ item.itemScanBean.artikel.artbez3 }}</p>
        </div>
        <div class="c row end no-wrap center-v pl-3 border-box ">
            <div class="flex relative">
                <ng-container>
                    <soft-number-input placeholder="Menge"
                                       [value]="item.itemScanBean?.menge"
                                       changeTrigger="input"
                                       style="max-width: 80px"
                                       (valueChange)="onChange(menge?.value)"
                                       #menge>
                    </soft-number-input>

                    <div style="width: 100px;" class="ml-2">
                        <span class="soft-title ml-3 color-light"
                              style="width: 100px">{{ item.itemScanBean.einheit.arteh || ''}}</span>
                    </div>
                </ng-container>
            </div>

            <span class="detail-area">
                <i class="fa-regular fa-trash pointer p-1"
                   [ngStyle]="{ 'color': (!!(item.itemScanBean?.menge) || !!menge?.value) ? 'var(--colorError)' : 'rgba(0, 0, 0, 0.33)'}"
                   (click)="onDelete(); menge.value = null"></i>
            </span>
        </div>
    </div>
</ng-container>
