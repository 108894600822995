<div class="soft-card rounded mb-2 w-full">
  <div class="c row space-between no-wrap center p-1 border-box">
    <div class="c column">
      <div [ngClass]="(deliveryMaterial?.bezeichnung1?.length || 0) < 1 && (deliveryMaterial?.bezeichnung2?.length || 0) < 1 && !deliveryMaterial?.menge ? 'font-semibold' : 'text-sm color-light'">
        {{ deliveryMaterial?.artikelnummer }}
        <i class="fa-regular fa-note-sticky info-icon" *ngIf="(deliveryMaterial?.vermerk?.length || 0) > 0"></i>
      </div>
      <span class="mt-1 font-semibold">{{ deliveryMaterial?.bezeichnung1 }}</span>
      <div class="text-sm color-light mt-1" *ngIf="deliveryMaterial?.bezeichnung2">{{ deliveryMaterial?.bezeichnung2 }}</div>
      <div class="text-sm color-light mt-1" *ngIf="deliveryMaterial?.bezeichnung3">{{ deliveryMaterial?.bezeichnung3 }}</div>
      <div class="text-sm color-light mt-1" *ngIf="deliveryMaterial?.beschreibung">{{ deliveryMaterial?.beschreibung }}</div>
      <div *ngIf="deliveryMaterial?.zeilentext"
           [ngClass]="( deliveryMaterial?.bezeichnung1?.length ?? 0 ) < 1 && (deliveryMaterial?.bezeichnung2?.length ?? 0 ) < 1 && !deliveryMaterial?.artikelnummer ? 'mt-1 font-semibold' : 'color-light mt-1'">
        {{ deliveryMaterial?.zeilentext }}
      </div>
    </div>
    <div class="c column end">
      <div *ngIf="deliveryMaterial?.editable !== true; else editableTemplate" class="c row end text-base font-semibold">
        {{ deliveryMaterial?.menge }} {{ deliveryMaterial?.einheit }}
      </div>
      <ng-template #editableTemplate>
        <div class="c row end no-wrap center-v end pl-3 border-box">
          <div class="flex gap-0 soft-input !w-auto items-center text-center !p-1 justify-center">
            <button class="soft-button bg-primary text-white" [disabled]="(deliveryMaterial?.menge ?? 0) < 1" (click)="input.setValue('' + ((input?.value || 0) - 1))">-</button>
            <soft-number-input placeholder="Menge" class="custom-input-alignment w-auto appearance-none !text-center border-none border-0 !h-full"
                               #input
                               style="max-width: 70px;"
                               [value]="deliveryMaterial?.menge"
                               (valueChange)="onChange(input?.value ?? 0)">
            </soft-number-input>
            <button class="soft-button bg-primary text-white" (click)="((input?.value || 0) !== null) && (input?.value || 0) >= 0 && input.setValue('' + ((input?.value || 0) + 1))">+</button>
          </div>

          <span class="soft-title ml-3 color-light" style="width: 100px;">{{ (deliveryMaterial?.einheit?.length ?? 0) > 0 ? deliveryMaterial?.einheit : 'EH' }}</span>
          <span class="detail-area cursor-pointer" (click)="onEditBeweClick()">
              <i class="fa-regular fa-ellipsis-vertical"></i>
          </span>
        </div>
      </ng-template>
      <div class="c row end">
        <span *ngFor="let eh of deliveryMaterial?.arteheingaben; let first = first" class="text-gray-500">
          <ng-container *ngIf="first; else plusTemp">=</ng-container> {{ eh?.menge}} {{ eh?.arteh }}
          <ng-template #plusTemp>&nbsp;+</ng-template>
        </span>
      </div>
    </div>
  </div>
</div>
