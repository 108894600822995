<strong class="text-lg font-semibold mt-6 pt-2 ml-3 mr-3 border-box">{{ images[(selectedIndex || 0)]?.name }}</strong>

<div class="c column center p-rel mt-2 mb-2 border-box">
    <div class="gallery-navigator left" (click)="showPrevious()">
        <i class="fa-regular fa-chevron-left fa-2x icon" *ngIf="hasPrevious"></i>
    </div>

    <div class="image-container">
        <img [attr.src]="selectedImageURL"  alt="currently selected image"/>
    </div>

    <div class="gallery-navigator right" (click)="showNext()">
        <i class="fa-regular fa-chevron-right fa-2x icon" *ngIf="hasNext"></i>
    </div>

    <div class="c p-abs center gallery-stepper-container">
        <div class="gallery-stepper">
            <div *ngFor="let image of images; let i = index"
                 class="gallery-indicator"
                 [class.active]="selectedIndex === i"
                 (click)="selectedIndex = i"></div>
        </div>
    </div>
</div>
