import { inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import { BackNavigationService } from "../program/services/back-navigation.service";
import { BackNavigable, Refreshable, RefreshService } from "@softline/application";
import { Router } from "@angular/router";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithRefresh = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BackNavigationMixin extends Base implements OnInit, OnDestroy, Refreshable {

    router = inject(Router);
    refreshService = inject(RefreshService);
    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      this.refreshService.add(this);
    }

    ngOnDestroy(): void {
      this.refreshService.remove(this);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    abstract refresh(): Promise<void>
  }
  return BackNavigationMixin;
}
