import {Dictionary} from '@softline/core';
import {
  KzAusgabeAllInputCalculationData,
  KzAusgabeAllInputCalculationStrategy
} from './input-calculation.strategy';

export class KzAusgabeNInputCalculationStrategy implements KzAusgabeAllInputCalculationStrategy  {
  calculate(data: KzAusgabeAllInputCalculationData): Dictionary<number | null> {
    const faktor = (data.ausgabeEh1.faktor / data.inputArteh.faktor)
    const ausgabeEh1Quantity = Math.ceil(data.inputValue / faktor)

    const faktor2 = (data.ausgabeEh2.faktor / data.ausgabeEh1.faktor)
    const ausgabeEh2Quantity = Math.floor(ausgabeEh1Quantity / faktor2)

    if (ausgabeEh2Quantity > 0) {
      const restInAusgabeEh1 = (ausgabeEh2Quantity * data.ausgabeEh2.faktor)
      data.dict[data.ausgabeEh2.id] = ausgabeEh2Quantity
      data.dict[data.ausgabeEh1.id] = ausgabeEh1Quantity - restInAusgabeEh1
    } else {
      data.dict[data.ausgabeEh1.id] = ausgabeEh1Quantity
    }

    return data.dict
  }
}
