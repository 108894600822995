<ng-container *ngIf="(bewegungen?.length || 0) > 0; else placeholder">
  <div class="mt-4 soft-card rounded-sm border border-primary border-solid !p-0"
       *ngFor="let bewegung of bewegungen; trackBy: trackByFn">
    <div class="flex flex-col md:flex-row justify-between m-3 pb-2 gap-2"
         [ngClass]="{'border-b border-solid':
              bewegung.auftrag || (bewegung?.zeilentext && bewegung?.zeilentext !== '')}">
      <div class="flex flex-col w-auto md:w-2/4 lg:w-2/3 gap-0.5">
        <p class="font-semibold">
          <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 1">00{{bewegung?.lfdnr}}</span>
          <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 2">0{{bewegung?.lfdnr}}</span>
          <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) >= 3">{{bewegung?.lfdnr}}</span>
        </p>
        <p>
          <span *ngIf="bewegung.artikelMenge?.artikel?.lieferantArtikelNummer">
            {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_LIEFERANT_ARTIKELNUMMER' | translate}} {{bewegung.artikelMenge?.artikel?.lieferantArtikelNummer ?? ''}} |
          </span>
          <span *ngIf="bewegung.artikelMenge?.artikel?.ean">
            {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_EAN' | translate}} {{bewegung.artikelMenge?.artikel?.ean}} |
          </span>
          {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ARTIKELNUMMER' | translate}} {{bewegung.artikelMenge?.artikel?.nummer ?? ''}}
        </p>
        <p class="font-semibold text-lg">
          {{bewegung.artikelMenge.artikel?.artbez1 ?? '-'}}
        </p>
        <p class="text-light"
           *ngIf="bewegung.artikelMenge.artikel?.artbez2 && bewegung.artikelMenge.artikel?.artbez2 !== ''">
          {{bewegung.artikelMenge.artikel?.artbez2}}
        </p>
      </div>
      <div class="flex flex-col mt-2 lg:mt-0 w-auto md:w-2/4 lg:w-1/3 gap-1">
        <div class="flex flex-row w-full gap-2 justify-end">
          <soft-mengen-eingabe #mengenEingabe
                               [disabled]="isBestellung || (bewegung.status === 'E')"
                               [float]="true"
                               [value]="bewegung.artikelMenge.menge ?? 0"
                               (valueChange)="mengeChange.emit({ bewe: bewegung, menge: mengenEingabe.value })"
                               [einheit]="(bewegung.artikelMenge?.einheit?.arteh ?? '') | uppercase"
                               class="w-4/5 md:w-full"
                               [ngClass]="{'soft-mengen-eingabe-order w-full': (isBestellung || (bewegung.status === 'E'))}">
          </soft-mengen-eingabe>
          <button
            *ngIf="(!isBestellung && bewegung.status !== 'E') || (isBestellung && (bewegung.status !== 'O'))"
            class="soft-button primary flex items-center justify-center text-white w-1/5 md:w-auto min-w-[3.5rem] rounded-sm"
            (click)="mengenEingabeOeffnen.emit(bewegung)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
            </svg>
          </button>
          <button
            *ngIf="!isBestellung"
            class="soft-button primary flex items-center justify-center text-white w-1/5 md:w-auto min-w-[3.5rem] rounded-sm"
            (click)="statusChange.emit(bewegung)">
            <svg *ngIf="bewegung.status === 'O'"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <svg *ngIf="bewegung.status === 'E'"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>
          </button>
        </div>
        <p class="text-sm text-light ml-auto">
          = {{bewegung?.artikelMenge?.menge_lg}} {{ (bewegung?.artikelMenge?.einheit_lg?.arteh ?? '') | uppercase}}
        </p>
        <p *ngIf="bewegung?.lagerplatz && (bewegung?.lagerplatz?.length || 0) > 0"
          class="text-sm font-semibold ml-auto">
          {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_LAGER' | translate}} {{bewegung?.lagerplatz}}
        </p>
      </div>
    </div>
    <div class="flex flex-col m-3 gap-2" *ngIf="bewegung?.auftrag"
         [ngClass]="{'border-b border-solid pb-4': bewegung?.zeilentext && bewegung?.zeilentext !== ''}">
      <p class="text-light">
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_AUFTRAG' | translate}} {{bewegung?.auftrag?.auftragnr}}
      </p>
      <p class="text-light">
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_KUNDE' | translate}} {{bewegung.auftrag?.kunde?.kundennummer}} {{bewegung.auftrag?.kunde?.kurzbez}} |
        {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ADRESSE' | translate}} {{bewegung.auftrag?.kunde?.strasse}}<ng-container *ngIf="bewegung.auftrag?.kunde?.strassenr"> {{bewegung.auftrag?.kunde?.strassenr}}</ng-container>,
        {{bewegung.auftrag?.kunde?.plz}} {{bewegung.auftrag?.kunde?.ort}}
      </p>
    </div>
    <div class="flex flex-row w-full p-3" *ngIf="bewegung?.zeilentext && bewegung?.zeilentext !== ''">
      <p class="text-light mb-2">
        {{bewegung?.zeilentext}}
      </p>
    </div>
  </div>
</ng-container>

<ng-template #placeholder>
  <div class="flex items-center justify-center text-center text-sm text-lighter py-4 mt-2">
    {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_KEINE_BEWEGUNGEN' | translate}}
  </div>
</ng-template>



