import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {SOFTLINE_PERMISSION_LIEFERSCHEIN} from './delivery-note.shared';
import { MasterDataResolver, OfflineResolver } from "@softline/application";
import {ReturnNoteBeweModalService} from './services/return-note-bewe-modal.service';
import {DeliveryNoteChangedPipe} from './pipes/delivery-note-changed.pipe';

export const lieferscheinRoutes: Routes = [
  {
    path: 'lieferscheine',
    loadComponent: () => import('./pages/delivery-note.component').then(o => o.DeliveryNoteComponent),
    children: [
      { path: ':id', loadComponent: () => import('./pages/details/details.component').then(o => o.DetailsComponent) },
      { path: 'return-note/:id', loadComponent: () => import('./pages/return-note/return-note.component').then(o => o.ReturnNoteComponent)},
      { path: 'return-note/:id/search', loadComponent: () => import('./pages/return-note-search-items/return-note-search-items.component').then(o => o.ReturnNoteSearchItemsComponent)},
      { path: 'return-note/:id/scan', loadComponent: () => import('./pages/return-note-scan-items/return-note-scan-items.component').then(o => o.ReturnNoteScanItemsComponent)},
      { path: '', loadComponent: () => import('./pages/overview/overview.component').then(o => o.OverviewComponent) }
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_LIEFERSCHEIN},
    resolve: { offline: OfflineResolver, masterData: MasterDataResolver },
    providers: [
      ReturnNoteBeweModalService,
      DeliveryNoteChangedPipe
    ]
}];
