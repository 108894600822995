import {Emballage} from './emballage';
import {BeweLf} from './bewe-lf';
import { ItemScan } from '@softline/application';

export type ReturnNoteBewe =
  | EmballageBewe
  | StornoBewe
  | VermerkBewe
  | SearchStornoBewe
  | ItemScanBewe;

export interface EmballageBewe {
  id: string;
  typ: 'emballage';
  emballageBean: Emballage;
  menge?: number | null;
  vermerk: string;
  files: File[];
}

export interface StornoBewe {
  id: string;
  typ: 'storno';
  idbewelf: number | string;
  menge: number | null | undefined;
  vermerk: string;
  artehBean?: any;
  files: File[];
}

export interface SearchStornoBewe {
  id: string;
  typ: 'search';
  bewelf: BeweLf;
  menge: number | null | undefined;
  vermerk: string;
  artehBean?: any;
  files: File[];
  deliveryNote?: string;
  date?: string;
}

export interface VermerkBewe {
  id: string;
  lfdnr: number;
  typ: 'vermerk';
  vermerk: string;
  files: File[];
}

export interface ItemScanBewe {
  id: string;
  typ: 'itemScan';
  itemScanBean: ItemScan;
  vermerk: string;
  files: File[];
}

export interface ReturnNote {
    id: string;
    idlf: number;
    email?: boolean;
    vermerk?: string;
    bewes: ReturnNoteBewe[];
}
