import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemScanBewe } from '../../types/return-note';
import { Arteh } from '../../types/arteh';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
  selector: 'lib-scan-item',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './scan-item.component.html',
  styleUrls: ['./scan-item.component.scss']
})
export class ScanItemComponent implements OnInit {

  @Input() item!: ItemScanBewe;

  @Output() itemChanged = new EventEmitter<ItemScanBewe>();
  @Output() itemRemoved = new EventEmitter<ItemScanBewe>();

  constructor() { }

  ngOnInit(): void {}

  onChange(menge: number | null | undefined): void {
    this.itemChanged.emit({
      ...this.item,
      itemScanBean: {
        ...this.item.itemScanBean,
        menge: menge ?? 0
      }
    });
  }

  onDelete(): void {
    this.itemRemoved.emit(this.item);
  }
}
