import { Injectable } from '@angular/core';
import { ConnectionHttpService, ResponseResult } from '@softline/core';
import { Observable } from 'rxjs';
import {
  SOFTLINE_API_ESL_FLASH,
  SOFTLINE_API_ESL_FLASH_EXISTS,
} from './../esl.api';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EslLinkFlashService {
  constructor(private service: ConnectionHttpService) {}

  exists(idartstamm: number): Observable<string[]> {
    return this.service
      .get<ResponseResult<string[]>>({
        path: SOFTLINE_API_ESL_FLASH_EXISTS,
        pathParams: {
          idartstamm: idartstamm,
        },
      })
      .pipe(map((o) => o?.result ?? []));
  }

  flash(body: { idartstamm: number }[]): Observable<{ idartstamm: number }[]> {
    return this.service.create<typeof body, ResponseResult<typeof body>>({ path: SOFTLINE_API_ESL_FLASH }, body).pipe(
      map((o) => o?.result ?? [])
    );
  }
}
