import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import JsBarcode from 'jsbarcode';
import { isNullOrEmpty } from '@softline/core';
import { CommonModule } from '@angular/common';

type labelTypes =
  | 'codabar'
  | 'code39'
  | 'code128'
  | 'ean13'
  | 'ean8'
  | 'itf'
  | 'upc';

@Component({
  selector: 'soft-barcode',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeComponent {
  private _barcode: any

  data = input.required<string>();
  labelType = input<labelTypes>('ean13');
  size = input<'sm' | 'md' | 'lg' | undefined>('md');
  height = computed(() => {
    switch (this.size()) {
      case 'sm': return 50;
      case 'md': return 100;
      case 'lg': return 150;
      default: return 100;
    }
  });

  width = computed(() => {
    switch (this.size()) {
      case 'sm': return 1;
      case 'md': return 2;
      case 'lg': return 4;
      default: return 2;
    }
  });

  viewChild = viewChild<ElementRef>('ref');
  dataEffect = effect(() => {
    const data = this.data();
    const labelType = this.labelType();
    const viewChild = this.viewChild();

    if(!isNullOrEmpty(data) && viewChild)
      this._barcode = JsBarcode(viewChild.nativeElement,
        data, {
        format: labelType,
        width: this.width(),
        height: this.height(),
        background: 'transparent'
      });
  })



  constructor() {}
}
