<div class="flex items-center justify-between">
    <div class="flex flex-col">
        <span class="soft-overline">{{ bewelf?.artikelnummer }}</span>
        <div class="mt-1 mb-1">
          <p class="text-base font-semibold" *ngIf="bewelf?.bezeichnung1?.length">{{ bewelf?.bezeichnung1 }}</p>
          <p class="text-base text-light" *ngIf="bewelf?.bezeichnung2?.length">{{ bewelf?.bezeichnung2 }}</p>
          <p class="text-base text-light" *ngIf="bewelf?.bezeichnung3?.length">{{ bewelf?.bezeichnung3 }}</p>
        </div>
        <p class="text-base font-semibold mt-1 mb-1" *ngIf="!bewelf?.bezeichnung1 && bewelf?.zeilentext?.length">{{ bewelf?.zeilentext }}</p>
        <span class="color-tertiary" *ngIf="artehBean">
          {{ (artehBean?.maxRetourmenge || 0) | number:'1.0-3' }} {{ artehBean.arteh  }} {{ '#DELIVERY_NOTE.RETOUR.LIST_ITEM_QTY_SUFFIX' | translate }}
        </span>
    </div>

    <div class="c row end no-wrap center-v pl-3 border-box ">
        <div class="flex relative">
            <small class="absolute left-0 bottom-9 text-red-700 text-xs font-medium" *ngIf="hasError && !hasSplit">
                max. {{ artehBean?.maxRetourmenge ?? 0 | number: '1.2-3' }} {{ artehBean?.arteh }}
            </small>

            <small class="absolute left-0 bottom-9 text-warning-700 text-xs font-medium" *ngIf="hasSplit">
              Wird aufgeteilt
            </small>

            <ng-container>
                <soft-number-input placeholder="Menge"
                                   [value]="value?.menge"
                                   [class.error]="hasError"
                                   changeTrigger="blur"
                                   style="max-width: 80px"
                                   (valueChange)="onChange(value, menge.value, arteh?.value, menge)"
                                   #menge>
                </soft-number-input>

                <div style="width: 100px;" class="ml-2">
                    <soft-select placeholder="EH"
                                 [value]="artehBean"
                                 [class.hidden]="(bewelf?.artehBeans?.length || 0) <= 1"
                                 (valueChange)="onChange(value, menge.value, $event, menge)"
                                 #arteh>
                      @for (arteh of bewelf?.artehBeans ?? []; track arteh.id) {
                        <soft-select-option [value]="arteh">
                          {{ arteh?.arteh }}
                        </soft-select-option>
                      }
                    </soft-select>
                    @if ((bewelf?.artehBeans?.length || 0) <= 1) {
                      <span class="soft-title ml-3 color-light" style="width: 100px">{{ bewelf?.einheit || ''}}</span>
                    }
                </div>
                <small *ngIf="artehBean" class="absolute left-0 top-9 text-gray-600 text-xs">
                    max. {{ artehBean?.maxRetourmenge | number: '1.2-3' }} {{ artehBean?.arteh }}
                </small>
            </ng-container>
            <span class="detail-area mt-1 ml-1" (click)="onShowExtendedUnitDialog(value)">
                <i class="fa-regular fa-calculator"></i>
            </span>
        </div>
    </div>
</div>
