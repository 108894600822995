import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  SOFTLINE_SERVICE_UUID, StoreBuilder,
  SubscriptionStore
} from '@softline/core';
import {Umsatzstatistik} from '../types/umsatzstatistik';
import {UmsatzstatistikQuery} from '../types/umsatzstatistik.query';
import {lastValueFrom} from 'rxjs';
import {UmsatzstatistikService} from '../services/umsatzstatistik-service';
import {SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG} from '../umsatzstatistik.shared';
import {WwsUmsatzstatistikVkforgStore} from './vkforg.store';

export interface State {
  loading: boolean;
  umsatzstatistik: Umsatzstatistik | null;
}

const getters = {
  getUmsatzstatistik: createGetter<State, Umsatzstatistik, string | undefined | void>('wws/umsatzstatistik/getters/getUmsatzstatistik'),
  loading: createGetter<State, boolean, void>('wws/umsatzstatistik/getters/loading'),
  ...SubscriptionStore.getters,
  ...ActionStore.getters
}

const mutations = {
  setUmsatzstatistik: createMutation<State, Umsatzstatistik>('wws/umsatzstatistik/mutations/setUmsatzstatistik'),
  patch: createMutation<State, Partial<State>>('wws/umsatzstatistik/mutations/patch'),
  ...SubscriptionStore.mutations,
  ...ActionStore.mutations,
}

const actions = {
  load: createAction<State, { query: UmsatzstatistikQuery, token?: string, key?: string }, Umsatzstatistik>('wws/umsatzstatistik/actions/load'),
  ...SubscriptionStore.actions,
}

const umsatzstatistikCustomStore: CustomStore<State, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      loading: false,
      umsatzstatistik: null
    },
    getters: [
      select(getters.getUmsatzstatistik, ({ state, params }) =>
        params ? state[params] : state.umsatzstatistik
      ),
      select(getters.loading, ({ state }) => state.loading),
    ],
    mutations: [
      mutate(mutations.setUmsatzstatistik, ({ state, params }) => {
        return { ...state, umsatzstatistik: params };
      }),
      mutate(mutations.patch, ({ state, params }) => {
        return { ...state, ...params };
      }),
    ],
    actions: [
      on(actions.load, async ({ params, featureName, injector, dispatch, commit }) => {
        const service =  injector.get(UmsatzstatistikService);
        const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        const filialen = await dispatch(SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG, WwsUmsatzstatistikVkforgStore.actions.loadItemsOnce);

        const {
          ebene,
          umsatzart,
          aufgliederung,
          filiale,
          vondatum,
          bisdatum,
          ...zusaetzlicheFilter
        } = params.query;

        const query = {
          ebene: params.query.ebene?.key ?? params.query.ebene.umsatzebene,
          umsatzart: params.query.umsatzart.umsatzart,
          aufgliederung: params.query?.aufgliederung?.name,
          filialen: params.query.filiale !== null ? [params.query.filiale] : filialen,
          vondat: params.query.vondatum,
          bisdat: params.query.bisdatum,
          menge: 0,
          ...zusaetzlicheFilter
        }

        if (!params?.key)
          commit(featureName, mutations.patch, { loading: true });

        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.loadUmsatzstatistik(query),
          featureName,
          commit,
          token
        );

        const umsatzstatistik = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            actions.load.name,
            token
          )
        );

        if (params?.key) {
          commit(featureName, mutations.patch, { [params.key]: umsatzstatistik });
        } else {
          commit(featureName, mutations.patch, { umsatzstatistik, loading: false });
        }

        return umsatzstatistik;
      }),
    ]
  }
}

export const WwsUmsatzstatistikStore = new StoreBuilder(umsatzstatistikCustomStore)
  .add(SubscriptionStore)
  .add(ActionStore)
  .value
