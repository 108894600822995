import { Injectable } from '@angular/core';
import { DateService, Store } from '@softline/core';
import * as NfcReaderStore from '../nfc-reader.store';
import { BehaviorSubject } from 'rxjs';
import { NfcReaderService } from './nfc-reader.service';
import { NfcReadResult } from '../types/nfc-read-result';
import {SOFTLINE_FEATURE_NFC_READER, SOFTLINE_SETTINGS_NFC_READER} from "../nfc-reader.shared";
import {SOFTLINE_FEATURE_SETTINGS} from '../../application.shared';
import {NfcReaderSettings} from '../types/nfc-reader-settings';
import { SettingsStore } from '@softline/application';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable()
export class InputNfcReaderService extends NfcReaderService {

  private active$ = new BehaviorSubject(true);
  private registered = false;

  override available = true;

  private readValue: string = '';
  private readStarted: boolean = false;
  private listener = async (event: KeyboardEvent) => {
    if(event.key ==='Control' || event.key ==='Shift')
      return;
    let readCompleted = false
    if(event.key === '}')
      this.readStarted = true;
    if (this.readStarted && event.key !== '{') {
      if(event.key !== 'Alt' && event.key !== 'AltRight')
        this.readValue += event.key
    }
    else if (this.readStarted && this.readValue) {
      readCompleted = true;
      const read: NfcReadResult = {
        type: 'read',
        serialNumber: this.readValue.replace('}', ''),
        records: [],
        timestamp: this.dateService.now()
      };
      this.store.commit(SOFTLINE_FEATURE_NFC_READER, NfcReaderStore.mutations.add, read);
      this.readValue = '';
      this.readStarted = false;
    } else
      this.readStarted = false;

    if(this.readStarted || readCompleted) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  constructor(private store: Store, private dateService: DateService) {
    super();
  }

  override async init(): Promise<void> {
    this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<NfcReaderSettings>(),
        SOFTLINE_SETTINGS_NFC_READER
      )
      .pipe(
        takeUntilDestroyed(),
      )
      .subscribe(async (o) => {
        if(!o || !this) {
          try {
            console.debug('[BrowserNfcReaderService] Somehow the settings are undefined or the closure is not the BrowserNfcReaderService');
            console.debug('[BrowserNfcReaderService] Current o:', o, 'Current this:', this, this?.constructor?.name)
          }
          catch (e) { }
          return;
        }

        if(o.enabled)
          await this.activate();
        else if (!o.enabled)
          await this.deactivate();
      });
  }

  override async activate(): Promise<void> {
    console.log('activate nfc service:', this.registered);
    if(!this.registered) {
      console.log('activating input nfc service....')
      document.addEventListener('keydown', this.listener, false);
      this.registered = true;
    }
  }

  override async deactivate(): Promise<void> {
    if(this.registered) {
      document.removeEventListener('keydown', this.listener, false);
      this.registered = false;
    }
  }
}
