import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { CheckOption } from '../check-group/check-group';
import { CommonModule } from '@angular/common';
import { HasFocusDirective } from '../../../directives/has-focus.directive';

let uniqueIndex = 0;

@Component({
  selector: 'soft-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [CommonModule, HasFocusDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: CheckOption,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends CheckOption
  implements OnInit, OnDestroy, ControlValueAccessor
{
  labelName = `soft-checkbox-${++uniqueIndex}`;

  private _checked: boolean = false;
  private _value: any;
  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  parentReadonly = false;

  @Input() trueValue: any = true;
  @Input() falseValue: any = false;
  @Input() labelPosition: 'before' | 'after' = 'after';

  get value(): any {
    return this._value;
  }
  @Input()
  set value(value: any) {
    this.setValue(value, true);
  }
  @Output() valueChange = new EventEmitter<any>();

  get checked(): boolean {
    return this._checked;
  }
  @Input()
  set checked(checked: boolean) {
    this.setChecked(checked, true);
  }
  @Output() checkedChange = new EventEmitter<any>();

  @Input() readonly = false;
  @Input() disabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!this._checked) {
      this._value = this.falseValue;
      this._checked = equals(this.value, this.trueValue);
    }
  }

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
    this._checked = equals(this.value, this.trueValue);
    this.checkedChange.emit(this._checked);
    this.valueChange.emit(this._value);
  }

  setValue(value: any, preventEmit: boolean = false): void {
    const previousChecked = this._checked;

    this._value = value;
    this._checked = equals(value, this.trueValue);
    if (previousChecked !== this._checked) {
      if (!preventEmit) {
        this.valueChange.emit(this._value);
        this.checkedChange.emit(this._checked);
      }
      this.onChange(this._value);
      this.onTouch();
    }
  }

  setChecked(checked: boolean, preventEmit: boolean = false): void {
    const previousChecked = this._checked;

    this._checked = checked;
    this._value = checked ? this.trueValue : this.falseValue;
    if (previousChecked !== this._checked) {
      if (!preventEmit) {
        this.checkedChange.emit(this._checked);
        this.valueChange.emit(this._value);
      }
      this.onChange(this._value);
      this.onTouch();
    }
  }

  setReadonlyState(readonly: boolean): void {
    this.parentReadonly = readonly;
  }
}
