import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

export interface SegmentedControlConfiguration {
  leadingPadding: string;
  trailingPadding: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-segemented-control',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './segemented-control.component.html',
  styleUrls: ['./segemented-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentedControlComponent implements OnInit {

  @Input() segments: { value: any; label: string; hasEntries?: boolean }[] = [];
  @Input() selected?: string | null;
  @Input() config: SegmentedControlConfiguration = { leadingPadding: '0.125rem', trailingPadding: '0.15rem' };
  @Output() segmentChange = new EventEmitter<{ value: any; label: string }>();

  get activeIndicatorStyle(): string {
    const width = 1 / (this.segments?.length ?? 0) * 100;
    const selectedIndex = this.segments.findIndex(o => o.value === this.selected);
    const percentage = selectedIndex === 0 ? 0 : ((selectedIndex + 1) / this.segments?.length) * 100;

    let padding = '';

    if (this.segments.length === 1) {
      padding = `+ ${this.config.leadingPadding}`;
    } else {
      padding = selectedIndex === 0 ? `+ ${this.config.leadingPadding}` : `- ${this.config.trailingPadding}`;
    }

    return `
      width: ${this.segments.length === 1 ? `calc(100% - calc(${this.config.leadingPadding} + ${this.config.trailingPadding})` : `${width.toFixed(2)}%`};
      transform: translateX(calc(${percentage}% ${padding}))
    `;
  }

  constructor() { }

  ngOnInit(): void {}

  onChangeSegment(segment: { value: any; label: string }): void {
    this.segmentChange.emit(segment);
  }
}
