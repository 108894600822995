<h2 class="text-xl font-semibold mb-2 pr-3 pl-3 pt-2 mb-2">{{ '#DELIVERY_NOTE.DIALOGS.ADD_ITEM.TITLE' | translate }}</h2>

<div class="c row no-wrap center-v mt-3 mb-3 pl-3 pr-3 border-box">
    <soft-input [placeholder]="'#DELIVERY_NOTE.DIALOGS.ADD_ITEM.SEARCH_PLACEHOLDER' | translate" (valueChange)="searchChanged(input.value ?? '')" #input></soft-input>
</div>

<div class="c column no-wrap border-box pl-3 pr-3 overflow-auto"
     [class.shrink]="selectedItem$ | async" #container
     [ngStyle]="{ minHeight: (selectedItem$ | async) ? 'calc(' + (initialContainerHeight) + 'px - 4rem)' : initialContainerHeight + 'px' }">
    <div class="c row center-v no-wrap border-box space-between pt-3 pb-3 pl-3 pr-3 separator-top"
         *ngFor="let item of itemsWithInfo$ | async; let last = last"
         (click)="selectOrUnselectItem(item)"
         [class.isSelected]="item?.info?.selected"
         [class.separator-bottom]="last">
        <div class="c column no-wrap">
            <span class="text-sm color-light">{{ item?.emballage }}</span>
            <strong>{{ item?.bezeichnung }}</strong>
        </div>

        <div class="flex items-end justify-center">
            <span class="soft-chip" *ngIf="item?.info?.added && (item?.info?.quantity ?? 0) > 0">{{ item?.info?.quantity }}</span>
            <i class="fa-regular fa-star text-accent-400" [class.invisible]="!item?.favorit"></i>
        </div>
    </div>
</div>

<div *ngIf="selectedItem$ | async as selectedItem" class="flex flex-col no-wrap pt-3 box-border shadow-top selected-area sm:ml-3 sm:mr-3">
    <form [formGroup]="form" class="c row center-v space-between no-wrap box-border pl-3 pr-3">
        <div class="c column no-wrap truncate">
            <span class="text-sm color-light">{{ selectedItem?.emballage }}</span>
            <strong class="truncate">{{ selectedItem?.bezeichnung }}</strong>
        </div>
        <div class="c row center-v end no-wrap">
            <soft-number-input style="max-width: 10rem; min-width: 2.5rem;" #quantityInput
                               [changeTrigger]="'input'"
                               [placeholder]="'#DELIVERY_NOTE.DIALOGS.ADD_ITEM.QTY_PLACEHOLDER' | translate"
                               [formControlName]="'selectedQuantityControl'"></soft-number-input>
            <span class="soft-title ml-3" style="min-width: 2rem">EH</span>
        </div>
    </form>
</div>

<div class="c row border-box no-wrap size-aware-padding">
    <button class="soft-button secondary custom-size" (click)="close(null)">
        {{ '#DELIVERY_NOTE.DIALOGS.ADD_ITEM.CANCEL' | translate }}
    </button>
    <button class="soft-button primary custom-size" [disabled]="!(selectedItem$ | async) || !(isFormQuantityValid$ | async)" (click)="selectAndClose()">
        {{ '#DELIVERY_NOTE.DIALOGS.ADD_ITEM.SUBMIT' | translate }}
    </button>
</div>

