import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[softDragDrop]',
  standalone: true,
})
export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<FileList>();
  @HostBinding('style.background-color') background = 'transparent';

  @HostListener('dragover', ['$event'])
  onDragover(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'rgba(112, 150, 193, 0.2)';
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'transparent';
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = 'transparent';
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) this.fileDropped.emit(files);
  }
}
