import {
  createAction, createGetter,
  createMutation,
  isDefined,
  LazyRemoteCollectionStore,
  mutate,
  on, select,
  StoreFeature,
} from '@softline/core';
import {ReturnNoteBewe, ReturnNote} from '../types/return-note';

export namespace ReturnNoteStore {
  export type State = LazyRemoteCollectionStore.State<ReturnNote>;
  const lazyRemoteStore = LazyRemoteCollectionStore.create<ReturnNote>();

  export const getters = {
    ...lazyRemoteStore.getters,
    fromLf: createGetter<State, ReturnNote | undefined, number>('lieferschein/retourschein: fromLf')
  };

  export const mutations = {
    ...lazyRemoteStore.mutations,
  };

  export const actions = {
      ...lazyRemoteStore.actions,
    upsertBewe: createAction<State, {id: string, bewe: ReturnNoteBewe}>('lieferschein/retourschein: upsertBewe'),
    deleteBewe: createAction<State, {id: string, bewe: ReturnNoteBewe}>('lieferschein/retourschein: deleteBewe'),
  };

  export const feature: StoreFeature<State> = {
    initialState: {
      ...lazyRemoteStore.feature.initialState,
    },
    getters: [
      ...lazyRemoteStore.feature.getters,
      select(getters.fromLf, ({get, featureName, params}) => {
        return get(featureName, getters.preparedAll).find(o => o.idlf === params);
      })
    ],
    mutations: [
      ...lazyRemoteStore.feature.mutations,
    ],
    actions: [
      ...lazyRemoteStore.feature.actions,
      on(actions.upsertBewe, async ({ state, params, commit, dispatch, get, featureName, injector}) => {
        const returnNote = get(featureName, getters.preparedEntity, params.id);
        if (!isDefined(returnNote))
          throw new Error(`ReturnStore: no return note for id: ${params.id}`);

        const bewes = [...returnNote.bewes];
        const index = bewes.findIndex(o => o.id === params.bewe.id);
        if (index === -1)
          bewes.push(params.bewe);
        else
          bewes.splice(index, 1, params.bewe);

        await dispatch(featureName, actions.preparePatch, {
          id: returnNote.id,
          changes: { bewes }
        });
      }),
      on(actions.deleteBewe, async ({ state, params, commit, dispatch, get, featureName, injector}) => {
        const returnNote = get(featureName, getters.preparedEntity, params.id);
        if (!isDefined(returnNote))
          throw new Error(`ReturnStore - delete bewe: no return note for id: ${params.id}`);

        const bewes = [...returnNote.bewes];
        const index = bewes.findIndex(o => o.id === params.bewe.id);
        if (index === -1)
          throw new Error(`ReturnStore - delete bewe: no bewe with id ${params.bewe.id}`);
        bewes.splice(index, 1);

        await dispatch(featureName, actions.preparePatch, {
          id: returnNote.id,
          changes: {bewes}
        });
      })
    ]
  };
}
