import {
  EnvironmentProviders,
  importProvidersFrom,
  Provider,
} from '@angular/core';
import { SoftappsCoreModule } from '@softapps/core';
import {
  accountContextProviders,
  connectionSettingsRoutes, contextSettingsProviders,
  contextStorageProviders,
  settingsProviders,
  settingsRoutes,
  userContextProviders,
  utilitiesProviders,
  vkforgContextProviders
} from '@softapps/allgemein/utils';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { loginProviders, loginRoutes } from '@softapps/allgemein/login';
import {
  passwordProviders,
  passwordRoutes,
} from '@softapps/allgemein/password';
import { moduleProviders, moduleRoutes } from '@softapps/allgemein/modules';
import { remoteConfigProviders } from '@softline/application';
import { SoftlineModuleConfig } from '../common.types';

export interface SoftappsCoreConfig {
  pdfViewer?: boolean;
  contextStorage?: boolean;
  contextSettings?: boolean;
  remoteConfig?: boolean;
  vkforgContext?: boolean;
  userContext?: boolean;
  accountContext?: boolean;
}

export function provideSoftappsCore(config?: SoftappsCoreConfig): SoftlineModuleConfig {
  const extraProviders = [] as (Provider | EnvironmentProviders)[];

  if (config?.userContext === true) {
    extraProviders.push(...userContextProviders);
  }

  if (config?.remoteConfig === true) {
    extraProviders.push(...remoteConfigProviders)
  }

  if (config?.contextStorage === true) {
    extraProviders.push(...contextStorageProviders)
  }

  if (config?.contextSettings === true) {
    extraProviders.push(...contextSettingsProviders)
  }

  if (config?.accountContext === true) {
    extraProviders.push(...accountContextProviders)
  }

  if (config?.pdfViewer === true) {
    extraProviders.push(...pdfViewerProviders)
  }

  if (config?.vkforgContext === true) {
    extraProviders.push(...vkforgContextProviders);
  }

  const providers = [
    importProvidersFrom(SoftappsCoreModule.forRoot()),
    ...utilitiesProviders,
    ...settingsProviders,
    ...loginProviders,
    ...passwordProviders,
    ...moduleProviders,
    ...extraProviders,
  ];

  const routes = {
    internal: [
      ...moduleRoutes,
      ...settingsRoutes,
    ],
    public: [
      ...loginRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes
    ],
  };

  return [providers, routes];
}
