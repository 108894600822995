import {CalculationData, CalculationStrategy} from '../calculation.strategy';
import {Dictionary} from '@softline/core';

export class KzAusgabeNCalculationStrategy implements CalculationStrategy {
  calculate(data: CalculationData): Dictionary<number | null> {
    if (data.inputArteh.id === data.baseArteh.id) {
      data.dictionary[data.inputArteh.id] = data.inputValue
    } else {
      data.dictionary[data.baseArteh.id] = data.inputValue / (data.baseArteh.faktor / data.inputArteh.faktor)
    }

    return data.dictionary
  }
}
