<h2 class="text-xl font-semibold mt-3">Erweiterte Einheiten-Eingabe</h2>
<span class="text-base opacity-50" *ngIf="beweLf">{{ beweLf?.bezeichnung1 }} (Artikelnummer: {{ beweLf?.artikelnummer }})</span>

<div class="c row end">
    <i class="fa-regular fa-arrows-rotate" [class.opacity-50]="!anyInputFocused"></i>
</div>

<form [formGroup]="formGroup" *ngIf="{ sum: sum$ | async } as result">
    <table *ngIf="artehs$ | async as artehBeans" class="w-full border-collapse border border-gray-300 mt-4">
        <thead>
        <tr class="h-10 bg-gray-900 text-white">
            <th class="border border-gray-200">{{ ausgabeEH }}</th>
            <th class="border border-gray-200">Einheit</th>
            <th class="border border-gray-200">Menge</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let arteh of artehBeans; trackBy:unitById" class="h-10" [class.calc]="arteh?.kzausgabe === 'N'">
            <td class="border border-gray-200 p-2 text-right">{{ arteh.faktor | number:'1.0-6' }}</td>
            <td class="border border-gray-200 p-2">{{ arteh.arteh }}</td>
            <td class="border border-gray-200 p-2">
                <input
                   inputmode="decimal"
                   type="tel"
                   min="0"
                   (focus)="anyInputFocused = true"
                   (blur)="anyInputFocused = false"
                   [formControlName]="arteh.id + ''"
                   placeholder="0"
                   class="w-full h-full border-none outline-none shadow-none text-right">
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="h-10">
            <td class="border border-gray-200 p-2">Summe</td>
            <td class="border border-gray-200 p-2">{{ ausgabeEH }}</td>
            <td class="border border-gray-200 p-2 text-right">
                {{ result?.sum | number:'1.2-2' }}
            </td>
        </tr>
        </tfoot>
    </table>

    <div class="c row border-box no-wrap mt-2 size-aware-padding">
        <button class="soft-button secondary custom-size" type="button" (click)="cancel()">
            {{ '#DELIVERY_NOTE.DIALOGS.COMMENT.CANCEL' | translate }}
        </button>
        <button class="soft-button primary custom-size" [disabled]="anyInputFocused" type="button" (click)="closeDialog(((!!smallestUnit?.maxRetourmenge && ((smallestUnit?.maxRetourmenge || 9999) < (result.sum || 0)) || false) ? (smallestUnit?.maxRetourmenge ?? 0) || result.sum : result.sum) ?? 0)">
            {{ '#DELIVERY_NOTE.DIALOGS.COMMENT.SAVE' | translate }}
        </button>
    </div>
</form>
