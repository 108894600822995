import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { serializeHtmlCharacterEntitiesFunction } from '../../../functions/html-character-entities.function';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_MODAL } from '../../../modal/modal.shared';
import * as ModalStore from '../../../modal/modal.store';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
  selector: 'soft-signature-input',
  standalone: true,
  imports: [CommonModule, UiCorePipesModule, I18nModule],
  templateUrl: './signature-input.component.html',
  styleUrls: ['./signature-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureInputComponent implements OnInit, ControlValueAccessor {
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value: Blob | null | undefined;
  @Output() valueChange: EventEmitter<Blob | null | undefined> =
    new EventEmitter<Blob | null | undefined>();

  @Input() @HostBinding('class.readonly') readonly = false;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: Blob | null): void {
    this.value = value;

    this.onTouch(this.value);
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  async openSignaturePad(): Promise<void> {
    if (this.readonly) return;
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.sign,
      { dismiss: true }
    );
    if (result === 'DISMISSED' || !result) {
      this.onTouch(this.value);
      return;
    }
    this.setValue(result);
  }

  async clearSignature(): Promise<void> {
    const removeConfirm = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      '#UI_CORE.COMPONENTS.SIGNATURE_INPUT.CLEAR_CONFIRM'
    );
    if (removeConfirm === 'YES') this.setValue(null);
    else this.onTouch(this.value);
  }
}
