<h2 class="text-xl font-semibold pt-2 mb-2">
  {{ '#DELIVERY_NOTE.DIALOGS.CLOSE.TILE_MAX_MENGE' | translate }}
</h2>

<div class="my-4">
  {{ '#DELIVERY_NOTE.DIALOGS.CLOSE.INFO_MAX_MENGE' | translate }}
</div>

<div class="flex flex-row w-full gap-4">
    <button class="soft-button soft-button-secondary rounded-sm w-full h-14" (click)="close(false)">
      {{ '#DELIVERY_NOTE.DIALOGS.CLOSE.CANCEL' | translate }}
    </button>
    <button class="soft-button soft-button-primary rounded-sm w-full h-14" (click)="close(true)">
        {{ ('#DELIVERY_NOTE.DIALOGS.CLOSE.CONTINUE' | translate) }}
    </button>
</div>
