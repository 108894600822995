<div>
    <h2 class="text-xl font-semibold mb-2 pr-3 pl-3 pt-2 mb-2">{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.TITLE' | translate }}</h2>

    <div class="separator-top separator-bottom pt-3 pb-3 border-box c row no-wrap space-between" *ngIf="showKilometerInput">
        <div class="c column pl-3 pr-3 border-box">
            <strong>{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.KILOMETERS_LABEL' | translate }}</strong>
            <!-- <span class="text-sm color-light">Zuletzt 123km</span> -->
        </div>
        <div class="c row no-wrap center-v pl-3 pr-3 border-box">
            <input type="tel" class="soft-input" #input (input)="kilometersChanged(+input.value)" [value]="(input$ | async)">
            <span class="soft-title ml-3" style="min-width: 2.5rem">{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.KILOMETERS_UNIT' | translate }}</span>
        </div>
    </div>

    <div class="mt-4 mb-4 pr-3 pl-3 c column border-box">
        <strong class="text-sm">{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.CLOSE_HEADLINE' | translate }}</strong>
        <p class="color-light">{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.CLOSE_TEXT' | translate }}</p>
    </div>

    <form [formGroup]="form" class="mt-4 mb-4 pr-3 pl-3 c column border-box">
        <strong class="text-sm mb-1">{{ '#DELIVERY_NOTE.DIALOGS.CLOSE.CUSTOMER_EMAIL' | translate }}</strong>
        <soft-input formControlName="email" placeholder="Kunden-Mail" [class.invalidMail]="form.value.email?.length > 0 && form.invalid"></soft-input>
    </form>
</div>

<div class="c row border-box no-wrap mt-2 size-aware-padding">
    <button class="soft-button secondary custom-size" (click)="saveAndClose()">
        {{ ('#DELIVERY_NOTE.DIALOGS.CLOSE.SAVE_AND_CANCEL' | translate) }}
    </button>
    <button class="soft-button primary custom-size" [disabled]="!hasUnterschrift || (form.value.email?.length > 0 && form.invalid)" (click)="closeDialog()">
        {{ '#DELIVERY_NOTE.DIALOGS.CLOSE.SUBMIT' | translate }}
    </button>
</div>
