import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateService, Store } from "@softline/core";
import { BelegStore, SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG_WIDGET } from "@softapps/wws/gesamtabfrage";
import { showRequestErrors } from "@softline/application";
import { I18nModule } from "@softline/ui-core";
import moment from "moment";

@Component({
  selector: 'soft-gesamtabfrage',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './gesamtabfrage.widget.html',
  styleUrls: ['./gesamtabfrage.widget.scss']
})
export class GesamtabfrageWidget implements OnInit {

  constructor(private store: Store, private dateService: DateService) { }

  async ngOnInit(): Promise<void> {
    try {
      const query = {
        belegart: {},
        from: moment(this.dateService.today()).startOf('month'),
        to: this.dateService.today(),
        neuerBeleg: true
      }
      await this.store.dispatch(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG_WIDGET,
        BelegStore.actions.query,
        {
          query,
          clear: true
        });
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }

}
