import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';

const ANIMATION_POS_OFFSET = 17;
const BASE_OFFSET = 100;

const POS_1 = 10;
const POS_2 = 20;
const POS_3 = 30;
const POS_4 = 40;
const POS_5 = 50;
const POS_6 = 60;

const MULTIPLIER_1_50 = 1.5;
const MULTIPLIER_2 = 2;
const MULTIPLIER_2_25 = 2.25;

const SCALE_0_80 = 0.8;
const SCALE_0_95 = 0.95;

const DIVIDER = 100;

@Component({
  selector: 'soft-pull-to-refresh-indicator',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  templateUrl: './pull-to-refresh-indicator.component.html',
  styleUrls: ['./pull-to-refresh-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({ transform: 'scale(0.1)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('0.2s ease-in', style({ transform: 'scale(0.1)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class PullToRefreshIndicatorComponent {
  @Input() dragDistance = 0;
  @Input() showSpinner = false;

  get getOpacity(): number {
    const position = this.dragDistance;

    if (position <= POS_1) return 0;
    else if (position > POS_1 && position < POS_5)
      return (position / DIVIDER) * MULTIPLIER_1_50;
    else if (position >= POS_5) return 1;
    else return 0;
  }

  get getTransform(): string {
    const pos = this.dragDistance - ANIMATION_POS_OFFSET;

    if (pos < POS_2) return `rotate(0) scale(0.7)`;
    else if (pos >= POS_2 && pos <= POS_3)
      return `rotate(0) scale(${
        (pos / DIVIDER) * MULTIPLIER_2_25 < SCALE_0_80
          ? SCALE_0_80
          : (pos / DIVIDER) * MULTIPLIER_2_25
      })`;
    else if (pos > POS_3 && pos < POS_4)
      return `rotate(0) scale(${
        (pos / DIVIDER) * MULTIPLIER_2 < SCALE_0_95
          ? SCALE_0_95
          : (pos / DIVIDER) * MULTIPLIER_2
      })`;
    else if (pos >= POS_4) return 'rotate(180deg) scale(1)';
    else return 'rotate(0)';
  }
}
