import { Component } from '@angular/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {CommonModule} from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-retour-image-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './retour-image-dialog.component.html',
  styleUrls: ['./retour-image-dialog.component.scss']
})
export class RetourImageDialogComponent implements Modal<File[] | null> {

  close!: (result: File[] | null) => void;

  registerCloseHandler(handler: (result: File[] | null) => void) {
    this.close = handler;
  }
}
