<div class="soft-card rounded-m p-3 c column mb-2"
     [class.barverkauf]="deliveryNote?.type?.type === 'BARVERKAUF'"
     [class.kreditrechnung]="deliveryNote?.type?.type === 'KREDITRECHNUNG'"
     *ngIf="deliveryNote">
    <div class="flex flex-1 items-center justify-between w-full">
        <div class="flex flex-col w-full">
            <span class="soft-overline">{{ deliveryNote?.belegnummer }} <ng-container *ngIf="deliveryNote.type?.bezeichnung">({{deliveryNote.type?.bezeichnung}})</ng-container><ng-container *ngIf="deliveryNote?.vkftour?.vkftour && deliveryNote?.vkftour?.bezeichnung"> - {{ deliveryNote?.vkftour?.vkftour }} / {{ deliveryNote?.vkftour?.bezeichnung }} ({{deliveryNote?.vkftour?.tourfolge}}) </ng-container></span>
            <div class="mb-1 flex items-center">
              <span style="font-weight: 700">{{ deliveryNote?.adresseBean?.kurzbez || '-' }}</span>
              <div *ngIf="deliveryNote | deliveryNoteChanged | async" [style]="{'min-width': '1rem'}" class="ml-4">
                <soft-chip class="bg-accent-500 text-xs">GEÄNDERT</soft-chip>
              </div>
            </div>
            <span class="text-sm color-light">
                {{ deliveryNote?.adresseBean?.plz }}&nbsp;{{ deliveryNote?.adresseBean?.ort }}
            </span>
        </div>

        <div class="flex items-center justify-end p-1 min-w-fit relative">
            <span class="opacity-50 text-sm font-medium min-w-full">{{ deliveryNote?.lieferzeit }} Uhr</span>
            <i class="fa-regular fa-chevron-right opacity-[15%] text-xl"></i>
        </div>
    </div>
</div>

