import {
  AfterContentInit,
  computed,
  contentChildren,
  Directive,
  effect,
  forwardRef,
  input,
  output, Signal,
  signal, WritableSignal
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioGroup, RadioOption } from '../radio-option';

@Directive({
  selector: 'soft-radio-group',
  standalone: true,
  exportAs: 'softRadioGroup',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true,
    },
    {
      provide: RadioGroup,
      useExisting: forwardRef(() => RadioGroupDirective),
    },
  ],
})
export class RadioGroupDirective<T>
  extends RadioGroup<T>
{

  options = contentChildren<RadioOption<T>>(
    forwardRef(() => RadioOption),
    { descendants: true }
  );
  optionValues = computed(() => this.options().map((option) => option.value()));

  valueInput = input<T | null>(null);
  value = computed<WritableSignal<T | null>>(() => {
    const optionValues = this.optionValues();
    return signal(this.valueInput() ?? optionValues[0] ?? null);
  });
  valueChange = output<any>();
  readonly = input<boolean>(false);

  constructor() {
    super();
  }

  writeValue(obj: any): void {
    this.value().set(obj);
  }

  override setValue(value: T | null): void {
    this.value().set(value);
    this.onChange(value);
    this.onTouch();
  }
}
