import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import {DateService, Store} from '@softline/core';
import {SOFTLINE_FEATURE_MODAL} from '../../modal/modal.shared';
import * as ModalStore from '../../modal/modal.store';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { L10nModule } from '../../l10n/l10n.module';
import { I18nModule } from '../../i18n/i18n.module';

@Component({
  selector: 'soft-date-navigator',
  standalone: true,
  templateUrl: './date-navigator.component.html',
  styleUrls: ['./date-navigator.component.scss'],
  imports: [CommonModule, IconComponent, I18nModule, L10nModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateNavigatorComponent implements OnInit {
  @Input() date: string = this.dateService.today();
  @Output() dateChange = new EventEmitter<string>();

  constructor(public dateService: DateService, private readonly store: Store) {}

  ngOnInit(): void {}

  onChange(date: string, days: number = 0): void {
    const m = moment(date).add(days, 'day');
    this.date = m.toISOString(true);
    this.dateChange.emit(m.toISOString(true));
  }

  async openDatePickerDialog(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.date,
      {
        value: this.date,
        dismiss: true,
      }
    );

    if (!result || result === 'DISMISSED') return;

    this.onChange(result);
  }
}
