import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {BeweLf} from '../../types/bewe-lf';
import {CommonModule} from '@angular/common';
import {ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from '@softline/ui-core';
import {SOFTLINE_FEATURE_DELIVERY_NOTE} from '../../delivery-note.shared';
import * as DeliveryNoteStore from '../../stores/delivery-notes.store';
import {DefaultCommentDialogComponent} from '../../dialogs/default-comment-dialog/default-comment-dialog.component';
import {
  BeweOptionDialogComponent,
  BottomSheetOption
} from '../../dialogs/bewe-option-dialog/bewe-option-dialog.component';
import {Store} from '@softline/core';

@Component({
  selector: 'lib-delivery-note-material-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './delivery-note-material-card.component.html',
  styleUrls: ['./delivery-note-material-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DeliveryNoteMaterialCardComponent implements OnInit {

  @Input() idKopfLf = 0;
  @Input() deliveryMaterial: BeweLf | undefined;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  async onChange(quantity: number): Promise<void> {
    if (!this.deliveryMaterial)
      return;

    await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNoteStore.actions.preparePatchBewe, {
      id: this.idKopfLf,
      bewe: {...this.deliveryMaterial, menge: quantity}
    });
  }

  async onEditBeweClick(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ selected?: BottomSheetOption }, { options: BottomSheetOption[]; itemName?: string }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_BOTTOM_SHEET',
        component: BeweOptionDialogComponent,
        data: {
          options: [{id: 'ADD_VERMERK', label: 'Vermerk bearbeiten'}],
        },
        dismiss: {
          backdrop: true,
          escape: true,
          button: true
        }
      }
    );
    if (result === 'DISMISSED')
      return;

    const vermerkResult = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string | null, { vermerk?: string | null; isItem?: boolean }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_COMMENT',
        component: DefaultCommentDialogComponent,
        data: { vermerk: this.deliveryMaterial?.vermerk },
        dismiss: {
          backdrop: true,
          button: true,
          escape: false
        }
      }
    );

    if (vermerkResult === 'DISMISSED' || vermerkResult === null)
      return;

    const bewe = {...this.deliveryMaterial, vermerk: vermerkResult};
    await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNoteStore.actions.preparePatchBewe, {
      id: this.idKopfLf,
      bewe
    });
  }

}
