import {
  createAction,
  createGetter,
  createMutation,
  DownloadStore,
  LazyRemoteCollectionStore,
  mutate,
  on,
  isDefined,
  select, SOFTLINE_FEATURE_DOWNLOAD,
  StoreFeature, base64Encode, SOFTLINE_SERVICE_UUID, KeyStore, ParamsStore, SubscriptionStore, ActionStore, PatchChange,
} from '@softline/core';
import {DeliveryNote} from '../types/delivery-note';
import {ArchiveStore, LabelType, SOFTLINE_API_FILE, SOFTLINE_FEATURE_ARCHIVE} from '@softline/application';
import { lastValueFrom, Observable } from 'rxjs';
import {ReturnNote} from '../types/return-note';
import {DeliveryNoteService} from '../services/delivery-note.service';
import {SOFTLINE_FEATURE_RETURN_NOTE} from '../delivery-note.shared';
import {ReturnNoteStore} from './return-note.store';
import {MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import { ExzessResult } from '../types/exzess-result';
import {BeweLf} from '../types/bewe-lf';

export type State = LazyRemoteCollectionStore.State<DeliveryNote> & { viewedDate: string };
const syncedRemoteCollectionStore = LazyRemoteCollectionStore.create<DeliveryNote>();

export const getters = {
    collection: syncedRemoteCollectionStore.getters,
    viewedDate: createGetter<State, string>('viewedDate')
};

export const mutations = {
    collection: syncedRemoteCollectionStore.mutations,
    setViewedDate: createMutation<State, string>('setViewedDate')
};

export const actions = {
    collection: syncedRemoteCollectionStore.actions,
    loadSignature: createAction<State, {id: number}>('loadSignature'),
    close: createAction<State, { note: DeliveryNote; kilometerstand: number }, void>('closeDeliveryOrMaterialNote'),
    getLieferscheinForScan: createAction<State, { labelType: LabelType | LabelType | null | undefined; code: string }, DeliveryNote>('getLieferscheinForScan'),
    recordGeolocation: createAction<State, { id: number; gpsbreite: number; gpslaenge: number }, DeliveryNote>('lieferscheinRecordGeolocation'),
    save: createAction<State, {id: number, token?: string}, DeliveryNote>('deliveryNote/save'),
    complete: createAction<State, {id: number; kilometerstand: number; email: string | undefined | null; token?: string}, DeliveryNote>('deliveryNote/complete'),
    exzess: createAction<State, { idlf: number; clear: boolean }, ExzessResult | undefined>('deliveryNote/exzess'),
    preparePatchBewe: createAction<State, {id: number, bewe: BeweLf}>('preparePatchBewe')
};

export const feature: StoreFeature<State> = {
    initialState: {
        ...syncedRemoteCollectionStore.feature.initialState,
        viewedDate: new Date().toISOString()
    },
    getters: [
        ...syncedRemoteCollectionStore.feature.getters,
        select(getters.viewedDate, ({ state }) => state.viewedDate)
    ],
    mutations: [
        ...syncedRemoteCollectionStore.feature.mutations,
        mutate(mutations.setViewedDate, ({ state, params: viewedDate }) => ({ ...state, viewedDate }))
    ],
    actions: [
        ...syncedRemoteCollectionStore.feature.actions,
        on(actions.getLieferscheinForScan, async ({ injector, params, commit, featureName }) => {
            const service = injector.get(DeliveryNoteService);
            const belegBean = await lastValueFrom(service.getIdForScan(params.labelType, params.code));
            const lieferschein = await lastValueFrom(service.getLieferschein(belegBean));
            commit(featureName, mutations.collection.addOrUpdate, lieferschein);
            return lieferschein;
        }),
        on(actions.loadSignature, async ({injector, params, commit, dispatch, get, featureName}) => {
          const note = get(featureName, getters.collection.entity, params.id);
          if (!isDefined(note?.signature) || isDefined(note.signatureBlob))
            return;

          const signature = await dispatch(SOFTLINE_FEATURE_DOWNLOAD, DownloadStore.actions.download, {
            location: { path: SOFTLINE_API_FILE, pathParams: {key: base64Encode(JSON.stringify(note.signature))}},
            filename: ''
          });

          commit(
              featureName,
              mutations.collection.patch,
              { id: note.id, changes: { signatureBlob: new File([signature.content], signature.filename) } }
          );
        }),
      on(actions.save, async ({injector, params, commit, state, featureName}) => {
        const service = injector.get(DeliveryNoteService);
        const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const deliveryNote = state.entities[params.id];
        const change = state.preparedChanges.find(o => o.action === 'patch' && o.id === params.id);
        if (!change || !deliveryNote)
          throw new Error('DeliveryNoteStore: No changes to save');

        const result = await ActionStore.handleActionState(
            service.save(deliveryNote, change),
            featureName,
            commit,
            actions.save.name,
            token
        );

        commit(featureName, mutations.collection.addOrUpdate, result);
        commit(featureName, mutations.collection.resetChange, result.id);

        return result;
      }),
      on(actions.complete, async ({injector, params, commit, dispatch, get, state, featureName}) => {
        const service = injector.get(DeliveryNoteService);
        const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const deliveryNote = state.entities[params?.id];
        const change = state.preparedChanges.find(o => o.action === 'patch' && o?.id === params?.id) as PatchChange<DeliveryNote>;
        if (!deliveryNote)
          throw new Error('DeliveryNoteStore: Can not find Delivery Note');

        const returnNote = get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, deliveryNote?.id);

        const result = await ActionStore.handleActionState(
            service.complete(
                deliveryNote,
                change,
                returnNote,
                { kilometerstand: params.kilometerstand, mail: params.email }
            ),
            featureName,
            commit,
            actions.save.name,
            token
        );

        if (change)
          commit(featureName, mutations.collection.resetChange, deliveryNote?.id);

        commit(featureName, mutations.collection.remove, deliveryNote);
        if(returnNote)
          commit(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.mutations.remove, returnNote);

        return result;
      }),
      on(actions.recordGeolocation, async ({ commit, injector, params, state, featureName}) => {
        const service = injector.get(DeliveryNoteService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const { id, ...coordinates } = params;
        const deliveryNote = state.entities[id];

        if (!deliveryNote)
          throw new Error('DeliveryNoteStore: Can not find Delivery Note');

        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            service.saveCoordinates(id, coordinates),
            featureName,
            commit,
            actions.save.name,
            token
          )
        );

        return result;
      }),
      on(actions.exzess, async ({ injector, params, featureName, commit }) => {
        const service = injector.get(DeliveryNoteService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const action$: Observable<ExzessResult | undefined> = params.clear ? service.clearExzess(params.idlf) : service.exzess(params.idlf);

        const result: ExzessResult | undefined = await lastValueFrom(
          ActionStore.handleObservableActionState(
            action$,
            featureName,
            commit,
            actions.save.name,
            token
          )
        );

        return result;
      }),
      on(actions.preparePatchBewe, async ({ dispatch, injector, params, featureName, commit , state, get}) => {
        const deliveryNote = get(featureName, getters.collection.preparedEntity, params.id);
        if (!isDefined(deliveryNote))
          throw new Error(`DeliveryNoteStore: no delivery note for id: ${params.id}`);

        const bewelfs = [...deliveryNote.bewelfs ?? []];
        const index = bewelfs.findIndex(o => o.id === params.bewe.id);
        if (index === -1)
          throw new Error(`DeliveryNoteStore: no bewe for id: ${params.id}/${params.bewe.id}`);
        bewelfs.splice(index, 1, params.bewe);

        await dispatch(featureName, actions.collection.preparePatch, {
          id: params.id,
          changes: { bewelfs }
        });
      })
    ]
};
