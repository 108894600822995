<h4 class="text-xl font-semibold mt-3 ml-3">{{ '#DELIVERY_NOTE.DIALOGS.BOTTOM_SHEET.TITLE' | translate }}</h4>

<p class="text-sm mb-1 mt-1 ml-3 mr-3 border-box color-light" *ngIf="customText && !itemName">
    {{ customText }}
</p>

<p class="text-sm mb-1 mt-1 ml-3 mr-3 border-box color-light" *ngIf="itemName">
   {{ '#DELIVERY_NOTE.DIALOGS.BOTTOM_SHEET.TEXT_1' | translate }} <strong>{{ itemName }}</strong> {{ '#DELIVERY_NOTE.DIALOGS.BOTTOM_SHEET.TEXT_2' | translate }}
</p>

<div class="c column mt-2">
    <div *ngFor="let option of options; let first = first"
         [class.separator-top]="!first"
         (click)="close({ selected: option })"
         class="c pt-4 pb-4 row border-box center-v hover:bg-gray-100 cursor-pointer">
        <i class="mr-3" [ngClass]="option?.icon"></i>
        <span class="mr-3">{{ option?.label }}</span>
    </div>
</div>
