import { SoftlineModuleRoutes } from '../lib/common.types';
import { ResolveData, Routes } from '@angular/router';
import { AuthenticationGuard } from '@softline/auth';

export function createApplicationRoutes(...routes: SoftlineModuleRoutes[]): Routes {
  let resolve: ResolveData = {};

  for (const route of routes) {
    resolve = {
      ...resolve,
      ...(route.internalResolve ?? {})
    }
  }

  return [
    {
      path: '',
      loadComponent: () => import('@softapps/core').then((o) => o.ShellComponent),
      children: routes.flatMap(o => o.internal),
      canActivate: [AuthenticationGuard],
      resolve
    },
    {
      path: '',
      loadComponent: () =>
        import('@softapps/core').then((o) => o.PublicShellComponent),
      children: routes.flatMap(o => o.public),
    },
  ];
}
