import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mengenEingabeFormat',
  pure: true,
  standalone: true
})
export class MengenEingabeFormatPipe implements PipeTransform {

  protected readonly locale = inject(LOCALE_ID);

  numberFormatOptions(maxFractionDigits: number): Intl.NumberFormatOptions {
    return  {
      minimumFractionDigits: 0,
      maximumFractionDigits: maxFractionDigits,
      useGrouping: false,
    }
  }

  transform(
    value: number | string | null | undefined,
    maxFractionDigits: number,
    locale = this.locale
  ): string {
    if (!value)
      return '';

    return new Intl.NumberFormat(locale, this.numberFormatOptions(maxFractionDigits)).format(Number(value));
  }
}
