import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[softHasFocus]',
  exportAs: 'softHasFocus',
  standalone: true,
})
export class HasFocusDirective {
  hasFocus: boolean = false;
  constructor() {}

  @HostListener('focus', ['$event']) onFocus(e: HTMLElement): void {
    this.hasFocus = true;
  }
  @HostListener('blur', ['$event']) onblur(e: HTMLElement): void {
    this.hasFocus = false;
  }
}
