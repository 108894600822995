<div class="soft-top-container">
  <soft-date-navigator [date]="(viewedDate$ | async) ?? ''" (dateChange)="dateChanged($event)"></soft-date-navigator>
</div>

<ng-container *ngIf="noteTypeSegments$ | async as segments">
    <ng-container *ngIf="segments && (segments?.length ?? 0) > 0">
        <soft-input
                (valueChange)="onSearchInputChange($event ?? '')"
                class="mt-3"
                [placeholder]="(activeSegment$ | async) === 'MATERIAL' ? '#DELIVERY_NOTE.OVERVIEW.SEARCH.PLACEHOLDER_MAT' : '#DELIVERY_NOTE.OVERVIEW.SEARCH.PLACEHOLDER_LIEF'">
        </soft-input>

        <lib-segemented-control
                [selected]="activeSegment$ | async"
                [segments]="segments"
                (segmentChange)="onChangeListType($event)">
        </lib-segemented-control>
    </ng-container>
</ng-container>

<div class="c row mt-3" style="position: relative" [@.disabled]="loadingChanged$ | async">
    <ng-container *ngIf="!(loading$ | async); else loadingTemplate">

        <ng-container *ngIf="((noteTypeSegments$ | async)?.length ?? 0) > 0; else noDataTemplate">
            <!-- Lieferscheine (slide von links) -->
            <div @slideInOutLeft class="c row" style="position: absolute; will-change: transform;" *ngIf="(activeSegment$ | async) !== 'MATERIAL'">
                <ng-container *ngIf="filteredNotes$ | async as filteredNotes">
                    <ng-container *ngIf="filteredNotes.length < 1; else notesList">
                        <div class="placeholder-wrapper">
                            <span class="color-light">{{ '#DELIVERY_NOTE.OVERVIEW.EMPTY.LIEFERSCHEINE' | translate }}</span>
                        </div>
                    </ng-container>
                    <ng-template #notesList>
                        <lib-delivery-note-card (click)="showDetails(deliveryNote.id)"
                                                [deliveryNote]="deliveryNote"
                                                *ngFor="let deliveryNote of filteredNotes | sort:'lieferzeit':'ASC'">

                        </lib-delivery-note-card>
                    </ng-template>
                </ng-container>
            </div>

            <!-- Materialscheine (slide von rechts) -->
            <div @slideInOutRight class="c row" style="position: absolute; will-change: transform;" *ngIf="(activeSegment$ | async) === 'MATERIAL'">
                <ng-container *ngIf="filteredNotes$ | async as filteredNotes">
                    <ng-container *ngIf="filteredNotes.length < 1; else notesList">
                        <div class="placeholder-wrapper">
                            <span class="color-light">{{ '#DELIVERY_NOTE.OVERVIEW.EMPTY.MATERIALSCHEINE' | translate }}</span>
                        </div>
                    </ng-container>
                    <ng-template #notesList>
                        <lib-delivery-note-card (click)="showDetails(deliveryNote.id)"
                                                [deliveryNote]="deliveryNote"
                                                *ngFor="let deliveryNote of filteredNotes | sort:'lieferzeit':'ASC'"></lib-delivery-note-card>
                    </ng-template>
                </ng-container>
            </div>
        </ng-container>


        <ng-template #noDataTemplate>
            <div class="c row" *ngIf="((noteTypeSegments$ | async)?.length ?? 0) < 1">
                <div class="placeholder-wrapper flex w-full text-center items-center justify-center">
                    <span class="color-light">{{ '#DELIVERY_NOTE.OVERVIEW.EMPTY.NO_DATA' | translate }}</span>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>

<ng-template #loadingTemplate>
    <div class="div c row center loading-wrapper">
        <soft-loading-spinner class="spin"></soft-loading-spinner>
    </div>
</ng-template>
