<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['artuntergrp']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['artuntergrpsprbezeichnung']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="">{{item['arthauptgrpsprbezeichnung']}}</span>
  </div>
</div>
