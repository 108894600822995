import {combineLatestWith, map, switchMap} from 'rxjs/operators';
import {Pipe} from '@angular/core';
import {Store} from '@softline/core';
import {Observable} from 'rxjs';
import {DeliveryNote} from '../types/delivery-note';
import {SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE} from '../delivery-note.shared';
import {DeliveryNotesStore} from '../stores';
import {ReturnNoteStore} from '../stores/return-note.store';

@Pipe({
  name: 'deliveryNoteChanged',
  standalone: true,
  pure: true
})
export class DeliveryNoteChangedPipe {

  constructor(private store: Store) {}

  transform(value: DeliveryNote): Observable<boolean> {
    return this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.fromLf, value.id)
      .pipe(
        switchMap(o => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.change, o?.id)),
        combineLatestWith(this.store.observe(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.getters.collection.change, value.id)),
        map(([o, p]) => !!o || !!p)
      );
  }
}
