<soft-page>
  <soft-page-header #pageHeader>
    @if(!smBreakpoint() && !!masterDetail?.selected()) {
      <ng-container *ngTemplateOutlet="masterTemplate() ?? null; context: {data: masterDetail?.selected()}"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="header()?.template() ?? null"></ng-container>
    }
  </soft-page-header>
  <soft-remote-data-container [data]="data()"
                              [loadingState]="loadingState()"
                              [loadingError]="loadingError()">
    <div [style.maxHeight.px]="masterDetailMaxHeightPx()" class="overflow-hidden">
      <soft-master-detail [data]="data()"
                          [style.maxHeight.px]="masterDetailMaxHeightPx()"
                          [masterTemplate]="masterTemplate()"
                          [detailTemplate]="detailTemplate()"
                          [detailPlaceholder]="detailPlaceholder()"
                          [detailHeaderVisible]="false"
                          (selectedChange)="selectedChange.emit($event)"
                          #masterDetail>
      </soft-master-detail>
    </div>
  </soft-remote-data-container>
  <ng-content></ng-content>
</soft-page>
