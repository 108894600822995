import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '../../types/address';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
  selector: 'app-delivery-note-address-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './delivery-note-address-card.component.html',
  styleUrls: ['./delivery-note-address-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteAddressCardComponent {

  private GOOGLE_LINK = 'https://www.google.com/maps/search/?api=1&query=';

  @Input() address?: Address;
  @Input() headerText?: string;

  generateMapsLink(address: Address | undefined): string {
    if (!address)
      return '';

    let link = this.GOOGLE_LINK;

    if (address?.geodaten?.gpsbreite && address?.geodaten?.gpslaenge) {
      link += address?.geodaten?.gpsbreite + ',' + address?.geodaten?.gpslaenge;
      return encodeURI(link);
    }

    let destination = '';

    if (address?.strasse)
      destination += encodeURIComponent(address?.strasse);
    if (address?.plz)
      destination += encodeURIComponent(destination?.length > 0 ? ', ' + address?.plz : address?.plz);
    if (address?.ort)
      destination += encodeURIComponent(destination?.length > 0 ? ' ' + address?.ort : address?.ort);

    return `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  }
}
