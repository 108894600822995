import { Injectable } from "@angular/core";
import {
  NfcReaderService,
  NfcReaderStore,
  SOFTLINE_FEATURE_NFC_READER,
  SOFTLINE_FEATURE_SETTINGS, SOFTLINE_SETTINGS_NFC_READER, SettingsStore, NfcReaderSettings, AudioService
} from "@softline/application";
import { DateService, Store } from "@softline/core";

declare const NDEFReader;

@Injectable()
export class BrowserNfcReaderService extends NfcReaderService{

  private ndef: any;
  private enabled = false;

  reading = ({message, serialNumber}) => {
    this.store.commit(SOFTLINE_FEATURE_NFC_READER, NfcReaderStore.mutations.add, {
      type: 'read',
      serialNumber,
      records: message,
      timestamp: this.dateService.now()
    })
    this.audioService.play('beep');
  }

  readingError = (error) => {
    this.store.commit(SOFTLINE_FEATURE_NFC_READER, NfcReaderStore.mutations.add, {
      type: 'error',
      error,
      timestamp: this.dateService.now()
    });

    this.audioService.play('beep-beep');
  }


  constructor(private store: Store,
              private dateService: DateService,
              private audioService: AudioService) {
    super()
  }

  override async init(): Promise<any> {
    this.available = 'NDEFReader' in window;
    if(!this.available)
      return;

    this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<NfcReaderSettings>(),
        SOFTLINE_SETTINGS_NFC_READER
      )
      .subscribe(async (o) => {
        if(!o || !this) {
          try {
            console.debug('[BrowserNfcReaderService] Somehow the settings are undefined or the closure is not the BrowserNfcReaderService');
            console.debug('[BrowserNfcReaderService] Current o:', o, 'Current this:', this, this?.constructor?.name)
          }
          catch (e) { }
          return;
        }

        if(o.enabled && !this.enabled)
          await this.activate();
        else if (!o.enabled && this.enabled)
          await this.deactivate();
      });
  }

  override async activate(): Promise<any> {
    if(!this.available)
      return;
    this.enabled = true;

    try {
      if(!this.ndef) {
        this.ndef = new NDEFReader()
        await this.ndef.scan();
      }
      this.ndef.addEventListener('reading', this.reading);
      this.ndef.addEventListener('readingerror', this.readingError);
    }
    catch (e) {
      console.error('[BrowserNfcReaderService] Failed to activate NDEFReader', e)
    }
  }

  override async deactivate(): Promise<any> {
    this.enabled = false;
    this.ndef.removeEventListener('reading', this.reading);
    this.ndef.removeEventListener('readingerror', this.readingError);
  }
}
