import { Injectable } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_CHECKLISTEN,
  SOFTLINE_FEATURE_CHECKPUNKTE,
} from '../checkliste.shared';
import { ChecklistenStore } from '../store/checklisten.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { Checkpunkt } from '../types/checkpunkt';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import { SelectChecklisteDialogComponent } from '../dialogs/select-checkliste-dialog/select-checkliste-dialog.component';
import { showRequestErrors } from '@softline/application';
import { CreateChecklisteDialogComponent } from '../dialogs/create-checkliste-dialog/create-checkliste-dialog.component';
import { CheckpunkteStore } from '../store/checkpunkte.store';
import { Checkliste } from '../types/checkliste';

@Injectable({ providedIn: 'root' })
export class ChecklistenService {
  readonly checklisten$ = this.store.observe(
    SOFTLINE_FEATURE_CHECKLISTEN,
    ChecklistenStore.getters.all
  );

  readonly checklisten = this.store.signal(
    SOFTLINE_FEATURE_CHECKLISTEN,
    ChecklistenStore.getters.all
  );

  readonly hasCheckliste = toSignal(
    this.checklisten$.pipe(map((o) => o.length > 0))
  );

  constructor(private readonly store: Store) {}

  async loadMany(): Promise<Checkliste[]> {
    return await this.store.dispatch(
      SOFTLINE_FEATURE_CHECKLISTEN,
      ChecklistenStore.actions.loadMany
    );
  }

  async createCheckpunkt(params: {
    idaufgabe: number;
    checkpunkt: string;
  }): Promise<Checkpunkt> {
    return await this.store.dispatch(
      SOFTLINE_FEATURE_CHECKPUNKTE,
      CheckpunkteStore.actions.create,
      {
        entity: { checkpunkt: params.checkpunkt },
        pathParams: { idaufgabe: params.idaufgabe },
      }
    );
  }

  async createCheckliste(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ betreff: string } | null, object>(),
      {
        id: 'SOFTAPPS_ALLGEMEIN_CHECKLISTEN_ADD',
        dismiss: true,
        data: {},
        component: CreateChecklisteDialogComponent,
      }
    );

    if (!result || result === 'DISMISSED') return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_CHECKLISTEN,
        ChecklistenStore.actions.create,
        {
          entity: {
            betreff: result.betreff,
          },
        }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#CHECKLISTEN.DIALOGS.CREATE.SUCCESS'
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async addToCheckliste(checkpunkt: Checkpunkt): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'SOFTAPPS_ALLGEMEIN_CHECKLISTEN_ADD',
        dismiss: true,
        data: { checkpunkt },
        component: SelectChecklisteDialogComponent,
      }
    );

    if (!result || result === 'DISMISSED') return;

    try {
      // store.dispatch goes here (add to checkliste)
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
