import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Modal, NumberInputComponent, UiCoreModule} from '@softline/ui-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Emballage } from '../../types/emballage';
import { map } from 'rxjs/operators';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RemoteObjectStore, Store} from '@softline/core';
import {Stammdaten} from '../../types/stammdaten';
import {SOFTLINE_FEATURE_MASTER_DATA} from '@softline/application';
import {CommonModule} from '@angular/common';

type EmballageWithInfo = Emballage & { info: { selected: boolean; added: boolean; quantity: number; } };

@Component({
  selector: 'app-deliver-note-add-bewe-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './add-bewe-dialog.component.html',
  styleUrls: ['./add-bewe-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBeweDialogComponent implements OnInit, Modal<{ emballage: Emballage, quantity: number } | null>, AfterViewInit {

  @ViewChild('container') itemContainerElement?: ElementRef<HTMLElement>;
  @ViewChild('quantityInput', { read: ElementRef, static: false }) quantityInput?: ElementRef<HTMLElement>;

  initialContainerHeight = 0;

  private addedItemMap = new Map<string, number>();

  close!: (result: { emballage: Emballage, quantity: number } | null) => void;

  items$: Observable<Emballage[]> = this.store
    .observe<any, undefined, Stammdaten>(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.getters.data)
    .pipe(
      map(({ emballageModule }) => emballageModule.filter(o => !this.excludedIds.includes(o.id)))
    );

  excludedIds: number[] = [];

  itemsWithInfo$!: Observable<EmballageWithInfo[]>;

  readonly searchText$ = new BehaviorSubject('');
  readonly selectedItem$ = new BehaviorSubject<Emballage | undefined>(undefined);

  readonly form = new UntypedFormGroup({
    selectedQuantityControl: new UntypedFormControl(null, { updateOn: 'change', validators: [Validators.required, Validators.min(1)] })
  }, { updateOn: 'change' });

  readonly isFormQuantityValid$ = this.form.valueChanges.pipe(
      map(o => !!o && o.selectedQuantityControl && o.selectedQuantityControl > 0)
  );

  get formQuantityValue(): number | undefined {
    return this.form.value?.selectedQuantityControl;
  }

  constructor(public store: Store) {
    this.form.valueChanges.subscribe(o => console.log(o));
  }

  ngOnInit(): void {
    if (!this.items$)
      return;

    this.itemsWithInfo$ = combineLatest([this.items$, this.selectedItem$, this.searchText$]).pipe(
        map(([emballagen, selectedItem, searchText]) => emballagen
            ?.filter(o => searchText && searchText?.length > 0
                ? o?.bezeichnung?.toLocaleLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                  o?.emballage?.toLocaleLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                  o?.id?.toString()?.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0
                : true
            )
            ?.map((item: Emballage) => ({
              ...item,
              info: {
                selected: (selectedItem && selectedItem?.id === item.id) ?? false,
                added: this.addedItemMap.has(item.emballage) ?? false,
                quantity: this.addedItemMap.has(item.emballage) ? (this.addedItemMap.get(item.emballage) ?? 0) : 0
              }
            }))

        )
    );
  }

  ngAfterViewInit() {
    if (this.itemContainerElement) {
      // Sets the initial height of the container with all items in the list
      // This is used to prevent the flickering when the height of the modal changes
      // We do not allow the list be smaller than the initial height even if only one item is filtered
      this.initialContainerHeight = this.itemContainerElement?.nativeElement.offsetHeight;
    }
  }

  registerCloseHandler(handler: (result: any) => void) {
    this.close = handler;
  }

  selectOrUnselectItem({ info, ...emballage }: EmballageWithInfo): void {
    if (this.selectedItem$.value && this.selectedItem$.value?.id === emballage.id)
      this.selectedItem$.next(undefined);
    else
      this.selectedItem$.next(emballage);

    // Focus input field
    setTimeout(() => {
      if (this.selectedItem$.value)
        (this.quantityInput?.nativeElement?.children[0] as HTMLInputElement)?.focus();
    }, 1);
  }

  selectAndClose() {
    if (!this.selectedItem$.value || !this.formQuantityValue || !(this.formQuantityValue > 0))
      this.close(null);
    else
      this.close({ emballage: this.selectedItem$.value, quantity: this.formQuantityValue });
  }

  searchChanged(value: string): void {
    this.searchText$.next(value);
  }
}
