import {UpdateQueryStrategyFactory} from './query-update.strategy';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';

export const vertreterQueryUpdateStrategy: UpdateQueryStrategyFactory<string> = () => {
  return {
    umsatzebene: 'Vertreter',
    updateFn: async (key, query) => {
      query.vertreter = { vertretercode: key } as any;
      return query;
    },
    removeFn: async (query: FrontendUmsatzstatistikQuery)  => {
      delete query['vertreter']
      return query;
    }
  }
}
