import {UpdateQueryStrategyFactory} from './query-update.strategy';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';

export const untergruppeQueryUpdateStrategy: UpdateQueryStrategyFactory<string> = () => {
  return {
    umsatzebene: 'Untergruppe',
    updateFn: async (untergruppe, query) => {
      query.untergruppe = { artuntergrp: untergruppe } as any;
      return query;
    },
    removeFn: async (query: FrontendUmsatzstatistikQuery)  => {
      delete query['untergruppe']
      return query;
    }
  }
}
