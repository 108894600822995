import { Component, computed, Injector, OnDestroy, OnInit } from "@angular/core";
import { Timestamp } from '../../data/timestamp.model';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  DateService,
  RequestError,
  SOFTLINE_STORE_ENTITY_SERVICE,
  Store, Trace
} from "@softline/core";
import { ActivatedRoute, Router } from '@angular/router';
import { RecordTimestampDialogComponent } from '../dialogs/record-timestamp-dialog/record-timestamp-dialog.component';
import { map } from 'rxjs/operators';
import moment from 'moment';
import {
  SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE,
  SOFTLINE_FEATURE_TIMESTAMP,
  TimestampEmployeeStore,
  TimestampStore,
} from '../../stempelerfassung.shared';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { TimestampCurrentEmployeeStore } from '../../store/current-employee.store';
import { CommonModule } from '@angular/common';
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-timestamp-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  imports: [CommonModule, UiCoreModule],
})
export class OverviewComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;
  private allTimestamps = this.store.signal(SOFTLINE_FEATURE_TIMESTAMP, TimestampStore.getters.all)

  currentEmployee$ = this.store.observe(
    SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE,
    TimestampCurrentEmployeeStore.getters.data
  );

  date$ = this.activatedRoute.fragment.pipe(
    map((o) => o ?? this.dateService.today())
  );

  loaded = this.store.signal(
    SOFTLINE_FEATURE_TIMESTAMP,
    TimestampStore.getters.loaded
  );

  loading = this.store.signal(
    SOFTLINE_FEATURE_TIMESTAMP,
    TimestampStore.getters.loading
  );
  date = toSignal(this.date$, {initialValue: '1970-01-01'})

  timestamps = computed(() => {
    const timestamps = this.allTimestamps()
    const date = this.date();
    return timestamps
      .filter((o) => moment(o.timestamp).startOf('day').isSame(date))
      .sort((a, b) =>
        a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
      );
  });

  constructor(
    private store: Store,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private dateService: DateService,
    private injector: Injector,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.subscription = combineLatest([
      this.currentEmployee$,
      this.date$,
    ]).subscribe(async ([employee, date]) => {
      if (!employee) {
        return;
      }
      if (!date) {
        date = this.dateService.today();
      }
      this.store.commit(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.mutations.clear
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.actions.loadMany,
        {
          queryParams: {
            from: date.substr(0, 10),
            to: date.substr(0, 10),
            employeeid: employee.id,
          },
        }
      );
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: OverviewComponent,
        commands: this.createCommands(),
      }
    );
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      OverviewComponent
    );
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  protected createCommands(): Command[] {
    return [
      {
        icon: 'fa-regular fa-stopwatch',
        name: '#TIMESTAMP.RECORD_TIMESTAMP',
        class: 'menu action-menu action-menu-top',
        execute: async () => await this.onCreateClick(),
      },
      {
        icon: 'fa-regular fa-stopwatch',
        name: '#TIMESTAMP.RECORD_TIMESTAMP',
        class: 'menu bottom-menu accent sm:hidden',
        execute: async () => await this.onCreateClick(),
      },
    ];
  }

  async onDelete(entity: Timestamp): Promise<void> {
    await this.store
      .dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, {
        question: '#TIMESTAMP.ASK.CONFIRM_DELETE',
        dismiss: { escape: true, backdrop: true },
      })
      .then(async (result) => {
        if (result !== 'YES') {
          return;
        }
        try {
          await this.store.dispatch(
            SOFTLINE_FEATURE_TIMESTAMP,
            TimestampStore.actions.delete,
            { entity }
          );
          await this.store.dispatch(
            SOFTLINE_FEATURE_MESSAGE_BAR,
            MessageBarStore.actions.success,
            {
              title: '#TIMESTAMP.MESSAGES.REMOVE_SUCCESS.TITLE',
              message: '#TIMESTAMP.MESSAGES.REMOVE_SUCCESS.MESSAGE',
            }
          );
        } catch (e) {
          if (e instanceof RequestError) {
            await this.store.dispatch(
              SOFTLINE_FEATURE_MESSAGE_BAR,
              MessageBarStore.actions.error,
              {
                title: '#TIMESTAMP.MESSAGES.REMOVE_FAILURE.TITLE',
                message: '#TIMESTAMP.MESSAGES.REMOVE_FAILURE.MESSAGE',
              }
            );
          } else {
            showRequestErrors(this.store, e);
          }
        }
      });
  }

  async onNavigateDate(date: string) {
    await this.router.navigate(['stempelerfassung'], { fragment: date });
  }

  @Trace()
  async onCreateClick(): Promise<void> {
    const result = await this.store
      .dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open<Timestamp, object>(), {
        id: 'RECORD_TIMESTAMP',
        component: RecordTimestampDialogComponent,
        getInjector: () => this.injector,
        dismiss: {
          escape: true,
          button: true,
          backdrop: true,
        },
      });
      if (result !== 'DISMISSED')
        await this.addTimestamp(this.store, result);
  }

  async addTimestamp(store: Store, entity: Timestamp): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIMESTAMP,
        TimestampStore.actions.create,
        { entity }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#TIMESTAMP.MESSAGES.ADD_SUCCESS.TITLE',
          message: '#TIMESTAMP.MESSAGES.ADD_SUCCESS.MESSAGE',
        }
      );
      await this.router.navigate(['/stempelerfassung']);
    } catch (e) {
      if (e instanceof RequestError) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          {
            title: '#TIMESTAMP.MESSAGES.ADD_FAILURE.TITLE',
            message: e.message,
          }
        );
      } else {
        showRequestErrors(this.store, e);
      }
    }
  }
}
