import {Dictionary} from '@softline/core';
import {KzAusgabeAllInputCalculationData, KzAusgabeAllInputCalculationStrategy} from './input-calculation.strategy';

export class KzAusgabe2InputCalculationStrategy implements KzAusgabeAllInputCalculationStrategy  {
  calculate(data: KzAusgabeAllInputCalculationData): Dictionary<number | null> {
    const faktor = data.ausgabeEh1.faktor / data.inputArteh.faktor
    const ausgabeEh1Quantity = Math.ceil(data.inputValue / faktor)
    const ausgabeEh2Quantity = Math.floor(data.inputValue)

    console.log('before 2 calc: ', data.dict)

    if (ausgabeEh2Quantity >= 1) {
      const restInAusgabeEh1 = ausgabeEh2Quantity * data.ausgabeEh2.faktor
      data.dict[data.ausgabeEh2.id] = ausgabeEh2Quantity

      if ((ausgabeEh1Quantity - restInAusgabeEh1) > 0) {
        data.dict[data.ausgabeEh1.id] = (ausgabeEh1Quantity - restInAusgabeEh1) + (data.dict[data.ausgabeEh1.id] ?? 0)
      }
    } else {
      data.dict[data.ausgabeEh1.id] = ausgabeEh1Quantity
    }

    console.log('after 2 calc: ', data.dict)
    return data.dict
  }
}
