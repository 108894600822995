import {
  AfterContentInit, ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { QueryParameters } from '@softline/core';
import { Observable } from 'rxjs';
import { DataViewDirective } from './views/data-view.directive';
import { InputViewDirective } from './views/input-view.directive';
import { QueryViewDirective } from './views/query-view.directive';
import { ViewDirective } from './views/view-directive';
import { TabGroupComponent } from '../../tab-group/tab-group.component';
import { TabComponent } from '../../tab-group/tab/tab.component';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { CdkPortalOutlet } from '@angular/cdk/portal';

@Component({
  selector: 'soft-entity-picker',
  standalone: true,
  templateUrl: './entity-picker.component.html',
  styleUrls: ['./entity-picker.component.scss'],
  imports: [
    TabGroupComponent,
    TabComponent,
    CommonModule,
    UiCorePipesModule,
    CdkPortalOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityPickerComponent<TEntity, TQuery = object>
  implements OnInit, AfterContentInit, OnDestroy
{
  activeViewIndex: number = 0;

  @ContentChildren(ViewDirective, { descendants: true })
  views: QueryList<ViewDirective> = new QueryList<ViewDirective>();

  @Input() entities$: Observable<TEntity[]> = new Observable<TEntity[]>();

  @Input() value?: TEntity;
  @Output() valueChange = new EventEmitter<TEntity>();

  @Output() query = new EventEmitter<QueryParameters<TQuery>>();
  @Output() create = new EventEmitter<Partial<TEntity>>();
  @Output() select = new EventEmitter<TEntity>();
  @Output() submit = new EventEmitter<TEntity>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    for (const view of this.views) {
      if (view instanceof QueryViewDirective)
        view.registerQuery((o) => this.query.emit(o));
      if (view instanceof InputViewDirective)
        view.registerCreate((o) => this.create.emit(o));
      if (view instanceof DataViewDirective) {
        view.registerSelect((o) => this.select.emit(o));
        view.registerSubmit((o) => {
          this.submit.emit(o);
          this.value = o;
          this.valueChange.emit(o);
        });
        view.setContext(this.entities$);
      }
    }
  }

  ngOnDestroy(): void {}

  activate(name: string): void {
    this.activeViewIndex =
      this.views.toArray().findIndex((o) => o.name === name) ?? 0;
  }

  filterViews(view: ViewDirective): boolean {
    return view.visible;
  }
}
