<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['konditionsgruppe']}}</span>
  </div>
  <div class="flex flex-col">
    <span class="font-bold">{{item['bezeichnung']}}</span>
  </div>
  @if(item['bezeichnung2']){
    <div class="flex flex-col">
      <span >{{item['bezeichnung2']}}</span>
    </div>
  }
</div>
