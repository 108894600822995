export const SOFTLINE_API_DELIVERY_NOTES = '/v1/auslieferungsapp/lieferscheine/kopflf/entities';
export const SOFTLINE_API_DELIVERY_NOTE_SIGN = '/v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}/sign';
export const SOFTLINE_API_DELIVERY_NOTE_STORNO = '/v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}/storno';
export const SOFTLINE_API_DELIVERY_NOTE_STORNO_ARTIKEL = '/v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}/storno/artikel';
export const SOFTLINE_API_DELIVERY_NOTE_FINISH = '/v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}/abschluss';
export const SOFTLINE_API_DELIVERY_NOTE_SCAN = '/v1/wws/lieferschein/scan/{{type}}/{{code}}';
export const SOFTLINE_API_DELIVERY_NOTE_SCAN_ALL = '/v1/belege/scan/{{type}}/{{code}}';
export const SOFTLINE_API_DELIVERY_NOTE_GET_FOR_SCAN = '/v1/auslieferungsapp/lieferscheine/kopflf/entities';
export const SOFTLINE_API_DELIVERY_NOTE_GEOLOCATION = '/v1/wws/lieferschein/entities/{{id}}/geolocation';
export const SOFTLINE_API_DELIVERY_NOTE_BY_ID = '/v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}';
export const SOFTLINE_API_DELIVERY_NOTE_CUSTOMER_NOTES = 'v1/auslieferungsapp/lieferscheine/kopflf/entities/{{id}}/kundenbelege';
export const FILE_RESOURCE_PATH = '/v2/file';
export const SOFTLINE_API_DELIVERY_NOTE_EXZESS = '/v1/wws/lieferschein/exzess';
