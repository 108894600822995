import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  effect,
  input,
  model,
  output,
  signal,
  untracked
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DragAndDropItem } from './drag-and-drop-item';
import { DragAndDropSelectItemComponent } from './drag-and-drop-select-item/drag-and-drop-select-item.component';
import { ControlValueAccessorBase } from '../control-value-accesor.class';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';


@Component({
  selector: 'soft-drag-and-drop-select',
  standalone: true,
  imports: [CommonModule, CdkDropList, UiCorePipesModule, I18nModule, CdkDropListGroup],
  templateUrl: './drag-and-drop-select.component.html',
  styleUrl: './drag-and-drop-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropSelectComponent<T> extends ControlValueAccessorBase {

  value = signal<T[]>([]);
  valueInput = input<T[]>([], {alias: 'value'});
  valueChange = output<T[]>();

  valueEffect = effect(() => {
    const input = this.valueInput();
    untracked(() => this.value.set(input));
  }, {allowSignalWrites: true});

  items = contentChildren(DragAndDropSelectItemComponent);

  availableItems = computed(() => {
    const items = this.items();
    return items.filter(o => !this.value().includes(o.value()));
  })
  chosenItems = computed(() => {
    const items = this.items();
    return items.filter(o => this.value().includes(o.value()));
  });

  availableValues = computed(() => {
    return this.availableItems().map(o => o.value());
  });

  constructor() {
    super();
  }

  override writeValue(value: T[]): void {
    this.value.set(value);
  }

  async onAvailableColumnsDropped(drag: CdkDragDrop<T[]>, chosenColumns: T[], availableColumns: T[]): Promise<void> {
    const chosen = [...chosenColumns];
    const available = [...availableColumns];

    if (drag.previousContainer === drag.container) {
      moveItemInArray(available, drag.previousIndex, drag.currentIndex);
    } else {
      transferArrayItem(chosen, available, drag.previousIndex, drag.currentIndex);
    }

    await this.setValue(chosen);
  }

  async onChosenColumnsDropped(drag: CdkDragDrop<T[]>, chosenColumns: T[], availableColumns: T[]): Promise<void> {
    const chosen = [...chosenColumns];
    const available = [...availableColumns];

    if (drag.previousContainer === drag.container) {
      moveItemInArray(chosen, drag.previousIndex, drag.currentIndex);
    } else {
      transferArrayItem(available, chosen, drag.previousIndex, drag.currentIndex);
    }

    await this.setValue(chosen);
  }

  private async setValue(value: T[]): Promise<void> {
    this.value.set(value);
    this.valueChange.emit(value);
  }
}
