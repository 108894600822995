import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';
import { Dictionary } from '../../../../types/dictionary';
import { Entity } from '../types/entity';
import { Patch } from '../types/patch';

export interface State<T extends Entity = Entity> {
  ids: (string | number)[];
  entities: Dictionary<T>;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    add: createMutation<State<T>, T>('add'),
    addMany: createMutation<State<T>, T[]>('addMany'),

    patch: createMutation<State<T>, Patch<T>>('patch'),
    patchMany: createMutation<State<T>, Patch<T>[]>('patchMany'),

    update: createMutation<State<T>, T>('update'),
    updateMany: createMutation<State<T>, T[]>('updateMany'),

    remove: createMutation<State<T>, T>('remove'),
    removeMany: createMutation<State<T>, T[]>('removeMany'),

    addOrUpdate: createMutation<State<T>, T>('addOrUpdate'),
    addOrUpdateMany: createMutation<State<T>, T[]>('addOrUpdateMany'),

    clear: createMutation<State<T>>('clear'),
  };

  getters = {
    ids: createGetter<State<T>, (string | number)[]>('ids'),
    entities: createGetter<State<T>, Dictionary<T>>('entities'),
    all: createGetter<State<T>, T[]>('all'),
    entity: createGetter<State<T>, T, string | number>('entity'),
    count: createGetter<State<T>, number>('count'),
  };

  actions = { }

  feature: StoreFeature<State<T>> = {
    initialState: {
      ids: [],
      entities: {},
    },
    mutations: [
      mutate(this.mutations.add, ({ state, params, featureName }) => {
        const index = state.ids.indexOf(params.id);
        if (index > -1)
          throw new Error(
            `[CollectionStore] ${featureName} - add: Entity with Id ${params.id} already exists in the collection.`
          );
        return {
          ...state,
          ids: [...state.ids, params.id],
          entities: { ...state.entities, [params.id]: params },
        };
      }),
      mutate(this.mutations.addMany, ({ state, params, featureName }) => {
        const ids = [...state.ids];
        let entities = { ...state.entities };

        const duplicateIds: (string | number)[] = [];
        for (const entity of params) {
          const index = state.ids.indexOf(entity.id);
          if (index > -1) duplicateIds.push(entity.id);
          ids.push(entity.id);
          entities = { ...entities, [entity.id]: entity };
        }
        if (duplicateIds.length > 0)
          throw new Error(
            `[CollectionStore] ${featureName} - addMany: Entities with Ids ${duplicateIds.join(
              ','
            )} already exists in the collection.`
          );
        return { ...state, ids, entities };
      }),
      mutate(this.mutations.patch, ({ state, params, featureName }) => {
        const index = state.ids.indexOf(params.id);
        if (index === -1)
          throw new Error(
            `[CollectionStore] ${featureName} - patch: Entity with Id ${params.id} does not exists in the collection.`
          );
        const entity = {
          ...(state.entities[params.id] as T),
          ...params.changes,
        };
        const entities = { ...state.entities, [params.id]: entity };
        return { ...state, entities };
      }),
      mutate(this.mutations.patchMany, ({ state, params, featureName }) => {
        const entities = { ...state.entities };
        const notFoundIds: (string | number)[] = [];
        for (const patch of params) {
          const index = state.ids.indexOf(patch.id);
          if (index === -1) notFoundIds.push(patch.id);
          const entity = {
            ...(state.entities[patch.id] as T),
            ...patch.changes,
          };
          entities[patch.id] = entity;
        }
        if (notFoundIds.length > 0)
          throw new Error(
            `[CollectionStore] ${featureName} - patchMany: Entities with Ids ${notFoundIds.join(
              ','
            )} does not exists in the collection.`
          );
        return { ...state, entities };
      }),
      mutate(this.mutations.update, ({ state, params, featureName }) => {
        const index = state.ids.indexOf(params.id);
        if (index === -1)
          throw new Error(
            `[CollectionStore] ${featureName} - update: Entity with Id ${params.id} does not exists in the collection.`
          );
        const entities = { ...state.entities, [params.id]: { ...params } };
        return { ...state, entities };
      }),
      mutate(this.mutations.updateMany, ({ state, params, featureName }) => {
        const entities = { ...state.entities };

        const notFoundIds: (string | number)[] = [];
        for (const update of params) {
          const index = state.ids.indexOf(update.id);
          if (index === -1) notFoundIds.push(update.id);
          entities[update.id] = { ...update };
        }
        if (notFoundIds.length > 0)
          throw new Error(
            `[CollectionStore] ${featureName} - updateMany: Entities with Ids ${notFoundIds.join(
              ','
            )} does not exists in the collection.`
          );
        return { ...state, entities };
      }),
      mutate(this.mutations.remove, ({ state, params, featureName }) => {
        const index = state.ids.indexOf(params.id);
        if (index === -1)
          throw new Error(
            `[CollectionStore] ${featureName} - remove: Entity with Id ${params.id} does not exists in the collection.`
          );
        const entities = { ...state.entities };
        delete entities[params.id];
        const ids = [...state.ids];
        ids.splice(index, 1);
        return { ...state, ids, entities };
      }),
      mutate(this.mutations.removeMany, ({ state, params, featureName }) => {
        const entities = { ...state.entities };
        const ids = [...state.ids];

        const notFoundIds: (string | number)[] = [];
        for (const remove of params) {
          const index = ids.indexOf(remove.id);
          if (index === -1) notFoundIds.push(remove.id);
          delete entities[remove.id];
          ids.splice(index, 1);
        }
        if (notFoundIds.length > 0)
          throw new Error(
            `[CollectionStore] ${featureName} - removeMany: Entities with Ids ${notFoundIds.join(
              ','
            )} does not exists in the collection.`
          );
        return { ...state, ids, entities };
      }),
      mutate(this.mutations.addOrUpdate, ({ state, params }) => {
        const entities = { ...state.entities };
        entities[params.id] = params;
        const ids = [...state.ids];
        if (ids.indexOf(params.id) === -1) ids.push(params.id);
        return { ...state, ids, entities };
      }),
      mutate(this.mutations.addOrUpdateMany, ({ state, params }) => {
        if (!params)
          return state;

        const entities = { ...state.entities };
        const ids = [...state.ids];
        for (const entity of (params ?? [])) {
          entities[entity.id] = entity;
          if (ids.indexOf(entity.id) === -1) ids.push(entity.id);
        }
        return { ...state, ids, entities };
      }),
      mutate(this.mutations.clear, ({ state, params }) => {
        return { ...state, ids: [], entities: {} };
      }),
    ],
    getters: [
      select(this.getters.ids, ({ state }) => state.ids),
      select(this.getters.entities, ({ state }) => state.entities),
      select(
        this.getters.entity,
        ({ state, params }) => state.entities[params]
      ),
      select(this.getters.all, ({ state }) =>
        state.ids.map((id) => state.entities[id])
      ),
      select(this.getters.count, ({ state }) => state.ids.length),
    ],
    actions: [],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
