import {
  AfterContentInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter, forwardRef, Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AccordionItemHeaderDirective} from '../../directives/accordion-item-header/accordion-item-header.directive';
import {AccordionItemContentDirective} from '../../directives/accordion-item-content/accordion-item-content.directive';
import {AccordionComponent} from '../../accordion.component';
import { AccordionItemBase } from '../accordion-item-base';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-accordion-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AccordionItemBase,
      useExisting: forwardRef(() => AccordionItemComponent),
    },
  ],
})
export class AccordionItemComponent extends AccordionItemBase implements AfterContentInit {

  private contentInitialized!: () => void;
  private waitForContentInit = new Promise<void>(resolve => this.contentInitialized = resolve);

  @ViewChild('accordion') template!: TemplateRef<any>

  @Input() class = '';

  @Input()
  set expanded(value: boolean) {
    this.waitForContentInit.then(() => {
      const isExpanded = this.accordion.isExpanded(this);

      if (value !== isExpanded)
        this.toggle();
    })
  }
  get expanded(): boolean {
    return this.accordion.isExpanded(this);
  }

  @Output() expandedChange = new EventEmitter<boolean>();

  @ContentChild(AccordionItemHeaderDirective) header!: AccordionItemHeaderDirective;
  @ContentChild(AccordionItemContentDirective) content!: AccordionItemContentDirective;

  constructor(
    @Inject(forwardRef(() => AccordionComponent)) readonly accordion: AccordionComponent
  ) {
    super();
  }

  toggle(): void {
    this.accordion.toggle(this);
    this.expandedChange.emit(this.expanded);
  }

  ngAfterContentInit(): void {
    this.contentInitialized()
  }
}
