import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HasFocusDirective } from './has-focus.directive';
import { RepeatDirective } from './repeat.directive';
import { VarDirective } from './var.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { SetFocusDirective } from "./set-focus.directive";
import { ValueConverterDirective } from "./value-converter.directive";
import { FieldValidationDirective } from "./field-validation.directive";
import { ToolTipDirective } from './tool-tip.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SetFocusDirective,
    ValueConverterDirective,
    FieldValidationDirective,
    ClickOutsideDirective,
    VarDirective,
    HasFocusDirective,
    RepeatDirective,
    ScrollIntoViewDirective
  ],
  exports: [
    VarDirective,
    HasFocusDirective,
    RepeatDirective,
    ClickOutsideDirective,
    ScrollIntoViewDirective,
    SetFocusDirective,
    ValueConverterDirective,
    FieldValidationDirective
  ],
  providers: [],
})
export class UiCoreDirectivesModule {}
