import {CalculationData, CalculationStrategy} from '../calculation.strategy';
import {Dictionary} from '@softline/core';
import {KzAusgabe1Strategy} from './input-strategies/kz-ausgabe-1.strategy';
import {KzAusgabe1InputCalculationStrategy} from './input-strategies/kz-ausgabe-1-input.strategy';
import {KzAusgabeNInputCalculationStrategy} from './input-strategies/kz-ausgabe-n-input.strategy';

export class KzAusgabe1CalculationStrategy implements CalculationStrategy {

  private readonly strategies: Record<'1' | 'N', KzAusgabe1Strategy> = {
    '1': new KzAusgabe1InputCalculationStrategy(),
    'N': new KzAusgabeNInputCalculationStrategy()
  };

  calculate(data: CalculationData): Dictionary<number | null> {
    const dict = data.dictionary
    const ausgabeEh1 = data.artehs.find(o => o.kzausgabe === '1')

    if (!ausgabeEh1 || !data.inputArteh?.kzausgabe || !this.strategies[data.inputArteh.kzausgabe]) {
      return dict
    }

    return this.strategies[data.inputArteh.kzausgabe].calculate({
      ausgabeEh1,
      inputArteh: data.inputArteh,
      inputValue: data.inputValue,
      dict
    })
  }
}
