<div class="c row space-between no-wrap center-v pt-3 pb-3">
    <div class="c column no-wrap pl-3 pr-3 border-box">
        <div class="g custom-grid">
          <div>
            <span class="text-sm color-light">{{ value.emballageBean.emballage }}</span>
            <i class="fa-regular fa-images info-icon" *ngIf="(value.files?.length || 0) > 0"></i>
          </div>
          <div class="flex gap-1 items-center flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
            <i class="fa-regular fa-note-sticky info-icon" *ngIf="(value.vermerk?.length || 0) > 0"></i>
            <div class="text-ellipsis overflow-hidden opacity-70">{{ value?.vermerk }}</div>
          </div>
        </div>
        <strong class="mt-1 mb-1">{{ value.emballageBean?.bezeichnung }}</strong>
    </div>
    <div class="c row no-wrap center-v end pl-3 border-box">
        <soft-number-input placeholder="Menge"
                           style="max-width: 80px"
                           [value]="value.menge"
                           (valueChange)="onChange(value, qty.value ?? 0)" #qty>

        </soft-number-input>
        <span class="soft-title ml-3 color-light" style="width: 100px;">EH</span>
        <span class="detail-area" (click)="onEditBeweClick(value)">
            <i class="fa-regular fa-ellipsis-vertical"></i>
        </span>
        <span class="detail-area">
            <i class="fa-regular fa-trash pointer p-1" style="color: var(--colorError)" (click)="onDeleteBeweClick(value)"></i>
        </span>
    </div>
</div>
