<div class="relative" *ngIf="{ canEdit: canEdit$ | async } as vm">
  <div class="absolute sticky top-[-0.5rem] left-[-1rem] right-[1rem]" style="z-index: 99999;" *ngIf="!vm?.canEdit">
    <div class="py-2 bg-background" style="background-color: var(--shellContentBackground)">
      <div class="px-4 py-4 text-error border border-danger border-solid" style="background-color: var(--colorDanger200)" *ngIf="!vm?.canEdit">
        Auftrag ist von {{ lockedByUser$ | async }} gesperrt!
      </div>
    </div>
  </div>

  <ng-container *ngIf="item$ | async  as item">
    <div class="mb-5 mt-3 flex flex-col" [class.pt-2]="vm?.canEdit">
      <div>
        <h4 class="text-2xl font-semibold">{{ "#DELIVERY_NOTE.DETAILS.CONSTRUCTION_SITE" | translate }}<br>{{ item.adresseBean?.kurzbez }}</h4>
      </div>
      <div class="color-light text-sm font-medium mt-1">
        {{ item.type?.bezeichnung }} {{ item.belegnummer }}
      </div>
    </div>

    <div class="custom-container mb-5">
      <app-delivery-note-address-card [address]="item.lieferadresseBean" [headerText]="'Baustelle'" *ngIf="item?.lieferadresseBean"></app-delivery-note-address-card>
      <app-delivery-note-address-card [address]="item.adresseBean" *ngIf="item?.adresseBean"></app-delivery-note-address-card>
    </div>

    <ng-container *ngIf="item?.lieferhinweis && (item?.lieferhinweis?.length ?? 0) > 0">
      <div class="c column w-full">
        <div class="soft-overline text-base mb-2">
          {{ '#DELIVERY_NOTE.DETAILS.DELIVERY_INFO_TITLE' | translate }}
        </div>

        <div class="mb-5 p-3 flex flex-col w-full border border-solid border-gray-300 rounded mt-1">
          <p class="text-orange-700" [class.mt-1]="!first" *ngFor="let text of (deliveryInfos$ | async); let first = first">{{ text }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="item?.vermerk">
      <ng-container>
        <div class="c row space-between no-wrap">
          <div class="soft-overline text-base mb-2">
            {{ '#DELIVERY_NOTE.DETAILS.VERMERK_TITLE' | translate }}
          </div>
          <span class="text-red" *ngIf="(vermerkChanged$ | async)">*</span>
        </div>

        <div class="mb-5 flex border border-solid border-gray-300 rounded p-4 flex border-box">
          <span>{{ item?.vermerk }}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item?.bewelfs">
      <div class="soft-overline text-base mb-2">
        {{ item?.type?.bezeichnung }}
      </div>
      <div class="mb-5 flex flex-col w-full mt-2">
        <lib-delivery-note-material-card
                *ngFor="let material of item.bewelfs"
                [idKopfLf]="item.id"
                [deliveryMaterial]="material">
        </lib-delivery-note-material-card>
      </div>
    </ng-container>

    <ng-container *ngIf="(returnNoteChanges$ | async) && (returnNoteWithNonZeroQuantityGroupedBewes$ | async) as retourNote">
      <div class="soft-overline text-base mb-2">
        {{  "#DELIVERY_NOTE.HEADERS.RETURN" | translate }}
      </div>
      <div *ngIf="retourNote" class="mb-5 flex flex-col w-full mt-1">
        <ng-container *ngFor="let bewe of retourNote?.bewes">
          <section *ngIf="(bewe?.bewes?.length ?? 0) > 0">
            <header class="mt-2 mb-2 text-xs font-semibold uppercase tracking-wide flex items-center opacity-70">
              {{ bewe.text | translate }}
              <span class="border-b border-solid border-gray-300 ml-2 w-full flex-1 h-[1px] transform translate-y-[0.5px]">&nbsp;</span>
            </header>
            <ng-container [ngSwitch]="bewe.type">
              <ng-container *ngSwitchCase="'itemScan'">
                <lib-delivery-note-return-card
                        *ngFor="let bewe of bewe.bewes"
                        [bewe]="bewe"
                        [bewelf]="undefined">
                </lib-delivery-note-return-card>
              </ng-container>
              <ng-container *ngSwitchCase="'search'">
                <lib-delivery-note-return-card
                        *ngFor="let bewe of bewe.bewes"
                        [bewe]="bewe"
                        [bewelf]="$any(bewe)?.bewelf">
                </lib-delivery-note-return-card>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <lib-delivery-note-return-card
                        *ngFor="let bewe of bewe.bewes"
                        [bewe]="bewe"
                        [bewelf]="item.bewelfs | single:filterSingleBewelf:$any(bewe)?.idbewelf">
                </lib-delivery-note-return-card>
              </ng-container>
            </ng-container>
          </section>
        </ng-container>
      </div>
    </ng-container>


    <ng-container *ngIf="vm?.canEdit">
      <div class="soft-overline text-base mb-1">
        {{  "#DELIVERY_NOTE.HEADERS.PHOTOS" | translate }}
      </div>

      <ng-container *ngIf="item?.archiveKey">
        <div class="mb-2">
          <soft-archive-list [archiveKey]="item?.archiveKey"></soft-archive-list>
        </div>
      </ng-container>

      <soft-file-input [value]="item.files ?? []"
                       [selectionMode]="'multi'"
                       [sources]="['camera']"
                       (valueChange)="patchNote(+item.id, {files: $event})">
      </soft-file-input>
    </ng-container>



    <div class="mb-4 pb-3">
      <h6 class="soft-overline text-base mb-2 mt-5">{{ '#DELIVERY_NOTE.DETAILS.SIGNATURE.OVERLINE' | translate }}</h6>
      <div class="signature-input pos-rel mt-2" (click)="vm?.canEdit && openSignaturePad(item)" [class.disabled]="!vm?.canEdit">
        <ng-container *ngIf="item?.signatureBlob">
          <div class="signature-image" [ngStyle]="{backgroundImage: 'url('+ ((item.signatureBlob | localImage | async) || 'none') + ')'}"></div>
        </ng-container>
        <small class="text-xs color-light">{{ '#DELIVERY_NOTE.DETAILS.SIGNATURE.TAP_TO_ADD_INFO' | translate }}</small>
      </div>
    </div>

    <button class="soft-button primary w-full h-14" [disabled]="!vm?.canEdit || saving || !item?.signatureBlob" (click)="complete(item.id, item)">
      <i *ngIf="saving" class="fa-regular fa-spinner fa-spin"></i>
      Abschließen
    </button>

  </ng-container>
</div>
