import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ValidationMessage } from '@softline/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../i18n/i18n.module';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';

@Component({
  selector: 'soft-validation-message',
  standalone: true,
  imports: [CommonModule, I18nModule, UiCorePipesModule],
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent implements OnInit {
  @Input() message?: ValidationMessage;

  constructor() {}

  ngOnInit(): void {}
}
