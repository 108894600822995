import { Component, ContentChild, Directive, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ModalOption } from "../../modal/data/option-modal-config";


@Directive({
  selector: 'soft-split-option',
  standalone: true
})
export class SplitButtonOptionDirective implements OnInit, ModalOption<EventEmitter<any>> {

  value= new EventEmitter<any>();
  @Input() label: string | undefined;
  @Input() icon: string | undefined;
  @Input() disabled = false;
  @Output()
  get action(): EventEmitter<any> {
    return this.value;
  }

  constructor() {}

  ngOnInit(): void {
  }
}
