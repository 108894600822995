import {UpdateQueryStrategyFactory} from './query-update.strategy';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';

export const artikelQueryUpdateStrategy: UpdateQueryStrategyFactory<string> = () => {
  return {
    umsatzebene: 'Artikel',
    updateFn: async (artikel, query) => {
      query.artikel = { cartikelnummer: artikel } as any;
      return query;
    },
    removeFn: async (query: FrontendUmsatzstatistikQuery)  => {
      delete query['artikel']
      return query;
    }
  }
}
