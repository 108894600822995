import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from '../form/select/select.component';
import { SelectOptionDirective } from '../form/select/select-option/select-option.directive';
import { I18nModule } from '../../i18n/i18n.module';
import { UiCorePipesModule } from '../../pipes/ui-core-pipes.module';

@Component({
  selector: 'soft-paginator',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    SelectOptionDirective,
    I18nModule,
    UiCorePipesModule,
  ],
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() page = 1;
  @Output() pageChange = new EventEmitter<number>();

  @Input() pageSize = 10;
  @Output() pageSizeChange = new EventEmitter<number>();

  @Input() pageSizes = [10, 25, 50, 100];
  @Input() elements = 0;

  isPreviousPageEnabled = false;
  isNextPageEnabled = false;

  get pageCount() {
    return Math.ceil(this.elements / this.pageSize);
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateState();
  }

  onFirstPage(): void {
    this.page = 1;
    this.updateState();
    this.pageChange.emit(this.page);
  }

  onPreviousPage(): void {
    if (this.page > 1) this.page = this.page - 1;
    this.updateState();
    this.pageChange.emit(this.page);
  }

  onNextPage(): void {
    if (this.page < Math.ceil(this.elements / this.pageSize))
      this.page = this.page + 1;

    this.updateState();
    this.pageChange.emit(this.page);
  }

  onLastPage(): void {
    this.page = Math.ceil(this.elements / this.pageSize);
    this.updateState();
    this.pageChange.emit(this.page);
  }

  setPageSize(size: number): void {
    this.pageSize = size;
    this.page = 1;
    this.pageSizeChange.emit(size);
    this.updateState();
  }

  private updateState(): void {
    this.isPreviousPageEnabled = this.page > 1;
    this.isNextPageEnabled =
      this.page < Math.ceil(this.elements / this.pageSize);
  }
}
