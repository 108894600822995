<!-- <ng-container *ngIf="deliveryNote$ | async as deliveryNote">
    <div class="mb-1 mt-3 flex flex-col">
        <div>
            <h4 class="text-2xl font-semibold">Angelieferte Artikel suchen</h4>
        </div>
    </div>
</ng-container> -->

<ng-container *ngIf="returnNote$ | async as returnNote">
    <ng-container *ngIf="searchResults$ | async as searchState">
        <section class="sticky top-[-0.5rem] z-50 fill-content px-4" style="background-color: var(--shellContentBackground)">
            <div class="flex items-center justify-end w-full h-5 mb-1">
                <span class="text-xs text-gray-400" *ngIf="(input?.value?.length ?? 0) < 3">Noch {{ 3 - (input?.value?.length ?? 0) }} Zeichen</span>
            </div>
            <div class="flex flex-row justify-between items-center soft-input">
                <input type="text" placeholder="Nach Artikel suchen (min. 3 Zeichen)" class="border-none shadow-none w-full h-full" #input (input)="searchInput$.next(input?.value ?? '')" [readonly]="(lockResults$ | async) ?? false">
                <i class="fa-regular fa-magnifying-glass"></i>
            </div>

            <div class="mt-4 border-gray-200 border-solid border-b fill-content px-4 pb-1" *ngIf="!searchState?.loading && (searchState?.results?.length ?? 0) > 0">
                <span class="text-sm uppercase tracking-wide text-gray-400 font-semibold">Ergebnisse ({{ searchState?.results?.length ?? 0 }})</span>
            </div>
        </section>

        <section class="z-0 pb-16">
            <div class="flex items-center justify-center min-h-[50vh]" *ngIf="searchState.loading; else results">
                <soft-loading-spinner></soft-loading-spinner>
            </div>
            <ng-template #results>
                <ng-container *ngIf="(searchState?.results?.length ?? 0) > 0">
                    <div class="flex flex-col fill-content bg-white pt-1">
                       <!--  <div class="flex flex-row justify-between items-center mb-1 mt-1 px-4">
                            <span class="soft-overline tracking-wide">Lieferschein {{ deliveryNote?.belegnummer }}</span>
                            <span class="text-sm text-gray-400">{{ deliveryNote?.lieferdatum | date:'dd.MM.YYYY' }}</span>
                        </div> -->

                     @for (stornoBewe of searchState.results; let first = $first; track stornoBewe) {
                        <div class="border-gray-200 border-solid border-b flex flex-col px-4 py-2">
                          <lib-return-note-search-item
                            #searchListItemComponent
                            (beweChanged)="onBeweChanged(stornoBewe, $event)"
                            (mengeAufsplitten)="onAufsplitten($event, searchState.results)"
                            [bewelf]="stornoBewe"
                            [returnNoteId]="returnNote.id"
                            [value]="returnNote.bewes|single:filterSingleSearchStornoBewe:stornoBewe">
                          </lib-return-note-search-item>
                        </div>
                      }

                    </div>
                </ng-container>

                <ng-container *ngIf="(searchState?.results?.length ?? 0) < 1 && ( input?.value?.length ?? 0) > 3">
                    <div class="flex items-center justify-center min-h-[70vh]">
                        Mit dem Suchbegriff "{{ input?.value }}" wurden keine ausgelieferten Artikel gefunden
                    </div>
                </ng-container>
                <ng-container *ngIf="(searchState?.results?.length ?? 0) < 1 && (input?.value?.length ?? 0) < 3">
                    <div class="flex items-center justify-center min-h-[70vh] px-10 text-center text-sm opacity-70">
                        Bitte geben Sie mindestens 3 Zeichen ein um die Suche automatisch zu starten
                    </div>
                </ng-container>
            </ng-template>
        </section>

        <section
            class="fixed bottom-0 py-4 text-white font-semibold text-base left-0 right-0 bg-primary-500 disabled:bg-300 flex items-center text-center justify-center"
            (click)="onSave(returnNote.id)">
            Übernehmen
        </section>
    </ng-container>
</ng-container>
