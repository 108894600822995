import { ChangeDetectionStrategy, Component } from '@angular/core';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_DELIVERY_NOTE } from '../../delivery-note.shared';
import { DeliveryNotesStore } from '../../stores';
import { GeolocationStore, SOFTLINE_FEATURE_GEOLOCATION } from '@softline/application';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-delivery-note-signature-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureDialogComponent implements Modal<{ time: string, signature?: string } | null> {

  hasUnterschrift = false;
  hasGeodaten = false;

  readonly currentTime = new Date().toISOString();

  id!: number;
  close!: (result: { time: string, signature?: string } | null) => void;

  constructor(private store: Store) { }

  registerCloseHandler(handler: (result: ({ time: string, signature?: string } | null)) => void) {
    this.close = handler;
  }

  async saveLocation(): Promise<void> {
    try {
      const location = await this.store.dispatch(
        SOFTLINE_FEATURE_GEOLOCATION,
        GeolocationStore.actions.getCurrentPosition
      );

      await this.store.dispatch(SOFTLINE_FEATURE_DELIVERY_NOTE, DeliveryNotesStore.actions.recordGeolocation, {
        id: this.id,
        gpsbreite: location.gpsbreite,
        gpslaenge: location.gpslaenge,
      });

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: 'Standort wurde erfolgreich gespeichert!'
        }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: 'Standort konnte nicht aufgezeichnet werden!'
        }
      );
    }
  }

  async closeDialog(time: string | undefined | null, signature: string | undefined, empty?: boolean): Promise<void> {
    if (empty && !this.hasUnterschrift)
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.info, {
        title: 'Keine Unterschrift vorhanden',
        message: 'Ohne Unterschrift kann der Lieferschein nicht abgeschlossen werden!'
      });

    this.close({
      time: time ?? new Date()?.toISOString(),
      signature: (empty) ? undefined : signature
    });
  }
}
