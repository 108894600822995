import {Component, OnDestroy, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {base64Decode, Store} from "@softline/core";
import {ActivatedRoute, Router} from "@angular/router";
import {
  SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG
} from "../../../warenuebernahme.shared";
import {WwsBestellungStore} from "../../../store/bestellung.store";
import {
  BackNavigable,
  BackNavigationService,
  CommandStore,
  FilterAndSortComponent, showRequestErrors,
  HeaderComponent, SOFTLINE_FEATURE_COMMANDS,
  StepHeaderComponent
} from "@softline/application";
import {BehaviorSubject, map, Observable, of, Subject, Subscription, switchMap, tap} from "rxjs";
import {KopfdatenComponent} from "../../../components/kopfdaten/kopfdaten.component";
import {Bestellung} from "../../../types/bestellung";
import {BewegungListComponent} from "../../../components/bewegung-list/bewegung-list.component";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from "@softline/ui-core";
import {
  BestellungUebernehmenDialogComponent
} from "../../../dialogs/bestellung-uebernehmen/bestellung-uebernehmen-dialog.component";
import {WwsWarenuebernahmeStore} from "../../../store/warenuebernahme.store";
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'soft-bestellung-detail',
  standalone: true,
  imports: [CommonModule, FilterAndSortComponent, HeaderComponent, StepHeaderComponent, KopfdatenComponent, BewegungListComponent, UiCoreModule],
  templateUrl: './bestellung-detail.component.html',
  styleUrls: ['./bestellung-detail.component.scss'],
})
export class BestellungDetailComponent implements OnInit, OnDestroy, BackNavigable {

  readonly steps = SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS;

  private readonly routeParams$: Observable<{ ids: number[], bestellnummern: string[] }> = this.activatedRoute.paramMap.pipe(
    map(params => params.get('data') ?? ''),
    map(base64Data => JSON.parse(base64Decode(base64Data)) ?? '{}')
  );

  private readonly bestellungData = toSignal(this.routeParams$);

  private readonly bestellungId$ = this.routeParams$.pipe(map(o => o[0]))

  readonly bestellungId = toSignal(
    this.bestellungId$,
    { initialValue: null }
  );

  readonly bestellung$ = this.routeParams$.pipe(
    switchMap(bestellungData =>
      this.store.observe(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
        WwsBestellungStore.getters.entity,
        bestellungData.ids[0]
      ).pipe(
        map(bestellung => ({
          ...bestellung,
          bestellnummer: bestellungData.bestellnummern.filter(o => !!o && o !== '').reduce((a, b) => `${a === '' ? '' : `${a},`} ${b}`, '')
        }))
      )
    )
  );

  readonly bewegungen$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
    WwsBestellungStore.getters.bewegungen
  );

  private bestellungSubscription?: Subscription;
  private bestellung?: Bestellung;
  public loadingBewes = signal(false);

  constructor(private store: Store,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private backNavigationService: BackNavigationService) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.bestellungSubscription = this.bestellung$.subscribe(bestellung => {
      this.bestellung = bestellung;
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet, {
        name: BestellungDetailComponent,
        commands: [
          {
            name: '#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_BESTELLUNG_UEBERNEHMEN',
            class: 'menu bottom-menu primary',
            execute: async () => await this.bestellungUebernehmen(this.bestellung ?? null),
          }
        ]
      }
    );

    try {
      const bestellungData = this.bestellungData();

      if (!bestellungData?.ids)
        return;

      this.loadingBewes.set(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
        WwsBestellungStore.actions.loadOnce,
        {
          id: bestellungData.ids[0]
        }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
        WwsBestellungStore.actions.loadBewegungen,
        bestellungData.ids
      );

      this.loadingBewes.set(false);
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.loadingBewes.set(false);
    }
  }

  ngOnDestroy(): void {
    if (this.bestellungSubscription)
      this.bestellungSubscription.unsubscribe();

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      BestellungDetailComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.mutations.setBewegungen,
      []
    );

    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/warenuebernahme/bestellung/schritt-2'],{
      queryParams: {
        idlieferant: this.bestellung?.lieferant.id
      }
    });
  }

  async bestellungUebernehmen(bestellung: Bestellung | null) {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN',
        component: BestellungUebernehmenDialogComponent,
        data: {
          bestellung: bestellung
        },
        dismiss: true
      }
    );

    if (result === 'DISMISSED')
      return;

    if (result !== '' && bestellung?.id) {
      const warenuebernahme = await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
        WwsBestellungStore.actions.bestellungUebernehmen,
        {
          idbestellungList: this.bestellungData()?.ids ?? [],
          lieferscheinnummer: result
        }
      );

      this.store.commit(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
        WwsWarenuebernahmeStore.mutations.addOrUpdate,
        warenuebernahme
      );

      if (warenuebernahme) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          {
            title: '#WWS_WARENUEBERNAHME.BESTELLUNG.MESSAGES.TITEL_BESTELLUNG_ABGESCHLOSSEN',
            message: '#WWS_WARENUEBERNAHME.BESTELLUNG.MESSAGES.MESSAGE_BESTELLUNG_ABGESCHLOSSEN',
          });

        await this.router.navigate(['/warenuebernahme/detail', warenuebernahme.id])
      }
    }
  }
}
