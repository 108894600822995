<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['arthauptgrp']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['arthauptgrpsprbezeichnung']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="">{{item['bezeichnung2']}}</span>
  </div>
</div>
