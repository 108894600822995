import { EventEmitter, Input, Output, OutputEmitterRef, Signal, WritableSignal } from '@angular/core';
import { ControlValueAccessorBase } from '../control-value-accesor.class';

export abstract class RadioGroup<T> extends ControlValueAccessorBase {
  abstract value: Signal<WritableSignal<T | null>>;
  abstract valueChange: OutputEmitterRef<T>;
  abstract readonly: Signal<boolean>;
  abstract setValue(value: T): void;
}

export abstract class RadioOption<T> {
  abstract checked: Signal<boolean>;
  abstract value: Signal<T>;
  abstract labelPosition: Signal<'before' | 'after'>;
  abstract disabled: Signal<boolean>;
}
