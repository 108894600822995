import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Host,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  SkipSelf,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { Subscription } from 'rxjs';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CheckOption } from './check-group';
import { CHECK_STRATEGY, CheckStrategy } from './strategies/check.strategy';
import { CommonCheckStrategy } from './strategies/common-check.strategy';

@Directive({
  selector: 'soft-check-group',
  standalone: true,
  exportAs: 'softCheckGroup',
  providers: [
    { provide: CHECK_STRATEGY, useClass: CommonCheckStrategy },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckGroupDirective),
      multi: true,
    },
  ],
})
export class CheckGroupDirective
  implements AfterContentInit, OnDestroy, ControlValueAccessor
{
  private subscription?: Subscription;
  private _value: any[] | null | undefined = undefined;
  private _readonly = false;
  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  @ContentChildren(CheckOption, { descendants: true })
  options!: QueryList<CheckOption>;

  get value(): any[] | null | undefined {
    return this._value;
  }
  @Input()
  set value(value: any[] | null | undefined) {
    if (equals(value, this._value)) return;
    this.setValue(value);
  }
  @Output() valueChange = new EventEmitter<any>();

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = value;
    for (const option of this.options ?? []) option.setReadonlyState(value);
  }
  @Input() checkStrategy: CheckStrategy;

  constructor(
    @Inject(CHECK_STRATEGY) checkStrategy: CheckStrategy,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.checkStrategy = checkStrategy;
  }

  ngAfterContentInit(): void {
    for (const option of this.options) {
      option.registerOnChange(() => this.onOptionChange());
      option.registerOnTouched(() => this.onOptionTouched());
      option.setReadonlyState(this._readonly);
    }

    this.subscription = this.options.changes.subscribe((options) => {
      for (const option of options) {
        option.registerOnChange(() => this.onOptionChange());
        option.registerOnTouched(() => this.onOptionTouched());
        option.setReadonlyState(this._readonly);
      }
      this.onOptionChange();
      this.changeDetectorRef.detectChanges();
    });

    if (this._value)
      this.checkStrategy.setOptions(this.options.toArray(), this._value);
    else this.writeValue(this.checkStrategy.getValues(this.options.toArray()));
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;

    if (!this.options) return;
    this.checkStrategy.setOptions(this.options.toArray(), obj);
  }

  setValue(value: any[] | null | undefined): void {
    this._value = value;
    this.valueChange.emit(this._value);

    if (!this.options) return;
    this.checkStrategy.setOptions(this.options.toArray(), value);

    this.onChange(this._value);
    this.onTouch();
  }

  private onOptionTouched(): void {
    this.onTouch();
  }

  private onOptionChange(): void {
    this._value = this.checkStrategy.getValues(this.options.toArray());
    this.valueChange.emit(this._value);
    this.onChange(this._value);
    this.onTouch();
  }
}
