import { EmballageBewe, ReturnNoteBewe, SearchStornoBewe, StornoBewe, VermerkBewe } from '../types/return-note';
import { BeweOptionDialogComponent, BottomSheetOption } from '../dialogs/bewe-option-dialog/bewe-option-dialog.component';
import { ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';
import { RetourImageDialogComponent } from '../dialogs/retour-image-dialog/retour-image-dialog.component';
import {SOFTLINE_CONFIG_LIEFERSCHEIN, SOFTLINE_FEATURE_RETURN_NOTE} from '../delivery-note.shared';
import { ReturnNoteStore } from '../stores/return-note.store';
import { EditImageDialogComponent } from '../dialogs/edit-image-dialog/edit-image-dialog.component';
import { DefaultCommentDialogComponent } from '../dialogs/default-comment-dialog/default-comment-dialog.component';
import { Emballage } from '../types/emballage';
import { AddBeweDialogComponent } from '../dialogs/add-bewe-dialog/add-bewe-dialog.component';
import { isDefined, SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import {Inject, Injectable, Optional} from '@angular/core';
import { ExtendedUnitDialogComponent } from '../dialogs/extended-unit-dialog-component/extended-unit-dialog.component';
import { Arteh } from '../types/arteh';
import { BeweLf } from '../types/bewe-lf';
import { Router } from '@angular/router';
import {DeliveryNoteConfig} from '../types/delivery-note-config';

@Injectable()
export class ReturnNoteBeweModalService {

  constructor(private store: Store,
              private router: Router,
              @Optional() @Inject(SOFTLINE_CONFIG_LIEFERSCHEIN) private deliveryNoteConfig: DeliveryNoteConfig,
              @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string) { }

  async editBewe(id: string, bewe: ReturnNoteBewe, beweLf?: BeweLf): Promise<void> {
    let options: BottomSheetOption[] = [
      { id: 'ADD_IMAGE', label: 'Bild hinzufügen' },
    ];

    options.push(
      (bewe.vermerk?.length ?? 0 > 0)
        ? {id: 'EDIT_VERMERK', label: 'Vermerk bearbeiten'}
        : {id: 'ADD_VERMERK', label: 'Vermerk hinzufügen'}
    );


    if (beweLf && beweLf?.artehBeans?.length > 1 && bewe.typ !== 'vermerk') {
        options = [
            { id: 'EXTENDED_UNIT', label: 'Erweiterte Einheiteneingabe' },
            ...options
        ];
    }

    if (bewe?.files?.length)
      options.splice(1, 0, { id: 'EDIT_IMAGES', label: bewe.files.length > 1 ? 'Bilder bearbeiten' : 'Bild bearbeiten' });

    const itemName = (bewe as EmballageBewe)?.emballageBean?.bezeichnung;
    const sheetResult = await this.openOptionModal(options, {itemName});

    if (sheetResult === 'DISMISSED' || !sheetResult?.selected)
      return;

    switch (sheetResult.selected?.id) {
      case 'ADD_VERMERK':
      case 'EDIT_VERMERK':
        await this.showCommentDialog(id, bewe as EmballageBewe | StornoBewe);
        break;
      case 'ADD_IMAGE':
        await this.showImageDialog(id, bewe);
        break;
      case 'EDIT_IMAGES':
        await this.showEditImageDialog(id, bewe);
        break;
      case 'EXTENDED_UNIT':
        await this.showExtendedUnitDialog(id, bewe as EmballageBewe | StornoBewe, beweLf);
        break;
    }
  }

  async addBewe(id: string): Promise<string | undefined> {
    const options: BottomSheetOption[] = [
      { id: 'ADD_EMBALLAGEN', label: 'Emballagen hinzufügen' },
      { id: 'ADD_VERMERK', label: 'Vermerk-Artikel hinzufügen' },
      { id: 'ADD_ITEM', label: 'Retour-Artikel von ausgelieferten Belegen hinzufügen' },
    ];

    if (this.deliveryNoteConfig && this.deliveryNoteConfig?.showReturnNoteScanOption) {
      options.push({ id: 'ADD_ITEM_SCAN', label: 'Retour-Artikel scannen und hinzufügen' });
    }

    const sheetResult = await this.openOptionModal(options, {
      customText: 'Bitte wählen Sie aus, welche Art von Artikel zum Retourschein hinzugefügt werden sollen.'
    });

    if (sheetResult === 'DISMISSED' || !sheetResult?.selected)
      return;

    switch (sheetResult.selected?.id) {
      case 'ADD_EMBALLAGEN':
        await this.showAddEmballagenDialog(id);
        break;
      case 'ADD_VERMERK':
        await this.showAddVermerkartikelDialog(id);
        break;
      case 'ADD_ITEM':
        break;
      case 'ADD_ITEM_SCAN':
        break;
    }

    return sheetResult?.selected.id;
  }

  private async openOptionModal(
    options: BottomSheetOption[],
    extraConfig?: { itemName?: string; customText?: string }
  ): Promise<{ selected?: BottomSheetOption } | 'DISMISSED'> {
    return await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ selected?: BottomSheetOption }, { options: BottomSheetOption[]; itemName?: string }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_BOTTOM_SHEET',
        component: BeweOptionDialogComponent,
        data: {
          options,
          ...(extraConfig ?? {})
        },
        dismiss: {
          backdrop: true,
          escape: true,
          button: true
        }
      }
    );
  }

  private async showImageDialog(id: string, bewe: ReturnNoteBewe): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<File[] | null, {}>(),
      {
        id: 'DELIVERY_NOTE_IMAGE',
        component: RetourImageDialogComponent,
        dismiss: {
          backdrop: true,
          button: true,
          escape: false
        }
      }
    );

    if (result === 'DISMISSED' || result === null || result?.length < 1)
      return;

    bewe = {...bewe, files: [...bewe?.files, ...result] };
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }

  private async showEditImageDialog(id: string, bewe: ReturnNoteBewe): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<File[] | null, { images?: File[]; callback?: (_: File[]) => void }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_EDIT_IMAGE',
        component: EditImageDialogComponent,
        data: {
          images: bewe.files,
        },
        dismiss: {
          backdrop: true,
          button: true,
          escape: false
        }
      }
    );

    if (result === 'DISMISSED' || result === null)
      return;

    bewe = {...bewe, files: result};
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }

  async showExtendedUnitDialog(id: string, bewe: StornoBewe | EmballageBewe | SearchStornoBewe, beweLf?: BeweLf): Promise<void> {
      if (bewe.typ === 'emballage' || !beweLf)
          return;

      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<{ amount: number; arteh: Arteh } | null, any>(),
        {
          id: 'DELIVERY_NOTE_RETOUR_COMMENT',
          component: ExtendedUnitDialogComponent,
          data: {
              beweLf,
          },
          dismiss: {
            backdrop: true,
            button: true,
            escape: false
          }
        }
      );

      if (result === 'DISMISSED' || result === null)
        return;

      const updatedBewe: StornoBewe | SearchStornoBewe = { ...bewe, artehBean: result.arteh, menge: result.amount };
      await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, { id, bewe: updatedBewe });
  }

  private async showCommentDialog(id: string, bewe: StornoBewe | EmballageBewe): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string | null, { vermerk?: string | null; isItem?: boolean }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_COMMENT',
        component: DefaultCommentDialogComponent,
        data: { vermerk: bewe.vermerk },
        dismiss: {
          backdrop: true,
          button: true,
          escape: false
        }
      }
    );

    if (result === 'DISMISSED' || result === null)
      return;

    bewe = {...bewe, vermerk: result};
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }

  private async showAddEmballagenDialog(id: string): Promise<void> {
    const excludedIds = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.preparedEntity, id)
      .bewes.filter(o => o.typ === 'emballage').map(o => (o as EmballageBewe).emballageBean.id);


    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ emballage: Emballage, quantity: number } | null, { }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_ADD',
        component: AddBeweDialogComponent,
        data: { excludedIds },
        dismiss: {
          backdrop: false,
          escape: true,
          button: true
        }
      }
    );

    if (!isDefined(result) || result === 'DISMISSED')
      return;

    const bewe: EmballageBewe = {
      id: this.uuid(),
      typ: 'emballage',
      files: [],
      vermerk: '',
      emballageBean: result.emballage,
      menge: result.quantity
    };
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }

  private async showAddVermerkartikelDialog(id: string): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string | null, { vermerk?: string | null; isItem?: boolean }>(),
      {
        id: 'DELIVERY_NOTE_RETOUR_ADD_VERMERKARTIKEL',
        component: DefaultCommentDialogComponent,
        data: { vermerk: '', isItem: true },
        dismiss: {
          backdrop: true,
          button: true,
          escape: false
        }
      }
    );

    if (result === 'DISMISSED' || result === null)
      return;

    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.preparedEntity, id);
    const vermerkBewes = returnNote.bewes.filter(o => o.typ === 'vermerk').map(o => o as VermerkBewe);

    let lastNumber = 0;

    if (vermerkBewes?.length > 0) {
        lastNumber =  vermerkBewes.reduce((prev, curr) => curr?.lfdnr > prev?.lfdnr ? curr : prev)?.lfdnr;
    }

    if (vermerkBewes.find(o => o.lfdnr === lastNumber + 1))
        return;

    const bewe: VermerkBewe = {
      id: this.uuid(),
      lfdnr: lastNumber + 1,
      typ: 'vermerk',
      vermerk: result,
      files: [],
    };

    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }
}
