import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {Observable, Subscription} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Dictionary, Store} from '@softline/core';
import {SOFTLINE_FEATURE_DELIVERY_NOTE, SOFTLINE_FEATURE_RETURN_NOTE} from '../../delivery-note.shared';
import {DeliveryNotesStore} from '../../stores/';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule} from '@softline/ui-core';
import {DeliveryNote} from '../../types/delivery-note';
import {BeweLf} from '../../types/bewe-lf';
import {
  EmballageBewe,
  ItemScanBewe,
  ReturnNote,
  ReturnNoteBewe,
  SearchStornoBewe,
  StornoBewe
} from '../../types/return-note';
import {CommonModule, DecimalPipe} from '@angular/common';
import {ReturnNoteStore} from '../../stores/return-note.store';
import {ReturnNoteBeweModalService} from '../../services/return-note-bewe-modal.service';
import {ReturnNoteScanItemComponent} from '../../components/return-note-scan-item/return-note-scan-item.component';
import {
  ReturnNoteStornoBeweItemComponent
} from '../../components/return-note-storno-bewe-item/return-note-storno-bewe-item.component';
import {
  ReturnNoteEmballageBeweItemComponent
} from '../../components/return-note-emballage-bewe-item/return-note-emballage-bewe-item.component';

@Component({
  selector: 'app-delivery-note-retour',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReturnNoteScanItemComponent, ReturnNoteStornoBeweItemComponent, ReturnNoteEmballageBeweItemComponent],
  templateUrl: './return-note.component.html',
  styleUrls: ['./return-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe]
})
export class ReturnNoteComponent implements OnInit, OnDestroy, BackNavigable {

  private deliveryNoteSubscription?: Subscription;
  private returnNoteSubsctiption?: Subscription;

  readonly id$ = this.route.paramMap.pipe(map(o => o.get('id')));

  readonly returnNote$: Observable<ReturnNote> = this.id$.pipe(
    switchMap(id => this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.preparedEntity, id))
  );

  readonly deliveryNote$: Observable<DeliveryNote> = this.returnNote$.pipe(
    switchMap(returnNote => this.store.observe(
      SOFTLINE_FEATURE_DELIVERY_NOTE,
      DeliveryNotesStore.getters.collection.preparedEntity,
      returnNote.idlf
    ))
  );

  readonly searchStornoBewes$: Observable<SearchStornoBewe[]> = this.returnNote$.pipe(
    withLatestFrom(this.deliveryNote$),
    map(([returnNote, _]) => {
        return returnNote.bewes?.filter(bewe => bewe.typ === 'search') as SearchStornoBewe[];
      }
    )
  );

  readonly scanItems$: Observable<ItemScanBewe[]> = this.returnNote$.pipe(
    map(o => o.bewes.filter(bewe => bewe.typ === 'itemScan') as ItemScanBewe[])
  );

  readonly trackBewelfs = (index: number, item: BeweLf) => item.id ?? index;
  readonly trackSearchBewelfs = (index: number, item: SearchStornoBewe) => item.id ?? index;
  readonly trackScanItems = (index: number, item: ItemScanBewe) => item.id ?? index;

  constructor(
      private store: Store,
      protected router: Router,
      private route: ActivatedRoute,
      private modalService: ReturnNoteBeweModalService,
      private backNavigationService: BackNavigationService
  ) { }

  ngOnInit(): void {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#DELIVERY_NOTE.RETOUR.TITLE');
    this.backNavigationService.set(this);
    this.deliveryNoteSubscription = this.deliveryNote$
      .subscribe(o => {
        this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setSubTitle, 'Lieferschein ' + o.belegnummer);
    });
    this.returnNoteSubsctiption = this.returnNote$
      .subscribe(o => {
        this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
          name: ReturnNoteComponent,
          commands: this.createCommands(o)
        });
      });
  }

  ngOnDestroy(): void {
    if (this.deliveryNoteSubscription && !this.deliveryNoteSubscription.closed)
      this.deliveryNoteSubscription.unsubscribe();

    if (this.returnNoteSubsctiption && !this.returnNoteSubsctiption.closed)
      this.returnNoteSubsctiption.unsubscribe();

    this.returnNoteSubsctiption = undefined;
    this.deliveryNoteSubscription = undefined;

    this.backNavigationService.set(undefined);

    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setSubTitle, '');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, ReturnNoteComponent);
  }

  async navigateBack(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.entity, id);

    try {
      for (const bewe of returnNote?.bewes) {
        if (this.nonEmptyBewes(bewe)) {
          continue;
        }

        await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.deleteBewe, { id: returnNote.id, bewe });
      }
    } catch (e) {
      console.log(e)
    }

    await this.router.navigate(['/lieferscheine', returnNote.idlf]);
  }

  private nonEmptyBewes(bewe: ReturnNoteBewe): boolean {
    if (bewe.typ === 'search') {
      return (bewe?.menge || 0) > 0;
    } else if (bewe.typ === 'storno') {
      return (bewe?.menge || 0) > 0;
    } else if (bewe.typ === 'emballage') {
      return (bewe?.menge || 0) > 0;
    } else if (bewe.typ === 'itemScan') {
      return (bewe?.itemScanBean?.menge || 0) > 0;
    } else {
      return !!bewe.vermerk && bewe?.vermerk?.trim()?.length > 0;
    }
  }
  async addBewe(id: string) {
    const result = await this.modalService.addBewe(id);
    const deliveryNoteId = this.route.snapshot.paramMap.get('id');
    const returnNote = this.store.get(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.entity, deliveryNoteId);

    if (result === 'ADD_ITEM') {
      await this.router.navigate(['lieferscheine', 'return-note', returnNote?.id, 'search']);
    } else if (result === 'ADD_ITEM_SCAN') {
      await this.router.navigate(['lieferscheine', 'return-note', returnNote?.id, 'scan']);
    }
  }

  patchReturnNote(id: string, changes: Dictionary<any>) {
    this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.preparePatch, {id, changes});
  }

  async upsertBewe(id: string, bewe: ReturnNoteBewe): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.upsertBewe, {id, bewe});
  }

  async removeBewe(id: string, bewe: ReturnNoteBewe): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.actions.deleteBewe, {id, bewe});
  }

  async onSubmit(): Promise<void> {
    await this.navigateBack();
  }

  async onAddVermerkImage(id: string | undefined, bewe: ReturnNoteBewe) {
    if (!id)
      return;

    await this.modalService.editBewe(id, bewe);
  }

  filterBewelfs(bewelf: BeweLf): boolean {
    return !!bewelf.menge;
  }

  filterSingleStornoBewe(stornoBewe: StornoBewe, bewelf: BeweLf): boolean {
    return stornoBewe.idbewelf === bewelf.id;
  }

  filterSingleSearchStornoBewe(stornoBewe: ReturnNoteBewe, bewelf: BeweLf): boolean {
    return stornoBewe.typ === 'search' && stornoBewe.bewelf.id === bewelf.id;
  }

  filterEmballageBewe(bewe: EmballageBewe): boolean {
    return bewe.typ === 'emballage';
  }

  filterVermerkBewe(bewe: ReturnNoteBewe): boolean {
    return bewe.typ === 'vermerk';
  }

  protected createCommands(item: ReturnNote): Command[] {
    return [
      {
        name: 'Position hinzufügen',
        icon: 'fa-regular fa-plus',
        class: 'menu action-menu action-menu-top',
        execute: async () => await this.addBewe(item.id)
      },
      {
        name: 'Änderungen verwerfen',
        icon: 'fa-regular fa-trash',
        class: 'menu action-menu action-menu-top',
        canExecute: this.store.observe(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.getters.changes).pipe(map(o => !!o)),
        execute: async () => {
          const answer = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, { question: '#DELIVERY_NOTE.DIALOGS.RETOUR_CLEAR_CHANGES_DIALOG.QUESTION' });
          if (answer === 'YES')
            this.store.commit(SOFTLINE_FEATURE_RETURN_NOTE, ReturnNoteStore.mutations.resetChange, item.id);
        }
      }
    ];
  }
}
