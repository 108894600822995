<ng-container *ngIf="returnNote$ | async as returnNote">
    <ng-container *ngIf="deliveryNote$ | async as deliveryNote">
        <div class="mb-5 mt-3 flex flex-col">
            <div>
                <h4 class="text-2xl font-semibold">Retourschein<br>{{ deliveryNote.adresseBean?.kurzbez }}</h4>
            </div>
            <div class="color-light text-sm font-medium mt-1">
              {{ (deliveryNote?.type?.type === 'AUSLIEFERUNG' ? "#DELIVERY_NOTE.DETAILS.DELIVERY_NOTE" : "#DELIVERY_NOTE.DETAILS.MATERIAL_NOTE") | translate }} {{ deliveryNote.belegnummer }}
            </div>
        </div>

        <div class="mb-5">
            <div class="c row space-between center-v no-wrap">
                <span class="soft-overline">{{ deliveryNote?.type?.bezeichnung }}</span>
            </div>

            <!-- the trackBy is necessary to keep the input selection -->
            <div class="separator-top"
                 [class.separator-bottom]="last"
                 *ngFor="let bewelf of deliveryNote.bewelfs | filter:filterBewelfs; let last = last; trackBy: trackBewelfs">
                <lib-return-note-storno-bewe-item
                    [addedFromSearch]="false"
                    [bewelf]="bewelf"
                    [returnNoteId]="returnNote.id"
                    [value]="returnNote.bewes|single:filterSingleStornoBewe:bewelf">
                </lib-return-note-storno-bewe-item>
            </div>
        </div>

        <ng-container *ngIf="$any(returnNote.bewes) | filter:filterEmballageBewe as emballagenBewes">
            <div class="mb-5" *ngIf="(emballagenBewes?.length ?? 0) > 0">
                <div class="soft-overline mb-2">{{ '#DELIVERY_NOTE.RETOUR.EMBALLAGEN' | translate }}</div>

                <div class="separator-top" [class.separator-bottom]="last" *ngFor="let bewe of emballagenBewes; let last = last;">
                    <lib-return-note-emballage-bewe-item
                      [value]="$any(bewe)"
                      [returnNoteId]="returnNote.id">
                    </lib-return-note-emballage-bewe-item>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="returnNote.bewes | filter:filterVermerkBewe as vermerkBewes">
            <div class="mb-5" *ngIf="(vermerkBewes?.length ?? 0) > 0">
                <div class="soft-overline mb-2">{{ '#DELIVERY_NOTE.RETOUR.VERMERKITEMS' | translate }}</div>

                <div class="separator-top border-box" [class.separator-bottom]="last" *ngFor="let bewe of vermerkBewes; let last = last;">
                    <div class="c row center-v space-between border-box p-3">
                        <span>
                          {{ bewe.vermerk }}
                          <i class="fa-regular fa-images info-icon" *ngIf="bewe?.files?.length"></i>
                        </span>
                        <div class="flex">
                            <span class="cursor-pointer" (click)="onAddVermerkImage(returnNote.id, bewe)">
                                <i class="fa-regular fa-ellipsis-vertical"></i>
                            </span>
                            <span style="width: 52px; " class="flex items-center justify-end">
                                <i class="fa-regular fa-trash pointer p-1" style="color: var(--colorError)" (click)="removeBewe(returnNote.id, bewe)"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="searchStornoBewes$ | async as searchBewes">
            <div class="mb-5" *ngIf="(searchBewes?.length ?? 0) > 0">
                <div class="soft-overline mb-2">{{ '#DELIVERY_NOTE.RETOUR.SEARCH' | translate }}</div>

                <div class="separator-top"
                     [class.separator-bottom]="last"
                     *ngFor="let bewe of searchBewes; let last = last; trackBy: $any(trackSearchBewelfs)">
                    <lib-return-note-storno-bewe-item
                      [addedFromSearch]="true"
                      [bewelf]="bewe.bewelf"
                      [returnNoteId]="returnNote?.id ?? ''"
                      [value]="returnNote.bewes|single:filterSingleSearchStornoBewe:bewe.bewelf">
                    </lib-return-note-storno-bewe-item>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="scanItems$ | async as scanItems">
            <div class="mb-5" *ngIf="(scanItems?.length ?? 0) > 0">
                <div class="soft-overline mb-2">{{ '#DELIVERY_NOTE.RETOUR.SCAN' | translate }}</div>

                <div class="separator-top"
                     [class.separator-bottom]="last"
                     *ngFor="let item of scanItems; let last = last; trackBy: trackScanItems">
                    <lib-return-note-scan-item
                            [item]="item"
                            [returnNoteId]="returnNote?.id ?? ''">
                    </lib-return-note-scan-item>
                </div>
            </div>
        </ng-container>

      <div class="c row end center-v mt-1" >
        <a href="#" class="soft-link mt-3" (click)="$event.preventDefault(); addBewe(returnNote.id)">
          <i class="fa-regular fa-plus"></i> {{ '#DELIVERY_NOTE.RETOUR.ADD_POSITION_LABEL' | translate }}
        </a>
      </div>

        <div class="mb-5">
            <div class="soft-overline mb-2">
                {{  '#DELIVERY_NOTE.RETOUR.COMMENT' | translate}}
            </div>
            <soft-multiline-input
              [lines]="5"
              [placeholder]="'#DELIVERY_NOTE.RETOUR.ADD_COMMENT' | translate"
              [value]="returnNote.vermerk"
              (valueChange)="patchReturnNote(returnNote.id, {vermerk: $event})">
            </soft-multiline-input>
        </div>

        <div class="flex items-center justify-between w-full border border-gray-200 rounded p-3">
            <p class="text-lg font-medium">Mail an Sachbearbeiter</p>

            <div class="flex">
                <soft-toggle-switch [checked]=" returnNote.email" (valueChange)="patchReturnNote(returnNote.id, {email: $event})"></soft-toggle-switch>
            </div>
        </div>

        <!--<button class="soft-button primary fixed-button" (click)="save(id, item.bewelfs)">
            <ng-container *ngIf="!(isSaving$ | async); else spinnerTemplate">{{ '#DELIVERY_NOTE.RETOUR.PRIMARY_BUTTON' | translate }}</ng-container>
            <ng-template #spinnerTemplate>
                <soft-loading-spinner class="spin"></soft-loading-spinner>
            </ng-template>
        </button>-->
      <button class="soft-button primary w-full h-14 mt-4" (click)="onSubmit()">Übernehmen</button>
    </ng-container>
</ng-container>



