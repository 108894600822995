import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_INVENTUR,
  SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_INVENTUR_BEWE
} from '../inventur.shared';
import {InventurBeweStore} from '../store/inventur-bewe.store';
import {map, Subscription} from 'rxjs';
import {InventurStore} from '../store/inventur.store';
import {InventurArtikelDetailStore} from '../store/inventur-artikel-detail.store';
import {InventurKopf} from '../data/inventur-kopf';

@Component({
  selector: 'soft-mde-inventur',
  standalone: true,
  templateUrl: './inventur.component.html',
  styleUrls: ['./inventur.component.scss'],
  imports: [RouterModule],
})
export class InventurComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_INVENTUR,
    InventurStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Inventur'
    );

    const data = (this.activatedRoute.snapshot.data as any)?.inventur as { kopf?: InventurKopf | null };

    if (data?.kopf?.lager) {
      this.setLager(data?.kopf?.lager)
    }

    if (data?.kopf?.lagerplatz) {
      this.store.commit(
        SOFTLINE_FEATURE_MDE_INVENTUR,
        InventurStore.mutations.setLagerplatz,
        data?.kopf?.lagerplatz
      );
    }

    if (data?.kopf?.belegdatum) {
      this.store.commit(
        SOFTLINE_FEATURE_MDE_INVENTUR,
        InventurStore.mutations.setBelegdatum,
        data?.kopf?.belegdatum
      );
    }

    if (data?.kopf && Object.keys(data?.kopf).length > 0 && !!data?.kopf?.lager)
      await this.router.navigate(['/mde-inventur']);
    else
      await this.router.navigate(['/mde-inventur/start']);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: InventurComponent,
        commands: [
          {
            name: 'Inventur',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-warehouse-full',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-inventur',
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_INVENTUR_BEWE,
      InventurBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      InventurComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
      InventurArtikelDetailStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR,
      InventurStore.mutations.setKopf,
      null,
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR,
      InventurStore.mutations.setLager,
      null,
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR,
      InventurStore.mutations.setBelegdatum,
      null,
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR,
      InventurStore.mutations.setLagerplatz,
      null,
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }

  setLager(value: any) {
    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR,
      InventurStore.mutations.setLager,
      value
    );
  }
}
