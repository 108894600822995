<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['artunteruntergrp']}}</span>
  </div>
  @if(item.artuntergrpsprbezeichnung){
    <div class="flex flex-row">
      <span class="font-bold">{{item['artuntergrpsprbezeichnung']}}</span>

      @if(item.artunteruntergrpsprbezeichnung){
        <span class="font-bold"> - {{item['artunteruntergrpsprbezeichnung']}}</span>
      }
    </div>
  }
  <div class="flex flex-row">
    <span>{{item['arthauptgrpsprbezeichnung']}}</span>
  </div>
</div>
