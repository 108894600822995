import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  contentChildren,
  effect,
  inject,
  OnInit,
  signal,
  untracked
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MenuItem,
  ResponsiveMenuComponent,
  WithActivatedRoute,
  WithMenuItems,
  WithRouter
} from '@softline/application';
import { PageHeaderComponent } from './page-header/page-header.component';
import { MenuOutletComponent } from '../../menu/menu-outlet/menu-outlet.component';
import { PageTabComponent } from './page-tab/page-tab.component';
import { isDefined, isDefinedNotEmpty } from '@softline/core';
import { PageSettingsService } from '../../user/page-settings/page-settings.service';

@Component({
  selector: 'soft-page',
  standalone: true,
  imports: [CommonModule, ResponsiveMenuComponent, MenuOutletComponent],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent extends WithMenuItems(WithActivatedRoute(WithRouter())) implements OnInit, AfterContentInit {

  private NAME = 'page';
  readonly service = inject(PageSettingsService, {optional: true});

  header = contentChild(PageHeaderComponent, { descendants: true });
  selectedTabName = signal<string | null>(null);

  tabs = contentChildren(PageTabComponent, { descendants: true });
  selectedTabTemplate = computed(() => {
    const tabs = this.tabs();
    const selectedTabName = this.selectedTabName();
    const selectedTab = tabs.find((tab) => tab.name() === selectedTabName) ?? tabs[0];
    if(isDefined(selectedTab))
      return selectedTab.template();
    return null;
  })

  override menuItems = computed<MenuItem[]>(() => {
    const tabs = this.tabs();
    return tabs.map((tab) => ({
      name: tab.name(),
      type: 'route',
      icon: tab.icon(),
      title: tab.title(),
      outlet: 'tab',
      routerLink: [],
      routerLinkParams: { fragment: tab.name() }
    }));
  });

  fragmentEffect = effect(() => {
    const fragment = this.routeFragment();
    const selectedTabName = untracked(() => this.selectedTabName());
    if(isDefinedNotEmpty(fragment) && fragment !== selectedTabName)
      untracked(() => this.selectedTabName.set(fragment));
  }, {allowSignalWrites: true});

  selectedTabNameEffect = effect(() => {
    const selectedTabName = this.selectedTabName();
    const service = this.service;
    if (service && isDefined(selectedTabName))
      untracked( () => service.set({ name: this.NAME, settings: { selectedTabName } }));
  }, {allowSignalWrites: true});

  constructor() {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  ngAfterContentInit() {
    const fragment = this.routeFragment();
    const tabs = this.tabs();
    if(isDefinedNotEmpty(fragment)) {
      this.selectedTabName.set(fragment);
    }
    else if(tabs.length > 0) {
      let tabName = tabs[0].name();
      if(this.service) {
        const settings = this.service.pageSettings().components.find(o => o.name === this.NAME)?.settings;
        if(settings && isDefined(settings['selectedTabName'])) {
          tabName = settings['selectedTabName'] as string;
        }
      }
      this.router.navigate([], {fragment: tabName});
    }
  }
}
