import { Connection, ConnectionModule, CoreModule, StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule, ItemScanModule,
  MasterDataModule, OfflineModule
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

export function provideFramework(connection: Connection) {
  return [
    importProvidersFrom(
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      OfflineModule.forRoot(),
      ItemScanModule.forRoot(),
      ConsoleModule.forRoot()
    ),
    provideAnimations()
  ];
}
