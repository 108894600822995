import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BeweLf } from '../../types/bewe-lf';
import { EmballageBewe, ItemScanBewe, ReturnNoteBewe, SearchStornoBewe, StornoBewe, VermerkBewe } from '../../types/return-note';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
  selector: 'lib-delivery-note-return-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './delivery-note-return-card.component.html',
  styleUrls: ['./delivery-note-return-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteReturnCardComponent implements OnInit {

  @Input() bewe!: ReturnNoteBewe;
  @Input() bewelf?: BeweLf;

  constructor() { }

  ngOnInit(): void {}

  public isStorno(bewe: ReturnNoteBewe): bewe is StornoBewe {
    return bewe.typ === 'storno';
  }
  public isEmballage(bewe: ReturnNoteBewe): bewe is EmballageBewe {
    return bewe.typ === 'emballage';
  }
  public isVermerk(bewe: ReturnNoteBewe): bewe is VermerkBewe {
    return bewe.typ === 'vermerk';
  }
  public isSearchItem(bewe: ReturnNoteBewe): bewe is SearchStornoBewe {
    return bewe.typ === 'search';
  }
  public isScanItem(bewe: ReturnNoteBewe): bewe is ItemScanBewe {
    return bewe.typ === 'itemScan';
  }
}
