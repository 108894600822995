import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
  selector: 'soft-password-input',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent implements OnInit {
  isMasked = true;

  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value: string | null | undefined;
  @Output() valueChange: EventEmitter<string | null | undefined> =
    new EventEmitter<string | null | undefined>();

  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() autocomplete?: 'on' | 'off' | 'current-password' | 'new-password';

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string | null): void {
    this.value = value;

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
