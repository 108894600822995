import {
  createApplicationRoutes,
  provideFramework,
  provideSoftappsCore,
} from '@configs/common';
import { provideHagebauPartnerSoftlineModules } from '@configs/hagebau-partner';
import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from '../environments/environment';
import { platform } from '../platforms/platform';

const [providers, routes] = provideSoftappsCore({
  userContext: true,
  contextStorage: true,
  pdfViewer: true,
  remoteConfig: true,
  vkforgContext: true,
});

const [hagebauProviders, hagebauRoutes] = provideHagebauPartnerSoftlineModules();

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      createApplicationRoutes(routes, hagebauRoutes)
    ),

    ...provideFramework(environment.connection),
    ...providers,
    ...hagebauProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
  ],
};
