import {
  createGetter,
  createMutation, isDefined,
  mutate,
  RemoteObjectStore, select,
  StoreBuilder,
  StoreFeature,
  SyncedRemoteCollectionStore
} from '@softline/core';
import { Notification } from "./types/notification";

export interface NotificationState extends SyncedRemoteCollectionStore.State<Notification> {
  allLoaded: boolean;
}

export class NotificationStoreExtension {
  static mutations = {
      setAllLoaded: createMutation<NotificationState, boolean>('setAllLoaded')
    };
  static getters = {
    allLoaded: createGetter<NotificationState, boolean>('allLoaded'),
    allUnread: createGetter<NotificationState, Notification[]>('allUnread'),
  };

  static feature : Partial<StoreFeature<NotificationState>> = {
    initialState: {
      allLoaded: false
    } as any,
    mutations: [
      mutate(NotificationStoreExtension.mutations.setAllLoaded, ({state, params}) => ({...state, allLoaded: params}))
    ],
    getters: [
      select(NotificationStoreExtension.getters.allLoaded, ({state}) => state.allLoaded),
      select(NotificationStoreExtension.getters.allUnread, ({get, featureName}) => {
        return get(featureName, SyncedRemoteCollectionStore.getters.all).filter((p) => !isDefined((p as Notification).readtime))
      }),
    ]
  }
}

export const NotificationStore = new StoreBuilder(SyncedRemoteCollectionStore.create<Notification>())
  .add(NotificationStoreExtension)
  .value
